
import types from './types';

export default {
	[types.SET_HELP_DATA](state, list)
	{
		var categories = {};
		var helpItems = {};

		// go over each category in the list
		if (list && list !== null) {
			state.categoryList = list.map(c => {
				var cat = { ...c };
	
				// extract help items into global map
				for(var item of c.items)
					helpItems[item.id] = { ...item };
				
				// replace category items array with list of ids into helpItems map
				cat.items = cat.items.map(item => item.id);
	
				// add category to map
				categories[cat.id] = cat;
				return cat.id;
			});
		} else {
			state.categoryList = [];
		}

		state.helpItems = helpItems;
		state.categories = categories;
		state.loaded = true;
	},

	[types.SET_EXPANDED_HELP_ITEM](state, id)
	{
		state.expandedItem = id;
	}
}