import _ from 'lodash/string';
import moment from 'moment';
import { sprintf } from 'sprintf-js';

import util from './util';

export default {
	filters: {
		currency(value)
		{
			var formatter = new Intl.NumberFormat('en-US', { 
				style: 'currency',
				currency: 'USD',
				minimumFractionDigits: 2
			});

			var num = parseFloat(value) || 0;
			return formatter.format(num);
		},

		dateFormat(value, format)
		{
			if (typeof(value) == 'string')
				return moment(value).format(format);
			else if (typeof(value) == 'number')
				return moment.unix(value).format(format);
			else if (typeof(value) == 'object' && typeof(value.format) == 'function')
				return value.format(format);
			else
				return `!INV: ${value}`;
		},

		numberFormat(value, decimals)
		{
			var dec = parseInt(decimals, 10) || 2;
			return sprintf(`%0.0${dec}f`, value);
			//return parseFloat(value).toFixed(decimals);
		},

		round(value)
		{
			return Math.round(value);
		},

		nthNumber(num)
		{
			return util.nthNumber(num);
		},

		capitalize(val)
		{
			return _.capitalize(val);
		},

		titleCase(val)
		{
			return _.startCase(val);
		},

		// expects val to be minutes, set mult to 60 if the input value is actually hours
		duration(val, mult=1)
		{
			var num = val * mult;
			var hours = Math.floor(num / 60);
			var minutes = _.padStart(num % 60, 2, '0');
			minutes = Math.round(minutes);

			return `${hours}h ${minutes}m`;
		},

		capitalizeWords(val)
		{
			const mySentence = val;
			const words = mySentence.split(" ");

			for (let i = 0; i < words.length; i++) {
				words[i] = words[i][0].toUpperCase() + words[i].substr(1);
			}

			return words.join(" ");
		}
	},

}
