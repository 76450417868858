import { mapState } from 'vuex';

export default {
	computed: {
		prevUrl()
		{
			var current = this.$store.state.catalog.currentOrder;

			if (!current)
				return '/catalog';
			else
				return `/catalog?prevOrder=${current.id}`;
		}
	},

}