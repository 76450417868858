<script functional>

const justifyOptions = ['center', 'start', 'end', 'space-between'];
const alignOptions = ['center', 'start', 'end'];

export default {
	props: {
		row: { type: Boolean, default: false },
		column: { type: Boolean, default: false },
		wrap: { type: Boolean, default: false },
		reverse: { type: Boolean, default: false },

		justify: { type: String, default: '' },
		align: { type: String, default: '' },

		fillHeight: { type: Boolean, default: false },

		gutter: { type: String, default: '', validator: val => ['', 'sm', 'md', 'lg'].indexOf(val) >= 0 },

		stack: { type: String, default: '' },
	},

	methods: {
		getClasses()
		{
			var classes = ['obe-layout'];

			// row or column?
			if (this.row)
				classes.push('obe-layout--row');
			else if (this.column)
				classes.push('obe-layout--column');

			// reverse order?
			if (this.reverse)
				classes.push('obe-layout--reverse');

			// allow wrapping?
			if (this.wrap)
				classes.push('obe-layout--wrap');

			// add justification?
			if (justifyOptions.indexOf(this.justify) >= 0)
				classes.push(`obe-layout--justify-${this.justify}`);

			// add alignment?
			if (alignOptions.indexOf(this.align) >= 0)
				classes.push(`obe-layout--align-items-${this.align}`);

			// fill height?
			if (this.fillHeight)
				classes.push('obe-layout--fill-height');

			// add gutter?
			if (this.gutter != '')
				classes.push(`obe-layout--gutter-${this.gutter}`)

			// add stacking class?
			if (this.stack != '')
				classes.push(`obe-layout--stack-${this.stack}`);

			return classes;
		}
	},

	render(h)
	{
		var args = { class: this.getClasses() };

		return h('div', args, [this.$slots.default]);
	}
}
</script>