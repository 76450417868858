<template>
	<ul class="obe-db-top__buttons">
		<!-- 
		<li class="obe-mr-4">
			<div class="obe-db-subheading obe-ma-0">No cleaning job booked?</div>
		</li>
		<li><a href="#" class="obe-db-top__btn-green">Find a retailer</a></li>
		<li><a href="https://cleaningproducts.aspenclean.com/" target="_blank" class="obe-db-top__btn-green">Order online</a></li>
		 -->
		<li><a href="#" class="obe-db-top__btn-orange" @click.prevent="startNewBooking">Book New Cleaning</a></li>

		<template v-if="$route.name == 'db-catalog' && currentOrder != null && $breakpoints.mdAndUp">
			<li><router-link to="/catalog/checkout" class="obe-db-top__btn-orange">Save</router-link></li>
		</template>

		<li>
			<router-link to="/catalog/order_history" class="obe-db-top__btn-blue">
				Product orders
				<span v-if="upcomingOrders > 0" class="order-count">{{ upcomingOrders }}</span>
			</router-link>
		</li>

	</ul>
</template>

<script>

import { mapState } from 'vuex';
import types from '../store/types';

export default {
	inject: ['$showNewBooking'],

	computed: {
		...mapState({
			currentOrder: state => state.catalog.currentOrder,
			upcomingOrders: state => state.dashboard.upcomingOrders,
		}),
	},

	methods: {
		startNewBooking()
		{
			//this.$store.commit('SHOW_NEW_BOOKING', true);=
			this.$showNewBooking(true);
		}
	}
}
</script>
