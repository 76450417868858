
import router from 'obe/dashboard/router';

import Screen from './comp/Feedback.vue';

router.addRoutes([
	{
		name: 'db-feedback',
		path: '/feedback',
		props: true,
		meta: { title: 'Feedback' },
		component: Screen
	}
]);
