<template>
	<form-field v-bind="formFieldAttrs">
		<input type="file" style="display:none;" ref="files" :multiple="multiple" @change="updateFiles">

		<div class="obe-file-controls">
			<push-button small @click="browse">Select Files</push-button>
		</div>

		<div class="obe-files">
			<div class="obe-files__file" v-for="file in files" :key="file.name">
				<a href="#" class="obe-files__file-icon" @click.prevent="removeFile(file)"><span class="obe-i-delete"></span></a>
				<span class="obe-files__file-link">{{ file.name }}</span>
			</div>
		</div>
	</form-field>
</template>

<script>
import alerts from 'obe/shared/alerts';

import formFieldProps from '../mixins/form-field';

export default {
	mixins: [formFieldProps],

	props: {
		files: { type: Array, default: () => ([]) },
		multiple: { type: Boolean, default: false },
		allowed: { type: Array, required: true },
		maxSize: { type: Number, default: 5 * 1000 * 1000 },
		maxFiles: { type: Number, default: 10 },
	},

	methods: {
		updateFiles()
		{
			var files = this.$refs.files.files;
			var selected = [];

			if (files.length >= this.maxFiles)
			{
				alerts.show(`Too many files selected, only ${this.maxFiles} are allowed', 'Error', 'warning`);
				return;
			}

			// make sure each file matches the given restrictions
			for(var file of files)
			{
				// check file type
				if (this.allowed.indexOf(file.type) == -1)
				{
					alerts.show(`Invalid file type: ${file.name}`, 'Error', 'warning');
					return;
				}

				// check file size
				if (file.size >= this.maxSize)
				{
					alerts.show(`${file.name} is too large`, 'Error', 'warning');
					return;
				}

				selected.push(file);
			}

			// merge files with given list
			this.$emit('update:files', this.files.concat(selected));
		},

		async removeFile(file)
		{
			var files = this.files.slice();
			var pos = files.indexOf(file);
			
			if (pos < 0)
				return;

			if (!await alerts.confirm('Are you sure you want to remove this file?'))
				return;

			files.splice(pos, 1);
			this.$emit('update:files', files);
		},

		browse()
		{
			this.$refs.files.click();
		}
	}
}
</script>