
import types from './types';

export default {
	[types.SET_CUSTOMER_CONTACTS](state, list)
	{
		var contacts = {};

		if (list && list !== null) {
			state.contactList = list.map(a => {
				contacts[a.id] = { ...a };
				return a.id;
			});
		} else {
			state.contactList = [];
		}

		state.contacts = contacts;
	},

	[types.SET_CURRENT_CONTACT](state, contact)
	{
		if (contact == null)
			state.currentContact = null;
		else
			state.currentContact = { ...contact };
	},

	[types.UPDATE_CURRENT_CONTACT](state, contact)
	{
		if (state.currentContact != null)
			state.currentContact = { ...state.currentContact, ...contact };
	},

	[types.SET_CONTACT_ADDRESSES](state, list)
	{
		var addresses = {};

		if (list && list !== null) {
			state.addressList = list.map(a => {
				addresses[a.id] = { ...a };
				return a.id;
			});
		} else {
			state.addressList = [];
		}

		state.addresses = addresses;
	},

	[types.SET_CURRENT_ADDRESS](state, addr)
	{
		if (addr == null)
			state.currentAddress = null;
		else
			state.currentAddress = { ...addr };
	},

	[types.UPDATE_CURRENT_ADDRESS](state, addr)
	{
		if (state.currentAddress != null)
			state.currentAddress = { ...state.currentAddress, ...addr };
	}

	/*[types.REFRESHING_ADDRESSES](state, flag)
	{
		state.refreshing = flag ? true : false;
	},

	[types.SET_PRIMARY_ADDRESS](state, id)
	{
		state.primaryAddressId = id;
	}*/
}