<template>
	<div class="obe-login login-content-centered">
		<div class="obe-login__wrapper">
			<div class="v-banner__logo-login">
				<img src="/images/header-logo.svg" alt="Logo" class="v-banner__logo"/>
			</div>

			<layout row wrap>
				<flex md6 xs12>
					<div class="obe-login__title-container">
						<p class="obe-login__title">Welcome to your AspenClean's Dashboard</p>
						<p class="obe-login__subtitle">Sign in to Start</p>
					</div>
					<fade-transition>
						<div v-if="loginFail" class="obe-alert obe-alert--error obe-mb-6">
							Invalid email address or password!
						</div>
					</fade-transition>

					<obe-form ref="form">
						<div class="obe-login__input-label-container">
							<label class="obe-login__input-label">Email Address*</label>
						</div>
						<text-field
							autofocus
							hide-label
							placeholder="Email Address"
							v-model.trim="email"
							:rules="rules.email"
							@enter="submit">
						</text-field>

						<div class="obe-login__input-label-container">
							<label class="obe-login__input-label">Password*</label>
						</div>
						<text-field
							hide-label
							placeholder="Password"
							input-type="password"
							v-model.trim="password"
							:rules="rules.password"
							@enter="submit">
						</text-field>
					</obe-form>
				</flex>
			</layout>

			<layout class="obe-login__buttons">
				<flex md6>
					<div class="obe-login__links">
						<router-link to="/forgot_password" class="obe-link-gray">Forgot Password?</router-link><br>
						<!-- <router-link to="/forgot_email" class="obe-link">Forgot email address?</#router-link> -->
					</div>
					<push-button block label="Login" @click="submit" :loading="loading"></push-button>
				</flex>
			</layout>
		</div>
	</div>
</template>

<script>
import api from 'obe/dashboard/api';

export default {
	data()
	{
		return {
			email: '',
			password: '',
			loading: false,
			loginFail: false,

			rules: {
				email: [ v => !!v || 'Please enter your email address' ],
				password: [ v => !!v || 'Please enter your password' ],
			}
		}
	},

	methods: {
		async submit()
		{
			if (!this.$refs.form.validate())
				return;

			this.loading = true;
			this.loginFail = false;

			try
			{
				var params = { email: this.email, password: this.password };
				var result = await api.post('/auth/login', params);

				if (result.data.ok)
				{
					if (this.$route.query.redirect)
					{
						window.location = this.$route.query.redirect;
					}
					else
					{
						window.location = '/dashboard/schedule';
					}
				}
				else
				{
					this.loginFail = true;
				}	
			}
			finally
			{
				this.loading = false;
			}
		}
	},
}
</script>
