
export const SET_APPT_LIST = 'SET_APPT_LIST';
export const SET_CURRENT_APPT = 'SET_CURRENT_APPT';
export const SET_CURRENT_APPT_LOYALTY_CODE = 'SET_CURRENT_APPT_LOYALTY_CODE';
export const SET_CURRENT_ADDON = 'SET_CURRENT_ADDON';
export const UPDATE_ADDON = 'UPDATE_ADDON';
export const REMOVE_ADDON = 'REMOVE_ADDON';
export const DELETE_ADDON = 'DELETE_ADDON';
export const UPDATE_ESTIMATE = 'UPDATE_ESTIMATE';
export const SET_AVAIL_GRADES = 'SET_AVAIL_GRADES';
export const SET_FRANCHISE_CLOSURES = 'SET_FRANCHISE_CLOSURES';
export const UPDATE_APPT_ACCESS = 'UPDATE_APPT_ACCESS';
export const SET_APPT_PRODUCTS = 'SET_APPT_PRODUCTS';
export const SET_APPT_OVERRIDES = 'SET_APPT_OVERRIDES';
export const SET_APPT_BASE_OVERRIDES = 'SET_APPT_BASE_OVERRIDES';
export const SET_LOYALTY = 'SET_LOYALTY';
export const SET_SHOW_OFFER_BANNER = 'SET_SHOW_OFFER_BANNER';

export default {
	SET_APPT_LIST,
	SET_CURRENT_APPT,
	SET_CURRENT_ADDON,
	UPDATE_ADDON,
	REMOVE_ADDON,
	DELETE_ADDON,
	UPDATE_ESTIMATE,
	SET_AVAIL_GRADES,
	UPDATE_APPT_ACCESS,
	SET_APPT_PRODUCTS,
	SET_FRANCHISE_CLOSURES,
	SET_APPT_OVERRIDES,
	SET_APPT_BASE_OVERRIDES,
	SET_LOYALTY,
	SET_SHOW_OFFER_BANNER,
	SET_CURRENT_APPT_LOYALTY_CODE,
}
