
import types from './types';

export default {
	[types.SET_PROFILE_DATA](state, data)
	{
		state.profile = { ...data };
	},

	[types.UPDATE_PROFILE_DATA](state, data)
	{
		state.profile = { ...state.profile, ...data };
	}
}