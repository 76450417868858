<template>
	<card>
		<!-- WARN USER IF WITHIN 24-48 HOURS OF ARRIVAL DATE -->
		<div v-if="showWarning" class="obe-warning-popup">
			<div class="obe-warning-popup__title">Rescheduling existing booking</div>

			<div class="obe-warning-popup__desc">
				You are trying to reschedule your booking within {{ hours }} hours of your cleaning service.<br>
				<strong>YOU WILL BE CHARGED {{ surcharge }} OF YOUR CLEANING SERVICE COST IF YOU PROCEED.</strong>
			</div>

			<ul class="obe-button-menu obe-button-menu--vert-center">
				<li><push-button wide block color="orange" @click="dismiss">Keep original booking</push-button></li>
				<li><push-button wide block color="text" @click="resume">Reschedule at additional cost</push-button></li>
			</ul>
		</div>

		<!-- OTHERWISE SHOW RESCHEDULING INTERFACE -->
		<reschedule-appt v-else @closed="dismiss"></reschedule-appt>
	</card>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';

import RescheduleAppt from './RescheduleAppt.vue';

export default {
	components: { RescheduleAppt },

	data()
	{
		return {
			showWarning: false,
			hours: 0,
			surcharge: '',
		}
	},

	computed: {
		...mapState({
			appt: state => state.schedule.currentAppt,
		}),
	},

	methods: {
		dismiss()
		{
			this.$emit('closed');
		},

		resume()
		{
			this.showWarning = false;
		}
	},

	mounted()
	{
		// get arrival date
		var arrival = moment(this.appt.arrival_window_start_time).startOf('day');

		// get difference between arrival date and today in hours
		var diff = moment.duration(arrival.diff(moment())).asHours();

		// if diff <= 24 then show 100% warning
		if (diff <= 24)
		{
			this.showWarning = true;
			this.hours = 24;
			this.surcharge = '100%';
		}
		// otherwise if within 48 hours..
		else if (diff <= 48)
		{
			this.showWarning = true;
			this.hours = 48;
			this.surcharge = '50%';
		}
	}
}
</script>