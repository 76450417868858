
import Vue from 'vue';
import VueRouter from 'vue-router';

import store from 'obe/dashboard/store';

Vue.use(VueRouter);

const router = new VueRouter({
	mode: 'history',
	base: '/dashboard/',
	routes: [
		{
			name: 'db-home',
			path: '/',
			redirect: '/schedule',
		},
	]
});

// force users to login screen if there is no user authenticated
router.beforeEach((to, from, next) => {
	var user = store.state.dashboard.currentUser;

	if (user != null || to.meta.noUserRequired)
		next();
	else
		next({ name: 'db-login' });
});

export default router;