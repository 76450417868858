
import api from 'obe/dashboard/api';

import types from './types';

export default {
	async ['catalog.getOrderHistory']({ commit })
	{
		var result = await api.get('/product_orders/history');
		commit(types.SET_ORDER_LIST, result.data.orders);
	},

	async ['catalog.getDiscounts']({ state, commit }, params)
	{
		var force = params && params.force;
		if (state.nextAppt != null && (state.discounts == null || force))
		{
			var params, result;

			params = { 
				postal_code: state.nextAppt.postal_code, 
				franchise_id: state.franchise.id,
				//sf_territory_id: state.franchise.sf_territory_id,
			};
			
			result = await api.get('/discounts', { params });
			commit(types.SET_CATALOG_DISCOUNTS, result.data.discounts);
		}
	},
	async ['catalog.getUserInfo']({ state, commit }, params)
	{
		
			const result = await api.get('/get_user_info');
			commit(types.SET_USER_INFO, result.data.loyalty_code);
	
	},
	async ['catalog.addPromoCode']({ state, commit }, args)
	{
		var params, result;

		params = { 
			promo_code: args.promo_code,
			postal_code: state.nextAppt.postal_code, 
			franchise_id: state.franchise.id,
			//sf_territory_id: state.franchise.sf_territory_id,
		};
		
		result = await api.get('/discounts/get_promo_code', { params });

		if (result.data.found)
			commit(types.SET_CATALOG_PROMO_CODE, result.data);
			
		return result.data;
	},

	async ['catalog.getProducts']({ commit })
	{
		var result = await api.get('/products');
		commit(types.SET_CATALOG_PRODUCTS, result.data.categories);
	},

	async ['catalog.getNextAppt']({ commit })
	{
		var result = await api.get('/product_orders/new');

		if (!result.data.no_appts_found)
		{
			var payload = {
				order: null,
				franchise: result.data.franchise,
				taxes: result.data.taxes,
				nextAppt: {
					work_order_id: result.data.appt.parent_record_id,
					delivery_date: result.data.appt.arrival_window_start_time,
					//delivery_date: result.data.appt.sched_start_time,
					postal_code: result.data.appt.parent_record.postal_code,
				}
			};

			commit(types.SET_CATALOG_APPT, payload);
			commit(types.SET_CART_CONTENTS, {});
		}
		else
		{
			commit(types.SET_CATALOG_APPT, null);
		}
	},

	async ['catalog.startNewOrder']({ commit }, params)
	{
		var payload = {
			order: null,
			franchise: { id: params.franchise.id },
			taxes: JSON.parse(JSON.stringify(params.franchise.taxes)),
			nextAppt: {
				id: params.apptId,
				work_order_id: params.workOrderId,
				delivery_date: params.deliveryDate,
				postal_code: params.postalCode,
			}
		};

		commit(types.SET_CATALOG_APPT, payload);
		commit(types.SET_CART_CONTENTS, {});
	},

	async ['catalog.getPreviousOrder']({ state, commit }, params)
	{
		// do nothing if there is an existing order already in the state and its ID matches the incoming payload's id
		//if (state.currentOrder != null && params.id == state.currentOrder.id)
		//	return;

		// otherwise replace the current order information with order data from the server
		var result = await api.get(`/product_orders/${params.id}`);
		var cart = {};
		var payload = {
			order: result.data.order,
			franchise: result.data.franchise,
			taxes: result.data.taxes,
			nextAppt: {
				id: result.data.order.sf_appt_id,
				work_order_id: result.data.order.sf_work_order_id,
				delivery_date: result.data.order.delivery_date,
				postal_code: result.data.order.postal_code,
			}
		};

		// only send mutations if the appt id is different
		if (state.nextAppt == null || state.nextAppt.id != result.data.order.sf_appt_id)
		{
			// update cart data
			commit(types.SET_CATALOG_APPT, payload);

			// set cart contents
			for(var p of result.data.products)
				cart[p.product_id] = p.quantity;
			
			commit(types.SET_CART_CONTENTS, cart);
		}
	},

	async ['catalog.submitOrder']({ state, commit }, params)
	{
		var result, req = {}, order = {
			franchise_id: state.franchise.id,
			work_order_id: state.nextAppt.work_order_id,
			appt_id: state.nextAppt.id,
			delivery_date: state.nextAppt.delivery_date,
			order_total: params.total,
			products: [],
			loyalty_code: params.loyaltyCode,
		};

		// add products
		for(var id in state.cart)
			order.products.push({ id, quantity: state.cart[id] });

		// add promo code if specified
		if (state.promoCode)
			req.promo_code = state.promoCode.discountCode.code;

		// specify previous order to update instead of creating a new one
		if (state.currentOrder)
			req.order_id = state.currentOrder.id;

		// add order data to request
		req.order = order;
		req.postal_code = state.nextAppt.postal_code;

		result = await api.post('/product_orders/submit_order', req);

		commit(types.SET_CART_CONTENTS, {});
		commit('SET_DASHBOARD_UPCOMING_ORDERS', result.data.upcoming_orders);
	}
}
