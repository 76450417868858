import axios from 'axios';
import axiosRetry from 'axios-retry';
import alerts from 'obe/shared/alerts';

let _api;

// add support for Rails CSRF protection
const csrfNode = document.querySelector('meta[name=csrf-token]');

if (csrfNode) {
  axios.defaults.headers.common['X-CSRF-Token'] = csrfNode.getAttribute('content');
}

export function createApi(baseUrl, headers = {}) {
  const api = axios.create({
    baseURL: baseUrl,
    withCredentials: true,
    headers: {
      ...axios.defaults.headers.common,
      ...headers
    }
  });

  let retryCount = 0;

  // add retry logic for network errors and timeouts
  axiosRetry(api, {
    retries: 3, // number of retries
    retryDelay: axiosRetry.exponentialDelay, // exponential backoff delay between retries
    retryCondition: (error) => {
      const shouldRetry = axiosRetry.isNetworkOrIdempotentRequestError(error) || error.message.includes('timeout');
      if (shouldRetry) {
        retryCount++;
      }
      return shouldRetry;
    },
  });

  // setup global error handling
  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      const msg = error.response ? error.response.data.error : error.message;

      if (retryCount >= 3) {
        // throw the error after the last retry attempt
        // use ugly JS alert for now
        alerts.show(msg, 'Error', 'error');
      }

      if (retryCount === 0) {
        // throw the error immediately if there was no retry attempt
        alerts.show(msg, 'Error', 'error');
      }

      return Promise.reject(error);
    }
  );

  return api;
}

// function to create an API
export function initApi(baseUrl) {
  _api = createApi(baseUrl);
}

export function getApi() {
  return _api;
}
