<template>
	<card>
		<obe-form ref="form">
			<div class="obe-db-heading">
				{{ method.id == 0 ? 'Add a new card' : `Card ending in ${method.last4}` }}
			</div>

			<layout row wrap align="center">
				<flex md7 class="obe-mb-4">
					<div class="obe-db-subheading obe-mb-0">Billing address</div>
				</flex>
				<flex md5 class="obe-mb-4">
					<!-- <push-button outline block small :loading="loadingPrimary" @click="copyPrimary">Use Primary</push-button> -->
					<!-- <checkbox label="Same as primary" v-model="usePrimary"></checkbox> -->
				</flex>
			</layout>

			<layout row wrap>
				<flex xs12>
					<div class="obe-form-field">
						<checkbox v-model="isBusiness" label="Business"/>
					</div>
					<text-field
						v-if="method.is_business"
						type="vert"
						label="Company name"
						:disabled="loadingPrimary"
						:value="method.company_name"
						@input="updateField('company_name', $event)">
					</text-field>
				</flex>

				<flex md6>
					<text-field
						type="vert"
						label="First name"
						:disabled="loadingPrimary"
						:rules="rules.first_name"
						:value="currentUser.first_name"
						@input="updateField('first_name', $event)">
					</text-field>
				</flex>

				<flex md6>
					<text-field
						type="vert"
						label="Last name"
						:disabled="loadingPrimary"
						:rules="rules.last_name"
						:value="currentUser.last_name"
						@input="updateField('last_name', $event)">
					</text-field>
				</flex>

				<flex xs12>
					<text-field
						type="vert"
						label="Street address"
						:disabled="loadingPrimary"
						:rules="rules.street_address"
						:value="currentAddress.street_address"
						@input="updateField('street_address', $event)">
					</text-field>
				</flex>

				<flex md6>
					<text-field
						type="vert"
						label="Apt # (optional)"
						:disabled="loadingPrimary"
						:value="currentAddress.apartment"
						@input="updateField('unit', $event)">
					</text-field>
				</flex>

				<flex md6>
					<dropdown-field
						type="vert"
						label="Province"
						:disabled="loadingPrimary"
						no-selection="Select province"
						:items="$options.provinces"
						item-text="name"
						item-value="code"
						:rules="rules.province"
						:value="currentAddress.province"
						@input="updateField('province', $event)">
					</dropdown-field>
				</flex>

				<flex md6>
					<text-field
						type="vert"
						label="City"
						:disabled="loadingPrimary"
						:rules="rules.city"
						:value="currentAddress.city"
						@input="updateField('city', $event)">
					</text-field>
				</flex>

				<flex md6>
					<text-field
						type="vert"
						label="Postal Code"
						:disabled="loadingPrimary"
						:rules="rules.postal_code"
						:value="currentAddress.postal_code"
						@input="updateField('postal_code', $event)">
					</text-field>
				</flex>

				<flex md6>
					<text-field
						type="vert"
						label="Phone"
						:disabled="loadingPrimary"
						:rules="rules.phone"
						:value="currentUser.phone1"
						@input="updateField('phone', $event)">
					</text-field>
				</flex>

				<flex md6>
					<text-field
						type="vert"
						label="Email Address"
						:disabled="loadingPrimary"
						:rules="rules.email"
						:value="currentUser.email"
						@input="updateField('email', $event)">
					</text-field>
				</flex>
			</layout>
				
			<layout row wrap>
		
				<template v-if="method.id == 0">
					<flex xs12>
						<div class="obe-db-subheading">Credit card</div>

						<!--
						<text-field
							hide-label
							placeholder="Name"
							:rules="rules.name"
							:value="method.name"
							@input="updateField('name', $event)">
						</text-field>
						-->
					</flex>

					<flex xs12>
						<stripe-card-field hide-label ref="stripe"></stripe-card-field>
					</flex>

					<flex xs12>
						<form-field hide-label :rules="rules.tos" :validate-value="tosAgreed">
							<checkbox v-model="tosAgreed">I agree to AspenClean's Terms and Conditions</checkbox>
						</form-field>
						<ul class="obe-button-menu">
							<li><push-button outline small color="grey" open="https://aspenclean.com/pages/terms" target="_blank">View terms and conditions</push-button></li>
						</ul>
					</flex>
				</template>

				<flex xs12>
					<ul class="obe-button-menu">
						<li>
							<push-button color="orange" wide :loading="saving" :disabled="loadingPrimary" @click="save">
								{{ method.is == 0 ? 'Add new card' : 'Update card' }}
							</push-button>
						</li>
					</ul>
				</flex>
			</layout>
		</obe-form>
	</card>
</template>

<script>
import { mapState } from 'vuex';

import alerts from 'obe/shared/alerts';
import api from 'obe/dashboard/api';
import { provinces } from 'obe/shared/regions';

import types from '../store/types';

export default {
	provinces,

	data()
	{
		return {
			tosAgreed: false,
			saving: false,
			loadingPrimary: false,
			currentAddress: {},
			rules: {
				first_name: [ v => this.usePrimary || !!v || 'Please enter your first name' ],
				last_name: [ v => this.usePrimary || !!v || 'Please enter your last name' ],
				street_address: [ v => this.usePrimary || !!v || 'Please enter your street address' ],
				province: [ v => this.usePrimary || !!v || 'Please enter your province' ],
				city: [ v => this.usePrimary || !!v || 'Please enter your city' ],
				postal_code: [ v => this.usePrimary || !!v || 'Please enter your postal code' ],
				phone: [ v => this.usePrimary || !!v || 'Please enter your phone number' ],
				email: [ v => this.usePrimary || !!v || 'Please enter your email address' ],
				//name: [ v => !!v || 'Please enter a name for this credit card' ],
				tos: [
					v => this.method.id != 0 || v || 'You must agree to the terms and conditions'
				]
			}
		}
	},

	async mounted() {
		await this.getPrimaryAddressOfAccount();
	},

	computed: {
		...mapState({
			method: state => state.payments.currentMethod,
			currentUser: state => state.dashboard.currentUser,
		}),

		isBusiness:
		{
			get()
			{
				return this.method.is_business;
			},

			set(value)
			{
				this.updateField('is_business', value);
			}
		}
	},

	methods: {
		updateField(field, val)
		{
			this.$store.commit(types.UPDATE_CURRENT_METHOD, { [field]: val });
		},

		dismiss(refresh)
		{
			this.$emit('close', refresh);
		},

		async getPrimaryAddressOfAccount()
		{
			this.loadingPrimary = true;

			try
			{
				var result = await api.get(`/addresses/get_primary_address`);
				this.currentAddress = result.data.address;
			}
			finally
			{
				this.loadingPrimary = false;
			}
		},

		async copyPrimary()
		{
			this.loadingPrimary = true;

			try
			{
				var result = await api.get('/addresses/primary');
				// var newResult = await api.get(`/addresses/get_primary_address`);
				// console.log(newResult);
				if (result.data.address != null)
				{
					var fields = ['street_address', 'unit', 'province', 'city', 'postal_code', 'phone', 'email'];
					var addr = {};

					for(var f of fields)
						addr[f] = result.data.address[f];
					this.$store.commit(types.UPDATE_CURRENT_METHOD, addr);
				}
			}
			finally
			{
				this.loadingPrimary = false;
			}
		},

		async save()
		{
			if (!this.$refs.form.validate())
				return;

			this.saving = true;

			try
			{
				var data = { method: this.method };
				var stripeData = {
					name: `${this.method.first_name} ${this.method.last_name}`,
					address_line1: this.method.street_address,
					address_city: this.method.city,
					address_state: this.method.city,
					address_zip: this.method.postal_code,
					address_country: 'CA',
				};

				if (this.$refs.stripe)
				{
					var token = await this.$refs.stripe.getToken(stripeData);
					if (!token.error)
						data.stripe_payload = JSON.stringify(token.token);
					else
						return;
				}
				
				if (this.method.id == 0)
					await api.post('/payment_methods', data);
				else
					await api.put(`/payment_methods/${this.method.id}`, data);

				this.dismiss(true);
			}
			catch(stripeErr)
			{
				alerts.show(stripeErr.message, 'Stripe Error', 'error');
			}
			finally
			{
				this.saving = false;
			}
		}
	}
}
</script>
