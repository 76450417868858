<template>
	<card>
		<!-- DISPLAY REQUEST CHANGE FORM -->
		<div class="obe-change-appt-form" v-if="!submitted">
			<div class="obe-change-appt-form__title">
				You are trying to change your Add-ons less then 48 hours to cleaning service. Let us know what you want to change and our Customer Care Team will contact you shortly.
			</div>

			<obe-form ref="form">
				<text-field
					hide-label
					multiline
					:disabled="sending"
					:rules="rules.notes"
					v-model="notes">
				</text-field>
			</obe-form>

			<ul class="obe-button-menu obe-button-menu--horz">
				<li><push-button wide outline color="gray" to="/schedule" :disabled="sending">Close</push-button></li>
				<li><push-button wide color="orange" @click="submit" :loading="sending">Send</push-button></li>
			</ul>
		</div>

		<!-- DISPLAY CONFIRMATION OF SUBMISSION -->
		<div class="obe-change-appt-form" v-else>
			<div class="obe-change-appt-form__title">
				Thank you! Your request has been received and will be reviewed shortly.
			</div>

			<ul class="obe-button-menu obe-button-menu--horz">
				<li><push-button wide outline color="gray" to="/schedule" :disabled="sending">Close</push-button></li>
			</ul>
		</div>
	</card>
</template>

<script>
import { mapState } from 'vuex';

import api from 'obe/dashboard/api';

export default {
	data()
	{
		return {
			sending: false,
			submitted: false,
			notes: '',
			rules: {
				notes: [ v => !!v || 'Please enter your requested changes above' ],
			}
		}
	},

	computed: {
		...mapState({
			appointment: state => state.schedule.currentAppt,
		})
	},

	methods: {
		async submit()
		{
			var params = {
				appt_num: this.appointment.appointment_number,
				notes: this.notes,
			};

			if (!this.$refs.form.validate())
				return;

			this.sending = true;
			this.submitted = false;

			try
			{
				await api.post('/support_cases', params);
				this.submitted = true;
			}
			finally
			{
				this.sending = false;
			}
		}
	}
}
</script>