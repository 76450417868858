
export default {
	loaded: false,
	
	categories: {},
	categoryList: [],

	helpItems: {},

	expandedItem: 0,
}