<template>
	<card>
		<obe-form ref="form">
			<div class="obe-addon-options">
				<div class="obe-addon-options__title">
					<div class="obe-addon-options__title-icon">
						<span :class="addonInfo.icon"></span>
					</div>
					<div class="obe-addon-options__title-text">
						<div class="obe-title obe-mt-0 obe-mb-0">Remove {{ addonInfo.label }}</div>
					</div>
				</div>

				<div class="obe-addon-options__main">
					<radio-group 
						hide-label
						v-model="removeOn"
						:items="$options.visitOptions"
						:rules="rules.option">
					</radio-group>

					<layout row>
						<flex sm6>
							<push-button block color="orange" label="REMOVE" @click="confirm"></push-button>
						</flex>
					</layout>
				</div>
			</div>
		</obe-form>
	</card>
</template>

<script>
import _ from 'lodash/string';
import { mapState, mapGetters } from 'vuex';

import { freqOptions, addonList } from 'obe/screens/booking-form/info';

import types from '../store/types';
import { visitOptions } from '../info';

export default {
	visitOptions,

	props: {
		name: { type: String, required: true },
	},

	data()
	{
		return {
			removeOn: '',

			rules: {
				option: [ v => !!v || 'Please select an option above' ],
			}
		}
	},

	computed: {
		...mapState({
			//currentAddon: state => state.schedule.currentAddon,
			addons: state => state.schedule.apptAddons,
		}),

		addonInfo()
		{
			// if this is any addon that is not laundry gold/silver
			if (addonList[this.name])
				return addonList[this.name];
			// if this is a laundry addon then manually return the following info
			else if (['linen_laundry_gold', 'linen_laundry_silver'].indexOf(this.name) >= 0)
				return { label: _.startCase(this.name), icon: 'obe-i-laundry2' };
		},

		currentAddon()
		{
			return this.addons[this.name];
		}
	},

	methods: {
		confirm()
		{
			if (this.$refs.form.validate())
			{
				this.$store.commit(types.REMOVE_ADDON, { name: this.currentAddon.addon_name, removeOn: this.removeOn });
				this.dismiss(true);
			}
		},

		dismiss(updated)
		{
			this.$emit('closed', updated);
		}
	},
}
</script>