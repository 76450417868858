<template>
	<card>
		<obe-form ref="form">
			<div class="obe-addon-options">
				<div class="obe-addon-options__title">
					<div class="obe-addon-options__title-icon">
						<span :class="addonInfo.icon"></span>
					</div>
					<div class="obe-addon-options__title-text">
						<div class="obe-title obe-mt-0 obe-mb-0">{{ addonInfo.label }}</div>
					</div>
				</div>

				<div class="obe-addon-options__main">
					<!--
					<radio-group 
						hide-label
						v-if="isRecurring"
						v-model="updateOn"
						:items="$options.visitOptions"
						:rules="rules.option">
					</radio-group>
					-->

					<dropdown-field
						hide-label
						v-if="showOptions"
						v-model="optionSelected"
						:items="options"
						:rules="rules.option">
					</dropdown-field>

					<layout row>
						<flex sm6>
							<push-button block color="orange" label="UPDATE" @click="confirm"></push-button>
						</flex>
					</layout>
				</div>
			</div>
		</obe-form>
	</card>
</template>

<script>
import _ from 'lodash/string';
import { mapState, mapGetters } from 'vuex';

import { freqOptions, addonList } from 'obe/screens/booking-form/info';

import types from '../store/types';
import { visitOptions } from '../info';

export default {
	freqOptions,
	addonList,
	visitOptions,

	props: {
		//name: { type: String, required: true },
	},

	data()
	{
		return {
			options: [],
			optionSelected: '',
			//updateOn: '',

			rules: {
				option: [ v => !!v || 'Please select an option above' ],
			}
		}
	},

	computed: {
		...mapGetters(['getBookingOptionsByRoom']),
		...mapState({
			currentAddon: state => state.schedule.currentAddon,
			addons: state => state.schedule.apptAddons,
			numRooms: state => state.schedule.apptBooking.num_rooms,
			cleaningFreq: state => state.schedule.apptBooking.cleaning_freq,
			isRecurring: state => state.schedule.isRecurring,
		}),

		addonInfo()
		{
			return addonList[this.currentAddon.addon_name];
		},

		showOptions()
		{
			// don't show if there aren't any
			if (this.options.length == 0)
				return false;
			// don't show if there is only one and it is 'yes'
			else if (this.options.length == 1 && this.options[0].value.toLowerCase() == 'yes')
				return false;
			// otherwise display options
			else
				return true;
		}
	},

	methods: {
		// return all options without 'no' as an option with option names capitalized
		getAddonOptions(name)
		{
			var options = this.getBookingOptionsByRoom(name, this.numRooms);
			return options
				.filter(o => o.option_name.toLowerCase() != 'no')
				.map(o => ({ text: _.capitalize(o.option_name), value: o.option_name }));
		},

		confirm()
		{
			var addon = {
				addon_name: this.currentAddon.addon_name,
				frequency: this.cleaningFreq == 'one-time' ? 'initial' : 'regular',
				//update_on: this.updateOn,
				//remove_on: false,
				option_selection: this.optionSelected,
			};

			/*if (this.cleaningFreq == 'one-time' || this.updateOn == 'this')
				addon.frequency = 'initial';
			else 
				addon.frequency = 'both';*/

			if (this.$refs.form.validate())
			{
				this.$store.commit(types.UPDATE_ADDON, addon);
				this.dismiss(true);
			}
		},

		remove()
		{
			this.$store.commit(types.REMOVE_ADDON, this.currentAddon.addon_name);
			this.dismiss(false);
		},

		dismiss(updated)
		{
			this.$emit('closed', updated);
		}
	},

	mounted()
	{
		this.options = this.getAddonOptions(this.currentAddon.addon_name);

		if (this.options.length == 1)
			this.optionSelected = this.options[0].value;

		this.optionSelected = this.currentAddon.option_selection.toLowerCase();
		//this.updateOn = this.currentAddon.update_on;
		//this.frequency = this.currentAddon.frequency;
	}
}
</script>