<template>
	<card>
		<obe-form ref="form">
			<div class="obe-addon-options obe-mb-4">
				<div class="obe-addon-options__title">
					<div class="obe-addon-options__title-icon">
						<span class="obe-i-laundry2"></span>
					</div>
					<div class="obe-addon-options__title-text">
						<div class="obe-title obe-mt-0 obe-mb-0">Laundry Options</div>
					</div>
				</div>
			</div>

			<layout row wrap>

				<!-- display dropdown selector for phones -->
				<flex v-if="$breakpoints.mdAndDown" xs12>
					<dropdown-field
						hide-label
						v-model="tier"
						no-selection="Select laundry service"
						class="obe-mb-0"
						:items="tiers">
					</dropdown-field>
				</flex>

				<flex v-if="$breakpoints.mdAndDown && tier == ''" xs12>
					<div class="obe-laundry-info">
						<div class="obe-laundry-info__desc">
							<p>Please select a laundry service above</p>
						</div>
					</div>
				</flex>
				
				<flex v-if="$breakpoints.mdAndUp || tier == 'linen_laundry_gold'" md6>
					<div class="obe-laundry-info">
						<radio v-model="tier" value="linen_laundry_gold" class="obe-laundry-info__tier-btn">
							<div class="obe-laundry-info__tier">Gold</div>
						</radio>

						<div class="obe-laundry-info__desc">
							<p>
							We wash, dry and fold linen and towels. We will put a load of 
							laundry into a washer, transfer it into a dryer, take it ou tof the dryer 
							and fold it. Washer &amp; dryer instructions and laundry detergent 
							must be provided.
							</p>

							<p>
								<strong>Maximum 1 load of laundry will be completed</strong>
							</p>
						</div>
					</div>
				</flex>

				<flex v-if="$breakpoints.mdAndUp || tier == 'linen_laundry_silver'" md6>
					<div class="obe-laundry-info">
						<radio v-model="tier" value="linen_laundry_silver" class="obe-laundry-info__tier-btn">
							<div class="obe-laundry-info__tier">Silver</div>
						</radio>

						<div class="obe-laundry-info__desc">
							<p>
								We wash linen and towels and transfer them into a dryer. Laundry will be left in the dryer. 
								Washer &amp; dryer isntructions and laundry detergent must be provided.
							</p>
						</div>
					</div>
				</flex>
			</layout>

			<layout column gutter="sm" class="obe-mb-4">
				<!--
				<flex v-if="cleaningFreq != 'one-time'">
					<dropdown-field
						hide-label
						class="obe-mb-0"
						no-selection="Update this addon for"
						v-model="updateOn"
						:items="$options.visitOptions"
						:rules="rules.updateOn">
					</dropdown-field>
				</flex>
				-->

				<flex>
					<dropdown-field
						hide-label
						class="obe-mb-0"
						no-selection="How many beds"
						v-model="optionSelected"
						:items="options"
						:rules="rules.option">
					</dropdown-field>
				</flex>
			</layout>

			<div class="obe-folding-options">
				<div class="options-title">
					<div class="obe-label-text">Laundry folded?</div>
				</div>
				<div class="options-main">
					<form-field hide-label class="obe-mb-0" :validate-value="laundryFolded" :rules="rules.laundryFolded">
						<ul class="obe-radio-list">
							<li v-for="opt in foldOptions" :key="opt.value">
								<radio
									v-model="laundryFolded"
									:value="opt.value"
									:label="opt.text">
								</radio>
							</li>
						</ul>
					</form-field>
				</div>
			</div>


			<p class="obe-laundry-disclaimer">We fold garments that have been washed and dried prior to arrival</p>

			<div class="obe-pt-4 obe-pb-4 obe-text--align-center obe-text--uppercase">
				<push-button wide color="orange" @click="submit">Continue</push-button>
			</div>

		</obe-form>
	</card>
</template>

<script>
import _ from 'lodash/string';
import { mapState, mapGetters } from 'vuex';

import types from '../store/types';
//import { freqOptions } from 'obe/screens/booking-form/info';

import { visitOptions } from '../info';

export default {
	//freqOptions,
	visitOptions,

	data()
	{
		return {
			tier: '',
			//frequency: '',
			optionSelected: '',
			laundryFolded: '',
			//updateOn: '',

			tiers: [
				{ text: 'Gold', value: 'linen_laundry_gold' },
				{ text: 'Silver', value: 'linen_laundry_silver' },
			],

			foldOptions: [
				{ text: 'No', value: 'no' },
				{ text: 'Small', value: 'small' },
				{ text: 'Medium', value: 'medium' },
				{ text: 'Large', value: 'large' },
			],

			rules: {
				//updateOn: [ v => !!v || 'Please select an option above' ],
				option: [ v => !!v || 'Please select how many rooms' ],
				laundryFolded: [ v => !!v || 'Please select an option above' ],
			}
		}
	},

	computed: {
		...mapGetters(['getBookingOptionsByRoom']),
		...mapState({
			//addons: state => state.bookingForm.addons,
			//numRooms: state => state.bookingForm.homeInfo.numRooms,
			//cleaningFreq: state => state.bookingForm.booking.cleaningFreq,

			addons: state => state.schedule.apptAddons,
			numRooms: state => state.schedule.apptBooking.num_rooms,
			cleaningFreq: state => state.schedule.apptBooking.cleaning_freq,
			isRecurring: state => state.schedule.isRecurring,
		}),

		options()
		{
			return this.tier != '' ? this.getAddonOptions(this.tier) : [];
		}
	},

	methods: {
		// return all options without 'no' as an option with option names capitalized
		getAddonOptions(name)
		{
			var options = this.getBookingOptionsByRoom(name, this.numRooms);
			return options
				.filter(o => o.option_name.toLowerCase() != 'no')
				.map(o => ({ text: _.capitalize(o.option_name), value: o.option_name }));
		},

		resetAddons()
		{
			this.$store.commit(types.DELETE_ADDON, 'linen_laundry_gold');
			this.$store.commit(types.DELETE_ADDON, 'linen_laundry_silver');
			this.$store.commit(types.DELETE_ADDON, 'laundry_folded');
		},

		submit()
		{
			if (!this.$refs.form.validate())
				return;

			// remove previous laundry addons
			this.resetAddons();

			// add laundry service addon
			this.$store.commit(types.UPDATE_ADDON, {
				addon_name: this.tier,
				frequency: this.cleaningFreq == 'one-time' ? 'initial' : 'regular',
				//update_on: this.isRecurring ? this.updateOn : 'this',
				option_selection: this.optionSelected,
			});

			// add laundry folded
			if (this.laundryFolded != 'no')
			{
				this.$store.commit(types.UPDATE_ADDON, {
					addon_name: 'laundry_folded',
					frequency: this.cleaningFreq == 'one-time' ? 'initial' : 'regular',
					//update_on: this.isRecurring ? this.updateOn : 'this',
					option_selection: this.laundryFolded,
				});
			}

			this.dismiss(true);
		},

		remove()
		{
			this.resetAddons();
			this.dismiss(false);
		},

		dismiss(updated)
		{
			this.$emit('closed', updated);
		}
	},

	mounted()
	{
		if (this.addons.linen_laundry_gold)
			this.tier = 'linen_laundry_gold';
		else if (this.addons.linen_laundry_silver)
			this.tier = 'linen_laundry_silver';

		if (this.tier != '')
		{
			//this.frequency = this.addons[this.tier].frequency;
			//this.updateOn = this.addons[this.tier].update_on;
			this.optionSelected = this.addons[this.tier].option_selection;
		}

		this.laundryFolded = this.addons.laundry_folded ? this.addons.laundry_folded.option_selection.toLowerCase() : 'no';
	}
}
</script>