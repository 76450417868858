<template>
	<div :class="classes" @keydown="onKeyDown">
		<!--
		<select :value="value" class="obe-focus-hack" @blur="active=false" @click.prevent="/**/" @keydown.prevent="/**/" @keypress.prevent="/**/" @keyup.prevent="/**/">
			<option v-for="item in items" :key="item[itemValue]">{{ item[itemText] }}</option>
		</select>
		-->

		<input 
			readonly 
			ref="input"
			type="text"
			class="obe-dropdown__selected"
			:value="showingValue" 
			:placeholder="showingPlaceholder"
			@click="active = true"
			@blur="active = false"
			:disabled="disabled"
		>

		<!-- HACK: trigger click event on the input if the user clicks on the actual arrow, preserves keyboard/onblur functionality -->
		<div class="obe-dropdown__icon" @click="arrowHack"><span class="obe-i-down-arrow"></span></div>

		<div class="obe-dropdown__options">
			<a 
				v-for="item in items" :key="item[itemValue]"
				href="#" 
				tabindex="-1"
				class="obe-dropdown__item"
				:class="{ 'obe-dropdown__item--selected': item[itemValue] == value }"
				@click.prevent="setValue(item[itemValue])">
				{{ item[itemText] }}
			</a>
		</div>
	</div>
</template>

<script>
import constants from '../constants';

export default {
	props: {
		value: { default: '' },
		items: { type: Array, required: true },
		itemText: { type: String, default: 'text' },
		itemValue: { type: String, default: 'value' },
		noSelection: { type: String, default: '(select an option)' },
		disabled: { type: Boolean, default: false },
	},

	data()
	{
		return {
			active: false,
		}
	},

	computed: {
		classes()
		{
			var classes = ['obe-dropdown'];

			if (this.disabled)
				classes.push('obe-dropdown--disabled');
			else if (this.active)
				classes.push('obe-dropdown--active');

			return classes;
		},

		valueList()
		{
			return this.items.map(item => item[this.itemValue]);
		},

		showingValue()
		{
			var pos = this.valueList.indexOf(this.value);
			return pos >= 0 ? this.items[pos][this.itemText] : '';
		},

		showingPlaceholder()
		{
			var pos = this.valueList.indexOf(this.value);
			return pos == -1 ? this.noSelection : '';
		}
	},

	methods: {
		arrowHack()
		{
			this.active = true;
			this.$refs.input.focus();
		},

		updateValue(val)
		{
			this.$emit('input', val);
			this.$emit('change', val);
		},

		setValue(val)
		{
			//this.$emit('input', val);
			this.updateValue(val);
			this.active = false;
		},

		moveSelection(dir)
		{
			var pos = this.valueList.indexOf(this.value) + dir;

			if (pos < 0)
				pos = 0;
			else if (pos >= this.valueList.length)
				pos = this.valueList.length - 1;

			//this.$emit('input', this.items[pos][this.itemValue]);
			this.updateValue(this.items[pos][this.itemValue]);
		},

		onKeyDown(event)
		{
			// do nothing if the event target doesn't belong to this component,
			// or if the component is disabled
			if (this.disabled || !this.$el.contains(event.target))
				return;

			switch(event.which)
			{
				// display options on space
				case constants.KEY_SPACE:
					this.active = true;
					break;

				case constants.KEY_UP:
					if (this.active)
						this.moveSelection(-1);
					break;

				case constants.KEY_DOWN:
					if (this.active)
						this.moveSelection(1);
					break;

				case constants.KEY_ENTER:
					if (this.active)
						this.active = false;
					break;
			}

			event.preventDefault();
			event.stopPropagation();
			return false;
		}
	},
}
</script>