
export default {
	currentUser: null,
	roomOptions: {},
	optionMap: {},
	collapsed: false,
	franchises: {},
	franchisesByTerritory: {},
	franchiseIds: [],
	upcomingOrders: 0,
	showMobileMenu: false,
	productLoyaltyCode:""
}