
import router from 'obe/dashboard/router';

import Screen from './comp/AccountScreen.vue';


router.addRoutes([
	{
		name: 'db-account',
		path: '/account',
		props: true,
		meta: { title: 'Account' },
		components: { default: Screen, topButtons: null }
	}
]);
