
//import 'sweetalert2/src/sweetalert2.scss'

//import swal from 'sweetalert2';

var dialog = null;

export default {
	setDialogComponent(dlg)
	{
		dialog = dlg;
	},

	async show(text, title, type)
	{
		if (arguments.length == 2)
			type = arguments[1];

		if (dialog)
		{
			await dialog.show(text, type);
			return true;
		}
		else
		{
			window.alert(text, type);
			return true;
		}

		/*await swal.fire({
			title, 
			type,
			text,
		});*/
	},

	async confirm(text, title, type)
	{
		if (arguments.length == 2)
			type = arguments[1];

		if (dialog)
			return await dialog.confirm(text, type);
		else
			return window.confirm(text, type);

		/*var result = await swal.fire({ text, title, type, showCancelButton: true });
		return result.value ? true : false;*/
	}
}