
import Vue from 'vue';
import types from './types';

export default {
	[types.SET_APPT_LIST](state, list)
	{
		var appts = {};

		if (list && list !== null) {
			state.apptList = list.map(a => {
				appts[a.id] = { ...a };
				return a.id;
			});
		} else {
			state.apptList = [];
		}

		state.appts = appts;
		state.apptsLoaded = true;
		return appts;
	},

	[types.SET_LOYALTY](state, payload)
	{
		state.loyaltyCode = payload ;
	},

	[types.SET_CURRENT_APPT_LOYALTY_CODE](state, payload)
	{
		state.currentApptLoyaltyCode = payload.current_appt_loyalty_code;
	},

	[types.SET_SHOW_OFFER_BANNER](state, payload)
	{
		state.showOfferBanner = payload ;
	},

	[types.SET_CURRENT_APPT](state, payload)
	{
		// home access fields to extract from payload
		var accessData = {}, accessFields = [
			'initial_access_type',
			'initial_access_details',
			'initial_alarm_code',
			'initial_garage_code',
			'garbage',
			'garbage_other',
			'parking',
			'parking_other',
			'regular_access_type',
			'regular_access_details',
			'regular_alarm_code',
			'regular_garage_code',
		];

		state.overrides = {
			apply_overrides: false,
			service_type: null,
			initial_duration: null,
			initial_cost: null,
			regular_duration: null,
			regular_cost: null,
		};

		state.baseOverrides = {
			apply_overrides: false,
			service_type: null,
			initial_duration: null,
			initial_cost: null,
			regular_duration: null,
			regular_cost: null,
		};

		// clear current appointment
		if (payload == null)
		{
			state.currentAppt = null;
			state.apptBooking = null;
			state.apptWorkOrder = null;
			state.apptAddons = null;
			state.apptAddress = null;
			state.apptHomeAccess = null;
		}
		else
		{
			// extract data from payload into state
			state.currentAppt = { ...payload.appointment, initial_mp: payload.work_order.initial_wo_mp, contact: { name: `${payload.contact.first_name} ${payload.contact.last_name}` } };
			state.apptBooking = { ...payload.booking };
			state.apptWorkOrder = { ...payload.work_order };
			state.apptFranchise = { ...payload.franchise };
			state.apptAddress = { ...payload.work_order.address };
			state.apptAccess = {};
			state.apptAddons = {};
			state.productLoyaltyCode = payload.product_loyalty_code;

			// Only assign when available, by default it is []
			if (payload.discounts.length > 0) {
				state.currentApptDiscounts = { ...payload.discounts };
			} else {
				state.currentApptDiscounts = [];
			}

			// prefill overrides
			if (state.apptBooking.is_staff_override)
			{
				state.overrides = { 
					apply_overrides: true,
					service_type: state.apptBooking.service_type,
					initial_duration: state.apptBooking.override_initial_minutes,
					initial_cost: state.apptBooking.override_initial_amount,
					regular_duration: state.apptBooking.override_regular_minutes,
					regular_cost: state.apptBooking.override_regular_amount,
				};

				state.baseOverrides = {
					apply_overrides: true,
					service_type: state.apptBooking.service_type,
					initial_duration: state.apptBooking.base_override_initial_minutes,
					initial_cost: state.apptBooking.base_override_initial_amount,
					regular_duration: state.apptBooking.base_override_regular_minutes,
					regular_cost: state.apptBooking.base_override_regular_amount,
				}
			}

			// extract home access info
			for(var accessKey of accessFields)
				accessData[accessKey] = payload.booking[accessKey] || '';
			state.apptAccess = accessData;

			// determine if appointment is recurring and save a flag in state
			var freq = state.apptBooking.cleaning_freq.toLowerCase();
			state.isRecurring = freq != 'once' && freq != 'one-time';

			// extract addons from state
			if (payload.addons && payload.addons instanceof Array)
			{
				var addons = {};
				for(var addon of payload.addons)
				{
					addons[addon.addon_name] = { 
						...addon, 
						update_on: 'this', 
						remove_on: false 
					};
				}

				state.apptAddons = addons;
			}
		}
	},

	[types.SET_APPT_OVERRIDES](state, data)
	{
		state.overrides = { ...state.overrides, ...data };
	},

	[types.SET_APPT_BASE_OVERRIDES](state, data)
	{
		state.baseOverrides = { ...state.baseOverrides, ...data };
		state.overrides = { ...state.baseOverrides, ...data };
	},

	[types.UPDATE_APPT_ACCESS](state, data)
	{
		state.apptAccess = { ...state.apptAccess, ...data };
	},

	[types.SET_CURRENT_ADDON](state, addon)
	{
		if (addon == null)
			state.currentAddon = null;
		else
			state.currentAddon = { ...addon };
	},

	[types.UPDATE_ADDON](state, addon)
	{
		var current = state.apptAddons[addon.addon_name] || { };
		Vue.set(state.apptAddons, addon.addon_name, { ...current, ...addon });
	},

	// This mutation should no longer be used
	/*
	[types.REMOVE_ADDON](state, params)
	{
		//if (state.apptAddons[name])
			//Vue.delete(state.apptAddons, name);
			
		var addon = state.apptAddons[params.name];

		// if this addon is valid then set its removed-on setting to the given parameter
		if (addon)
			Vue.set(state.apptAddons, addon.addon_name, { ...addon, remove_on: params.removeOn })
	},
	*/

	[types.DELETE_ADDON](state, name)
	{
		if (state.apptAddons[name])
			Vue.delete(state.apptAddons, name);
	},

	[types.UPDATE_ESTIMATE](state, data)
	{
		state.estimate = { ...data.estimate };
		state.overrides = { ...state.overrides, ...data.overrides };
	},

	[types.SET_AVAIL_GRADES](state, grades)
	{
		state.availGrades = { ...grades };
	},

	[types.SET_FRANCHISE_CLOSURES](state, closures)
	{
		state.franchiseClosures = { ...closures };
	},

	[types.SET_APPT_PRODUCTS](state, info)
	{
		var products = {};

		if (info.order != null)
		{
			state.apptProductOrder = { ...info.order };
			state.apptProductList = info.products.map(p => {
				products[p.id] = { ...p };
				return p.id;
			});
			state.apptProducts = products;
		}
		else
		{
			state.apptProductOrder = null;
			state.apptProductList = [];
			state.apptProducts = {};
		}
	}
}
