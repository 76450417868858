
export const SET_PAYMENT_METHODS = 'SET_PAYMENT_METHODS';
export const SET_CURRENT_METHOD = 'SET_CURRENT_METHOD';
export const UPDATE_CURRENT_METHOD = 'UPDATE_CURRENT_METHOD';
export const SET_INVOICE_LIST = 'SET_INVOICE_LIST';
export const SET_STATEMENT_INFO = 'SET_STATEMENT_INFO';
export const SET_PUBLIC_PAYMENT_GATEWAY = 'SET_PUBLIC_PAYMENT_GATEWAY';

export default {
	SET_PAYMENT_METHODS,
	SET_CURRENT_METHOD,
	UPDATE_CURRENT_METHOD,
	SET_INVOICE_LIST,
	SET_STATEMENT_INFO,
	SET_PUBLIC_PAYMENT_GATEWAY,
}
