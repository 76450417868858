
import { appConfig } from '../config';

var stripe = null;

function injectStripeJS()
{
	return new Promise((resolve, reject) => {
		var tag = document.createElement('script');
		tag.src = 'https://js.stripe.com/v3/';
		tag.onload = () => resolve();
		document.querySelector('body').appendChild(tag);
	});	
}

async function initStripe(publicKey)
{
	if (stripe == null)
	{
		// load stripe javascript
		await injectStripeJS();

		// create stripe instance
		stripe = Stripe(publicKey);
	}
}

export async function getStripe(key)
{
	if (stripe == null)
		await initStripe(key);

	return stripe;
}
