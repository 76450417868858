<template>
	<card>
		<div class="obe-db-heading">Select an Add-on</div>

		<div class="obe-addons">
			<layout row wrap>
				<flex xs6 md3 v-for="addon in showAddons" :key="addon.name">
					<div class="obe-addons__item">
						<a href="#" class="obe-addons__option" @click.prevent="selectAddon(addon)">
							<div class="obe-addons__option-button">
								<span class="obe-addons__option-icon" :class="addon.icon"></span>
							</div>
							<span class="obe-addons__option-label">
								{{ addon.label }}
							</span>
						</a>
					</div>
				</flex>
			</layout>
		</div>
	</card>
</template>

<script>
import _ from 'lodash/string';
import { mapState, mapGetters } from 'vuex';

import { addonList, serviceTypeAddons } from 'obe/screens/booking-form/info';

import types from '../store/types';

export default {
	addons: addonList,
	serviceTypeAddons,

	computed: {
		...mapGetters(['getBookingOptionsByRoom']),
		...mapState({
			booking: state => state.schedule.apptBooking,
			numRooms: state => state.schedule.apptBooking.num_rooms,
			isRecurring: state => state.schedule.isRecurring,
		}),

		showAddons()
		{
			var result = serviceTypeAddons[this.booking.service_type].map(name => addonList[name]);

			result.push(addonList.disinfection);

			return result;
		}
	},

	methods: {
		dismiss()
		{
			this.$emit('closed');
		},

		// return all options without 'no' as an option with option names capitalized
		getAddonOptions(name)
		{
			var options = this.getBookingOptionsByRoom(name, this.numRooms);
			return options
				.filter(o => o.option_name.toLowerCase() != 'no')
				.map(o => ({ text: _.capitalize(o.option_name), value: o.option_name }));
		},

		selectAddon(addon)
		{
			// get addon data to add
			var options = this.getAddonOptions(addon.name);
			var freq = this.$store.state.schedule.apptBooking.cleaning_freq.toLowerCase();

			freq = freq=="one-time"? "initial":"regular"
			var payload = {
				addon_name: addon.name,
				frequency: freq,
				//update_on: 'this',
				//remove_on: false,
				option_selection: options.length > 0 ? options[0].value : 'yes',
			};

			// dismiss this popup
			this.dismiss();

			// notify parent of the addon that was selected
			this.$emit('selected', payload);

			// add the addon to state and make it the current addon so the addon details popup appears immediately
			//this.$store.dispatch('schedule.addNewAddon', payload);
		}
	}
}
</script>
