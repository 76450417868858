<template>
	<form-field v-bind="formFieldAttrs" hide-label :validate-value="value">
		<slider
			:label="sliderLabel"
			:value="value"
			:min="min"
			:max="max"
			:snap="snap"
			:items="items"
			:disabled="disabled"
			:numbered="numbered"
			:value-labels="valueLabels"
			@input="$emit('input', $event)">
		</slider>
	</form-field>
</template>

<script>
import formFieldProps from '../mixins/form-field';

export default {
	mixins: [formFieldProps],

	props: {
		sliderLabel: { type: String, default: '' },
		min: { type: Number, default: 1 },
		max: { type: Number, default: 10 },
		snap: { type: Number, default: 1 },
		items: { type: Array, default: () => null },
		value: { default: 0 },
		disabled: { type: Boolean, default: false },
		numbered: { type: Boolean, default: false },
		valueLabels: { type: Array },
	}
}
</script>
