<template>
	<card>
		<div class="obe-warning-popup">
			<div class="obe-warning-popup__title">Save changes to?</div>

			<div class="obe-warning-popup__desc">
				Please select an option below to specify which appointments you would like your changes to apply on
			</div>

			<div class="obe-warning-popup__desc">
				<obe-form ref="form">
					<radio-group 
						hide-label
						inline
						centered
						v-model="updateOn"
						:items="$options.visitOptions"
						:rules="rules.updateOn">
					</radio-group>
				</obe-form>
			</div>

			<div v-if="updateOn == 'all'" class="obe-warning-popup__desc">
				
				<div class="obe-alert obe-alert--info">
					Any changes previously made to individual appointments in this schedule will be preserved. 
					If you wish to apply these changes to all appointments please check 'Apply to all appointments' below
				</div>

				<div class="obe-checkbox-group obe-checkbox-group--centered">
					<checkbox v-model="forceOverride">Apply to all appointments</checkbox>
				</div>

			</div>

			<ul class="obe-button-menu obe-button-menu--horz">
				<li><push-button wide outline color="gray" @click="dismiss(false)">Cancel</push-button></li>
				<li><push-button wide color="orange" @click="submit">Update Appointment</push-button></li>
			</ul>
		</div>
	</card>
</template>

<script>
import { visitOptions } from '../info';

export default {
	visitOptions,	

	data()
	{
		return {
			updateOn: '',
			forceOverride: false,

			rules: {
				updateOn: [ v => !!v || 'Please select an option above' ],
			}
		}
	},

	methods: {
		dismiss(result)
		{
			this.$emit('closed', result);
		},

		submit()
		{
			if (this.$refs.form.validate())
				this.dismiss({ updateOn: this.updateOn, forceOverride: this.forceOverride });
		}
	}
}
</script>