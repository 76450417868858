
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_ROOM_OPTIONS = 'SET_ROOM_OPTIONS';
export const SET_DASHBOARD_COLLAPSE = 'SET_DASHBOARD_COLLAPSE';
export const SET_DASHBOARD_FRANCHISES = 'SET_DASHBOARD_FRANCHISES';
export const SET_DASHBOARD_UPCOMING_ORDERS = 'SET_DASHBOARD_UPCOMING_ORDERS';
export const SET_MOBILE_MENU_VISIBLE = 'SET_MOBILE_MENU_VISIBLE';
export const SET_PRODUCT_LOYALTY_CODE = 'SET_PRODUCT_LOYALTY_CODE';

export default {
	SET_CURRENT_USER,
	SET_ROOM_OPTIONS,
	SET_DASHBOARD_COLLAPSE,
	SET_DASHBOARD_FRANCHISES,
	SET_DASHBOARD_UPCOMING_ORDERS,
	SET_MOBILE_MENU_VISIBLE,
	SET_PRODUCT_LOYALTY_CODE
}