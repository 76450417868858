<template>
	<div class="obe-datepicker">
		<div class="obe-datepicker__pill" @click="active=true">
			<input
				type="text" 
				:value="formattedValue"
				:placeholder="placeholder"
				class="obe-datepicker__display" 
				@click="active=true"
				readonly
			>
		</div>
		<div class="obe-datepicker__dropdown" :class="{ 'obe-datepicker__dropdown--active': active }">
			<calendar 
				:value="value" 
				:check-date="checkDate"
				:min-date="minDate"
				@input="$emit('input', $event);"
				@date:click="active = false">
			</calendar>
			<push-button block color="grey" @click="active = false">Close</push-button>
		</div>
	</div>
</template>

<script>
import moment from 'moment';

export default {
	props: {
		disabled: { type: Boolean, default: false },
		value: { type: String, default: '' },
		dateFormat: { type: String, default: 'YYYY-MM-DD' },
		placeholder: { type: String, default: '' },
		checkDate: { type: Function },
		minDate: { type: String, default: '' },
	},

	data()
	{
		return {
			active: false,
		}
	},

	computed: {
		formattedValue()
		{
			if (!this.value)
			{
				return '';
			}
			else
			{
				var date = moment(this.value, 'YYYY-MM-DD');
				return date.format(this.dateFormat);
			}
		}
	},

	methods: {
		outsideClickHandler(event)
		{
			// only want to dismiss the popup if the event target is not within this component
			if (!this.$el.contains(event.target))
				this.active = false;
		}
	},

	mounted()
	{
		this.onOutsideClick = this.outsideClickHandler.bind(this);
		document.body.addEventListener('click', this.onOutsideClick);
	},

	beforeDestroy()
	{
		document.body.removeEventListener('click', this.onOutsideClick);
	}
}
</script>
