<template>
	<div :class="classes">
		<div :class="`${baseClass}__title-bar`" @click="toggle">
			<slot name="title">
				<div :class="`${baseClass}__title`">{{ title }}</div>
			</slot>
		</div>
		<fade-transition>
			<div :class="`${baseClass}__content`" v-if="isExpanded">
				<slot></slot>
			</div>
		</fade-transition>
	</div>
</template>

<script>
export default {
	props: {
		title: { type: String },
		open: { type: Boolean, default: false },
		bordered: { type: Boolean, default: false },
		value: { type: Boolean, default: null },
	},

	data()
	{
		return {
			expanded: false,
		}
	},

	computed: {
		baseClass()
		{
			return this.bordered ? 'obe-expandable-bordered' : 'obe-expandable';
		},

		classes()
		{
			var cls = this.baseClass;

			if (this.isExpanded)
				cls += ` ${this.baseClass}--expanded`;

			return cls;
		},

		isExpanded()
		{
			if (this.value !== null)
				return this.value;
			else
				return this.expanded;
		}
	},

	methods: {
		toggle()
		{
			if (this.value !== null)
				this.$emit('input', !this.value);
			else
				this.expanded = !this.expanded;
		}
	},

	created()
	{
		if (this.open)
		{
			if (this.value !== null)
				this.$emit('input', true);
			else
				this.expanded = true;
		}
	}
}
</script>