
export default {
	appointmentList: state => state.apptList.map(id => state.appts[id]),

	franchiseDays: state => {
		if (state.apptFranchise != null)
		{
			var type;

			if (state.apptBooking.cleaning_freq.toLowerCase() == 'once')
				type = 'regular';
			else if (state.apptBooking.service_type == 'post construction')
				type = 'post-constrution';
			else
				type = 'one-time';

			return state.apptFranchise.days.filter(fd => fd.service_type == type).map(fd => fd.day);
		}
		else
		{
			return [];
		}
	},

	apptProductList: state => state.apptProductList.map(id => state.apptProducts[id]),

}