<template>
	<div class="obe-db-statement">
		<div class="obe-db-statement__label">Total Balance Due</div>
		<div :class="amountClass">{{ balance | currency }}</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';

import filters from 'obe/shared/filters';

export default {
	mixins: [filters],

	computed: {
		...mapState({
			balance: state => state.payments.statement.due,
		}),

		amountClass()
		{
			var cls = 'obe-db-statement__amount';

			if (this.balance == 0)
				cls += ' obe-db-statement__amount--green';
			else
				cls += ' obe-db-statement__amount--red';

			return cls;
		}
	}
}
</script>