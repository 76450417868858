<template>
	<div class="obe-db-item-box obe-db-item-box--has-status" :class="{ 'obe-db-item-box--top-detail': this.topDetail }">
		<div class="obe-db-item-box__status" :style="{ 'background-color': statusColor }"></div>

		<div class="obe-db-item-box__title">
			{{ item.date | dateFormat('MMM D, YYYY') }}
		</div>
	<div class="obe-db-item-box__detail">
			<div class="obe-db-item-box__detail-name">Type Of Cleaning</div>
			<div class="obe-db-item-box__detail-value">{{ item.appt.work_order.service_type| capitalize }}</div>
		</div>
		<div class="obe-db-item-box__detail">
			<div class="obe-db-item-box__detail-name">Service appointment#</div>
			<div class="obe-db-item-box__detail-value">{{ item.appt.appointment_number }}</div>
		</div>
		<div class="obe-db-item-box__detail">
			<div class="obe-db-item-box__detail-name">Street Address</div>
			<div class="obe-db-item-box__detail-value">{{ item.appt.address.street }}</div>
		</div>
		<div class="obe-db-item-box__detail">
			<div class="obe-db-item-box__detail-name">Contact</div>
			<div class="obe-db-item-box__detail-value">{{ this.item.appt.contact.name }}</div>				
		</div>
		
		<div class="obe-db-item-box__detail">
			<div class="obe-db-item-box__detail-name">Duration team of 3</div>
			<div class="obe-db-item-box__detail-value">{{ item.appt.duration | duration }}</div>
		</div>
		<div class="obe-db-item-box__detail">
			<div class="obe-db-item-box__detail-name">Frequency</div>
			<div class="obe-db-item-box__detail-value">{{ item.appt.cleaning_frequency }}</div>
		</div>
		<div class="obe-db-item-box__buttons">
			<push-button compact :to="`/appointment/${item.appt.id}`">Edit appointment</push-button>
		</div>
	</div>
</template>

<script>

import moment from 'moment';
import { mapState } from 'vuex';
import filters from 'obe/shared/filters';
import { statusColors as statuses } from '../info';

export default {
	mixins: [filters],

	props: {
		item: { type: Object, required: true },
		topDetail: { type: Boolean, default: false },
	},

	computed: {
		...mapState({
			currentUser: state => state.dashboard.currentUser,
		}),

		status()
		{
			return this.item.appt.status.toLowerCase().trim();
		},

		statusColor()
		{
			return statuses[this.status];
		},

		isScheduled()
		{
			var scheduled = ['scheduled', 'dispatched', 'in progress'];
			return scheduled.indexOf(this.status) >= 0;
		},

		isEditable()
		{
			var start = moment(this.item.appt.arrival_window_start_time);
			return moment().isSameOrBefore(start);
		},

		arrivalWindow()
		{
			if (this.isScheduled)
			{
				// var start = moment(this.item.appt.arrival_window_start_time);
				// var end = moment(this.item.appt.arrival_window_end_time);
				// var start = moment(this.item.appt.sched_start_time);
				// var end = moment(this.item.appt.sched_end_time);
				// return `${start.format('h:mma')} - ${end.format('h:mma')}`;
				return this.item.appt.work_order.arrival_window_for_email
			}
			else
			{
				return 'N/A';
			}
		},
	}
}
</script>
