<template>
	<div class="obe-card" :class="{ 'obe-card--expanded': open, 'obe-card--collapsed': !open }">
		<div class="obe-card__content">
			<a href="#" class="obe-card__toggle" @click.prevent="toggle">
				<span class="obe-sr-only">{{ open ? `Hide ${title}` : `Show ${title}` }}</span>
				<span class="obe-i-down-arrow-bold"></span>
			</a>

			<slot name="title">
				<div class="obe-card-title">{{ title }}</div>
			</slot>
			
			<fade-transition>
				<div v-if="open">
					<slot></slot>
				</div>
			</fade-transition>
		</div>
	</div>	
</template>

<script>
export default {
	props: {
		title: { type: String, default: '' },
		open: { type: Boolean, default: false },
	},

	methods: {
		toggle()
		{
			this.$emit('update:open', !this.open);
		}
	}
}
</script>