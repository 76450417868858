<template>
	<button ref="btn" :class="classes" :disabled="disabled || loading" @click.prevent="onClick">
		<slot v-if="!loading">{{ label }}</slot>
		<slot v-else name="loading">Please wait...</slot>
	</button>
</template>

<script>
export default {
	props: {
		label: { type: String, default: '' },
		color: { type: String, default: 'blue' },
		block: { type: Boolean, default: false },
		wide: { type: Boolean, default: false },
		large: { type: Boolean, default: false },
		small: { type: Boolean, default: false },
		compact: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
		loading: { type: Boolean, default: false },
		outline: { type: Boolean, default: false },
		submit: { type: Boolean, default: false },
		to: { type: [String, Object], default: null },
		href: { type: String, default: null },
		open: { type: String, default: null },
		width: { type: String, default: 'auto' },
	},

	computed: {
		classes()
		{
			var base = `obe-btn-${this.color}`;
			var classes = [base];
			
			if (this.block)
				classes.push(`${base}--block`);

			if (this.loading)
				classes.push(`${base}--loading`);

			if (this.wide)
				classes.push(`${base}--wide`);

			if (this.large)
				classes.push(`${base}--large`);

			if (this.small)
				classes.push(`${base}--small`);

			if (this.compact)
				classes.push(`${base}--compact`);

			if (this.outline)
				classes.push(`${base}--outline`);

			if (this.submit)
				classes.push(`${base}--submit`);

			return classes;
		}
	},

	methods: {
		focus()
		{
			this.$el.focus();
		},

		onClick(event)
		{
			// do nothing if the button is loading
			if (this.loading)
			{
				event.stopPropagation();
				event.preventDefault();
				return false;
			}
			// navigate to the given url if 'href' is specified
			else if (this.href)
			{
				window.location = this.href;
			}
			// open a new tab if 'open' is specified
			else if (this.open)
			{
				window.open(this.open, '_blank');
			}
			// navigate to the route if 'to' is specified
			else if (this.to && this.$router)
			{
				this.$router.push(this.to)
				event.stopPropagation();
				event.preventDefault();
				return false;
			}
			// otherwise emit a click event
			else
			{
				this.$emit('click');
				return false;
			}
		}
	}
}
</script>