<template>
	<div>
		<div v-if="loading">
			<loading-spinner>Please wait...</loading-spinner>
		</div>
		<div v-else class="obe-booking-complete is-dashboard">

			<div class="bc-info">
				<div class="bc-info-col">
					<div class="bc-caption">Booking Details</div>

					<div class="bc-booking">
						<div class="bc-group">
							<div class="bc-booking-row">
								<div class="bc-booking-left bc-small bc-grey">Date of service</div>
								<div class="bc-booking-right">
									<div class="bc-section bc-large">
										{{ appt.arrival_window_start_time | dateFormat('MMMM D, YYYY') }}
									</div>
								</div>
							</div>

							<div class="bc-booking-row">
								<div class="bc-booking-left bc-small bc-grey">Time preference</div>
								<div class="bc-booking-right">
									<div class="bc-subsection bc-medium">
										{{ timeLabels[booking.initial_arrival_window] }}
									</div>
								</div>
							</div>

							<div v-if="booking.cleaning_freq != 'one-time'" class="bc-booking-row">
								<div class="bc-booking-left bc-small bc-grey">Regular cleaning starting date</div>
								<div class="bc-booking-right">
									<div class="bc-section">
										<div class="bc-large">{{ booking.regular_arrival_date | dateFormat('MMMM D, YYYY') }}</div>
										<div class="bc-medium">{{ timeLabels[booking.regular_arrival_time] }}</div>
									</div>
								</div>
							</div>

							<div class="bc-booking-row">
								<div class="bc-booking-left bc-small bc-grey">Address</div>
								<div class="bc-booking-right">
									<div class="bc-section bc-medium">
										{{ address.street }},
										{{ address.city }} {{ address.state }},
										{{ address.postal_code }}
									</div>
								</div>
							</div>

							<div v-if="!this.appt.is_regular_appointment" class="bc-booking-row">
								<div class="bc-booking-left bc-small bc-grey">{{ booking.cleaning_freq != 'one-time' ? 'Initial access' : 'Access' }}</div>
								<div class="bc-booking-right">
									<div class="bc-section">
										{{ accessLabels[booking.initial_access_type] }}
										<template v-if="booking.initial_access_details != null && booking.initial_access_details.trim() != ''">
											- {{ booking.initial_access_details }}
										</template>
										<br>
										Garage code: {{ booking.initial_garage_code | obscureCode }}, 
										Alarm code: {{ booking.initial_alarm_code | obscureCode }}
										<br>
										{{ booking.parking == 'other' ? booking.parking_other : parkingLabels[booking.parking] }}, 
										{{ booking.garbage == 'other' ? booking.garbage_other : garbageLabels[booking.garbage] }}
									</div>
								</div>
							</div>

							<div v-if="booking.cleaning_freq != 'one-time'" class="bc-booking-row">
								<div class="bc-booking-left bc-small bc-grey">Regular access</div>
								<div class="bc-booking-right">
									<div class="bc-section">
										{{ accessLabels[booking.regular_access_type] }}
										<template v-if="booking.regular_access_details != null && booking.regular_access_details.trim() != ''">
											- {{ booking.regular_access_details }}<br>
										</template>
										Garage code: {{ booking.regular_garage_code | obscureCode }}, 
										Alarm code: {{ booking.regular_alarm_code | obscureCode }}
									</div>
								</div>
							</div>

							<div class="bc-booking-row">
								<div class="bc-booking-left bc-small bc-grey">Mobile phone</div>
								<div class="bc-booking-right">
									<div class="bc-section">
										{{ customer.mobile_phone }}
									</div>
								</div>
							</div>
						</div>
						
						<div class="bc-group">
							<div class="bc-booking-row">
								<div class="bc-booking-left bc-small bc-grey">Type</div>
								<div class="bc-booking-right">
									<div class="bc-section bc-medium">
										{{ booking.service_type | capitalize }}, {{ booking.cleaning_freq | capitalize }}
									</div>
								</div>
							</div>

							<div class="bc-booking-row">
								<div class="bc-booking-left bc-small bc-grey">Property info</div>
								<div class="bc-booking-right">
									<div class="bc-section bc-medium">
										{{ booking.num_rooms }} Bedrooms,
										{{ booking.bathrooms }} Bathrooms,<br>
										{{ booking.kitchens }} Kitchens,
										{{ booking.levels }} Levels

										<div v-if="petsAndFloors.length > 0" class="bc-grey">{{ petsAndFloors.join(", ") }}</div>
									</div>
								</div>
							</div>
						</div>
						
						<div class="bc-group">
							<div class="bc-booking-row">
								<div class="bc-booking-left bc-small bc-grey">Disinfection Service</div>
								<div class="bc-booking-right">
									<div class="bc-section">
										{{ hasDisinfection ? 'Included' : 'Not included' }}
										{{ hasDisinfection && booking.cleaning_freq != 'one-time' ? freqLabels[addonInfo.disinfection.frequency] : '' }}
									</div>
								</div>
							</div>

							<div v-if="addons.length > 0" class="bc-booking-row">
								<div class="bc-booking-left bc-small bc-grey">ADD-ONS</div>
								<div class="bc-booking-right bc-section">
									<!-- list all addons except for disinfection -->
									<template v-for="addon in addons">
										<div v-if="addon.addon_name != 'disinfection'" :key="addon.id" class="bc-small">
											{{ addonInfo[addon.addon_name].label }}
											<span v-if="booking.cleaning_freq != 'one-time'" class="bc-grey">{{ freqLabels[addon.frequency] }}</span>
										</div>
									</template>
								</div>
							</div>
						</div>

						<div class="bc-group">
							<div v-if="!this.appt.is_regular_appointment" class="bc-booking-row">
								<div class="bc-booking-left bc-grey">{{ booking.cleaning_freq == 'one-time' ? 'Price' : 'Initial price' }}</div>
								<div v-if="this.bookingDiscounts.length > 0" class="bc-booking-right bc-section">
									<span class="bc-medium discounted-amount">{{ booking.initial_amount | currency }}&nbsp;&nbsp;</span>
									<span class="bc-medium">{{ this.getDiscountedAmount() | currency }}&nbsp;&nbsp;</span>
								</div>
								<div v-else class="bc-booking-right bc-section">
									<span class="bc-medium">{{ booking.initial_amount | currency }}&nbsp;&nbsp;</span>
								</div>
							</div>

							<div v-if="!this.appt.is_regular_appointment" class="bc-booking-row">
								<div class="bc-booking-left bc-grey">{{ booking.cleaning_freq == 'one-time' ? 'Duration' : 'Initial duration' }}</div>
								<div class="bc-booking-right bc-section">
									<span class="bc-medium bc-grey">
										{{ booking.cleaning_freq == 'one-time' ? (appt.duration | minutesToHours) : (booking.initial_minutes / 3) | minutesToHours }}
									</span>
								</div>
							</div>

							<div v-if="booking.cleaning_freq != 'one-time'" class="bc-booking-row">
								<div class="bc-booking-left bc-grey">Regular price</div>
								<div class="bc-booking-right bc-section">
									<span class="bc-medium">{{ booking.regular_amount | currency }}&nbsp;&nbsp;</span>
								</div>
							</div>

							<div v-if="booking.cleaning_freq != 'one-time'" class="bc-booking-row">
								<div class="bc-booking-left bc-grey">Regular duration</div>
								<div class="bc-booking-right bc-section">
									<span class="bc-medium bc-grey">
										{{ appt.duration | minutesToHours }}
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!--
				<div class="bc-info-col bc-franchise-info">
					<div class="bc-caption">Serving Office</div>

					<div class="bc-section bc-small">
						{{ franchise.name }}<br>
						{{ franchise.address }}<br>
						{{ franchise.city }} {{ franchise.province }} {{ franchise.postal_code }}
					</div>

					<div class="bc-section bc-small">
						<a class="bc-link" :href="`mailto:${franchise.email}`">{{ franchise.email }}</a><br>
						{{ franchise.phone }}
					</div>
				</div>
				--->
			</div>

			<div class="bc-buttons">
				<push-button wide outline color="grey" :to="`/appointment/${this.id}`">Back to appointment</push-button>
			</div>
		</div>
	</div>
</template>

<script>

import _ from 'lodash';
import { mapState } from 'vuex';

import api from 'obe/dashboard/api';
import filters from 'obe/shared/filters';

import { addonList } from 'obe/screens/booking-form/info';

export default {
	mixins: [filters],

	filters: {
		minutesToHours(val)
		{
			var hours = Math.floor(val / 60);
			var mins = val % 60;
			var personHours = (val / 60.0);
			
			return `${hours} hour(s) ${mins} minute(s) with a team of 3 (${_.round(personHours * 3, 2)} person hours)`;
		},

		obscureCode(val)
		{
			if (val)
				return `***${val.slice(-1)}`;
			else
				return 'N/A';
		}
	},

	props: {
		id: { type: String, required: true },
	},

	data()
	{
		return {
			loading: true,
			franchise: null,
			booking: null,
			address: null,
			customer: null,
			appt: null,
			addons: [],
			addonInfo: {},
			workOrder: {},
			bookingDiscounts: [],
			movingServiceDefaultAddonKeys: ['marks_on_walls', 'cupboards_and_closets'],

			freqLabels: {
				initial: 'First visit',
				regular: 'Regular visits',
				both: 'All visits',
			},

			timeLabels: {
				'flexible-8am-4pm': 'Flexible: 8am-4pm',
				'8am-1pm': '8am-1pm',
				'11am-4pm': '11am-4pm',
			},

			accessLabels: {
				'door-code': 'Use door code',
				'will be at home': 'I will be home',
				'will hide key': 'I will hide key',
				'drop key off': 'I will drop off key at AspenClean\'s office',
				'other': 'Other',
			},

			parkingLabels: {
				drive_way: 'Driveway parking',
				street: 'Free street parking',
				paid: 'Paid parking (charges apply)',
			},

			garbageLabels: {
				garage: 'Garbage bin in the garage', 
				back_dumpster: 'Dumpster in the back of the house', 
				front_dumpster: 'Dumpster in the front of the house',
			}
		}
	},

	computed: {
		...mapState({
			dashboardUrl: state => state.app.dashboardUrl,
		}),

		hasDisinfection()
		{
			for(var addon of this.addons)
				if (addon.addon_name == 'disinfection')
					return true;

			return false;
		},

		petsAndFloors()
		{
			var parts = [];

			if (this.booking.pets.toLowerCase() == 'yes')
				parts.push('Pets');

			if (this.booking.hardsurface_floors.toLowerCase() == 'yes')
				parts.push('Hard surface floors in bedrooms');

			return parts;
		}
	},

	methods: {
		async loadBooking()
		{
			this.loading = true;

			try
			{
				var result = await api.get(`/appointments/${this.id}`);
				const addons = result.data.addons;

				// Filter out default addons if service type is 'Moving'
				if (result.data.booking.service_type === 'moving') {
					const addons = result.data.addons.filter(addon => !this.movingServiceDefaultAddonKeys.includes(addon.addon_name));
				}

				this.appt = result.data.appointment;
				this.booking = result.data.booking;
				this.address = result.data.appointment.address;
				this.workOrder = result.data.work_order;
				this.customer = result.data.contact;
				this.addons = addons || [];
				this.bookingDiscounts = result.data.discounts || [];
				//this.franchise = result.data.franchise;
			}
			finally
			{
				this.loading = false;
			}
		},

		gotoOrders()
		{
			if (this.booking != null)
				document.location = `${this.dashboardUrl}/catalog`;
		},

		gotoDashboard()
		{
			if (this.booking != null)
				document.location = this.dashboardUrl;
			
		},

		getDiscountedAmount()
		{
			var discount = this.bookingDiscounts[0];
			var total = this.booking.initial_amount;

			if (discount.deduct_type === 'percent') {
				total = total - (total * (discount.deduct_amount / 100));
				total = _.round(total, 2);

				return total;
			} else {
				total = total - discount.deduct_amount;

				return total;
			}
		}
	},

	created()
	{
		var info = JSON.parse(JSON.stringify(addonList));

		info.linen_laundry_silver = { label: 'Linen Laundry Silver' };
		info.linen_laundry_gold = { label: 'Linen Laundry Gold' };
		info.laundry_folded = { label: 'Laundry Folded' };

		this.addonInfo = info;
	},

	mounted()
	{
		this.loadBooking();
	}
}
</script>
