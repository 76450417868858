<template>
	<div>
		<fade-transition>
			<div v-if="!$route.query.token">
				No token!
			</div>
			<div v-else-if="!result">
				<layout row wrap>
					<flex md6 xs12>
						<div class="obe-db-subheading">Please enter your email address and new password</div>

						<obe-form ref="form">
							<layout row reverse wrap stack="md" class="obe-mb-4">
								<flex xs12 md6 mdpush6>
									<text-field
										hide-label
										placeholder="Confirm Email Address"
										v-model.trim="email"
										:rules="rules.email">
									</text-field>
								</flex>

								<flex md6 xs12>
									<password-security :password="password || ''" v-model="passwordValid" />
								</flex>

								<flex md6 xs12>
									<text-field
										hide-label
										input-type="password"
										placeholder="New Password"
										v-model.trim="password"
										:rules="rules.password">
									</text-field>

									<text-field
										hide-label
										input-type="password"
										placeholder="Confirm New Password"
										v-model.trim="confPassword"
										:rules="rules.confPassword">
									</text-field>
								</flex>
							</layout>

							<push-button block color="orange" label="Reset Password" :loading="loading" @click="submit"></push-button>
						</obe-form>
					</flex>
				</layout>
			</div>

			<div v-else>
				<div class="obe-title">Password reset successfully!</div>
				<p class="obe-body-text">Please <router-link to="/login">click here</router-link> to login</p>
			</div>
		</fade-transition>
	</div>
</template>

<script>
import validate from 'obe/shared/validators';
import api from 'obe/dashboard/api';

export default {

	data()
	{
		return {
			email: '',
			password: '',
			confPassword: '',
			loading: false,
			result: null,
			passwordValid: false,

			rules: {
				email: [
					v => !!v || 'Please enter your email address',
					v => validate.email(v) || 'Please enter a valid email address',
				],

				password: [
					v => !!v || 'Please enter your new password',
					v => this.passwordValid || 'Password does not meet the specified requirements',
				],

				confPassword: [
					v => !!v || 'Please confirm your new password',
					v => v == this.password || 'Passwords do not match!',
				]
			}
		}
	},

	methods: {
		async submit()
		{
			if (!this.$refs.form.validate())
				return;

			this.loading = true;

			try
			{
				var params = {
					token: this.$route.query.token,
					email: this.email,
					password: this.password,
					conf_password: this.confPassword,
				};

				await api.post('/password_reset/reset', params);

				this.result = true;
			}
			finally
			{
				this.loading = false;
			}
		}
	}
}
</script>
