
import Vue from 'vue';

// CONTROLS
import TextBox from './comp/TextBox.vue';
import TextArea from './comp/TextArea.vue';
import PushButton from './comp/PushButton.vue';
import Toggle from './comp/Toggle.vue';
import Slider from './comp/Slider.vue';
import Dropdown from './comp/Dropdown.vue';
import Checkbox from './comp/Checkbox.vue';
import Radio from './comp/Radio.vue';
import OptionSelector from './comp/OptionSelector.vue';
import DatePicker from './comp/DatePicker.vue';
import Calendar from './comp/Calendar.vue';
import Switcher from './comp/Switcher.vue';
import StripeCardField from './comp/StripeCardField.vue';

// OTHER
import LoadingSpinner from './comp/LoadingSpinner.vue';
import PasswordSecurity from './comp/PasswordSecurity.vue';

// FORM FIELDS
import ObeForm from './comp/ObeForm.vue';
import FormField from './comp/FormField.vue';
import TextField from './comp/TextField.vue';
import SliderField from './comp/SliderField.vue';
import DropdownField from './comp/DropdownField.vue';
import RadioGroup from './comp/RadioGroup.vue';
import FileSelector from './comp/FileSelector.vue';

// LAYOUT
import Layout from './comp/Layout.vue';
import Flex from './comp/Flex.vue';

// SURFACES
import Card from './comp/Card.vue';
import CollapsibleCard from './comp/CollapsibleCard.vue';
import CardSection from './comp/CardSection.vue';
import Modal from './comp/Modal.vue';
import Expandable from './comp/Expandable.vue';

// TRANSITIONS
import FadeTransition from './comp/FadeTransition.vue';

var ctrls = {
	TextBox, TextArea, PushButton, Toggle, Slider, Dropdown, Checkbox, Radio, OptionSelector, DatePicker, Calendar, Switcher, StripeCardField,
	ObeForm, FormField, TextField, SliderField, RadioGroup, DropdownField, FileSelector,
	Layout, Flex, 
	Card, CollapsibleCard, CardSection, Modal, Expandable,
	FadeTransition,
	LoadingSpinner,
	PasswordSecurity,
};

for(var key in ctrls)
	Vue.component(key, ctrls[key]);