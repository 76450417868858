<template>
	<card>
		<div class="obe-warning-popup">
			<div class="obe-warning-popup__title">Reschedule or Skip?</div>

			<div class="obe-warning-popup__desc">
				To reschedule just tell us a new date and time preference
			</div>

			<ul class="obe-button-menu obe-button-menu--vert-center">
				<li><push-button wide color="orange" @click="$showRescheduleForm()">Reschedule</push-button></li>
				<li><p>No thanks, I'd like to skip this job</p></li>
				<li><push-button wide color="orange" @click="$showSkipForm()">Skip</push-button></li>
			</ul>
		</div>
	</card>
</template>

<script>
export default {
	inject: ['$showRescheduleForm', '$showSkipForm'],
}
</script>