<template>
	<ul class="obe-pw-security">
		<li class="pw-is-title">Password Requirements</li>
		<li :class="testStatusClasses.minLength">At least 8 characters long</li>
		<li :class="testStatusClasses.hasNumber">Includes at least 1 number</li>
		<li :class="testStatusClasses.hasUppercase">Includes at least 1 uppercase letter</li>
		<li :class="testStatusClasses.hasSpecialChar">Includes at least 1 special character (one of: !@#$%^&amp;*-=_+)</li>
	</ul>
</template>

<script>
export default {
	props: {
		value: { type: Boolean },
		password: { type: String, required: true },
	},

	computed: {
		// Returns an object specifying the status of the password requirements
		passwordTests()
		{
			return {
				minLength: this.password.length >= 8,
				hasNumber: this.checkExpr(/[0-9]/),
				hasUppercase: this.checkExpr(/[A-Z]/),
				hasSpecialChar: this.checkExpr(/[!@#$%^&*\-=_+]/),
			}
		},

		// converts passwordTests into CSS class names to be used in the template above to keep template more readable
		testStatusClasses()
		{
			var tests = this.passwordTests;

			return {
				minLength: tests.minLength ? 'pw-is-good' : 'pw-is-bad',
				hasNumber: tests.hasNumber ? 'pw-is-good' : 'pw-is-bad',
				hasUppercase: tests.hasUppercase ? 'pw-is-good' : 'pw-is-bad',
				hasSpecialChar: tests.hasSpecialChar ? 'pw-is-good' : 'pw-is-bad',
			}
		}
		
	},

	watch: {
		// whenever the password changes emit a true/false flag based on tests
		password()
		{
			// get values of tests
			var tests = Object.values(this.passwordTests);
			
			// emit true only if there are no false values
			this.$emit('input', tests.indexOf(false) == -1);
		}
	},

	methods: {
		checkExpr(regex)
		{
			return this.password.match(regex) != null;
		}
	}
}
</script>