<template>
	<card>
		<template v-if="loading">
			Please wait...
		</template>

		<template v-else>
			<div class="obe-db-heading">Start new booking</div>
			<obe-form ref="form">
				<dropdown-field
					type="vert"
					label="Please select an address"
					v-model="selection"
					:rules="rules.addr"
					:items="addressOptions">
				</dropdown-field>

				<layout row wrap>
					<flex md6>
						<form-field type="vert" label="Bedrooms">
							<slider
								numbered
								:disabled="selection != 'new'"
								:min="1"
								:max="10"
								v-model="bedrooms">
							</slider>
						</form-field>
					</flex>

					<flex md6>
						<form-field type="vert" label="Bathrooms">
							<slider
								numbered
								:disabled="selection != 'new'"
								:items="bathroomOptions"
								v-model="bathrooms">
							</slider>
						</form-field>
					</flex>

					<flex md4>
						<!-- key attributes are used below to force Vue to render separate components for either field -->
						<text-field 
							v-if="selection == 'new'"
							key="postalCodeNew"
							type="vert"
							label="Postal Code"
							v-model="postalCode"
							:rules="rules.validatePostalCode">
						</text-field>

						<text-field
							v-else
							key="postalCodeCurrent"
							disabled
							type="vert"
							label="Postal Code"
							:value="selectedAddress.postal_code">
						</text-field>
					</flex>

					<flex md4>
						<dropdown-field 
							type="vert"
							label="Type of cleaning"
							v-model="serviceType"
							:items="$options.serviceTypes"
							:rules="rules.serviceType">
						</dropdown-field>
					</flex>

					<flex md4>
						<dropdown-field 
							type="vert"
							label="Frequency"
							v-model="cleaningFreq"
							:items="freqOptions"
							:rules="rules.cleaningFreq">
						</dropdown-field>
					</flex>
				</layout>
			</obe-form>

			<layout row wrap>
				<!--
				<flex md6>
					<push-button block>Continue with new address</push-button>
				</flex>
				-->
				<flex xs12>
					<push-button block color="orange" @click="submit">Continue with {{ selection == 'new' ? 'new' : 'selected' }} address</push-button>
				</flex>
			</layout>
		</template>
	</card>
</template>

<script>
import moment from 'moment';
import { mapState, mapGetters } from 'vuex';

import { serviceTypes, cleaningFreqOptions } from 'obe/screens/booking-form/info';

import alerts from 'obe/shared/alerts';
import validate from 'obe/shared/validators';
import api from 'obe/dashboard/api';
import { camelCaseFields } from 'obe/shared/util';
import { createApi } from 'obe/api';

const bookingApi = createApi(`//${window.location.host}/obe`);
const bookingUrl = window._spa_data.bookingUrl;

export default {
	serviceTypes,
	cleaningFreqOptions,

	data()
	{
		return {
			addresses: [],
			loading: false,
			selection: null,
			primaryContact: null,
			
			postalCode: '',
			serviceType: 'complete home',
			cleaningFreq: 'one-time',
			bedrooms: 1,
			bathrooms: 1,

			rules: {
				addr: [ v => v !== null || 'Please select an address before continuing' ],
				serviceType: [ v => !!v || 'Please select a cleaning type' ],
				cleaningFreq: [ v => !!v || 'Please select a cleaning frequency' ],
				validatePostalCode: [
					v => !!v || 'Please enter your postal code',
					v => validate.postalCode(v) || 'Invalid postal code'
				]
			}
		}
	},

	computed: {
		...mapGetters(['getBookingOptionsByRoom']),
		...mapState({
			currentUser: state => state.dashboard.currentUser,
		}),

		addressOptions()
		{
			var result = [];

			for(var i=0; i<this.addresses.length; i++)
				result.push({ 
					text: this.addresses[i].name, 
					value: i 
				});
			
			result.push({ text: 'Use new address', value: 'new' });

			return result;
		},

		freqOptions()
		{
			if (this.serviceType == 'complete home')
				return cleaningFreqOptions;
			else 
				return [cleaningFreqOptions[0]];
		},

		bathroomOptions()
		{
			return this.getBookingOptionsByRoom('bathrooms', this.bedrooms).map(o => o.option_name);
		},

		selectedAddress()
		{
			return this.selection != 'new' && this.selection != null ? this.addresses[this.selection] : {};
		}
	},

	watch: {
		serviceType(newVal)
		{
			if (this.serviceType == 'moving')
				this.cleaningFreq = cleaningFreqOptions[0].value;
		},

		selection(newVal)
		{
			if (newVal != 'new')
			{
				this.$nextTick(() => {
					this.bedrooms = this.selectedAddress.bedrooms;
					this.bathrooms = this.selectedAddress.bathrooms;
				});
			}
			else 
			{
				this.$nextTick(() => {
					this.bedrooms = 1;
					this.bathrooms = 1;
				});
			}
		},

		bedrooms(newVal)
		{
			if (this.bathroomOptions.indexOf(this.bathrooms.toString()) == -1)
				this.bathrooms = this.bathroomOptions[0];
		}
	},

	methods: {
		async loadAddresses()
		{
			this.loading = true;

			try
			{
				var result = await api.get('/addresses');

				if (result.data.addresses && result.data.addresses !== null) {
					this.addresses = result.data.addresses.map(a => {
					// if this address' contact is the primary contact then copy the contact info to primaryContact
					if (a.contact.is_primary)
						this.primaryContact = { ...a.contact };

						// add to list of address options
						return { 
							name: `${a.street}, ${a.city}, ${a.province}`,
							id: a.id,
							street_address: a.street,
							apartment: a.apartment,
							city: a.city,
							postal_code: a.postal_code,
							province: a.province,
							bedrooms: a.bedrooms,
							bathrooms: a.bathrooms,
							contact_id: a.contact.id,
							first_name: a.contact.first_name,
							last_name: a.contact.last_name,
							email: a.contact.email,
							phone1: a.contact.mobile_phone,
							phone2: a.contact.other_phone,

							kitchens: a.kitchens,
							extra_rooms: a.extra_rooms,
							size_sq_ft: a.size_sq_ft,
							levels: a.levels,
							hard_surface_floors_in_bedrooms: a.hard_surface_floors_in_bedrooms,
							pets: a.pets,
							pet_s_name: a.pet_s_name,
							special_requirements: a.special_requirements,
							home_access: a.home_access,
							access_details: a.access_details,
							garbage_disposal: a.garbage_disposal,
							garbage_disposal_other: a.garbage_disposal_other,
							parking: a.parking,
							parking_other: a.parking_other,
							regular_cleaning_access: a.regular_cleaning_access,
							regular_cleaning_access_details: a.regular_cleaning_access_details,
							alarm_code: a.alarm_code,
							garage_code: a.garage_code,
							regular_alarm_code: a.regular_alarm_code,
							regular_garage_code: a.regular_garage_code,
						};
					});
				}
			}
			finally
			{
				this.loading = false;
			}
		},

		async submit()
		{
			var addr, bookingData;

			if (!this.$refs.form.validate())
				return;
	
			// get address
			if (this.selection == 'new')
			{
				// abort if there is no primary contact for whatever reason
				if (!this.primaryContact || this.primaryContact == null)
				{
					var pcontact = await api.get('/get_primary_contact');

					if(!pcontact)
					{
						await alerts.show('There is no primary contact!', 'error');
						return;
					}else{
						this.primaryContact = pcontact.data.contact;
					}
				}

				// create new address data structure
				addr = {
					id: 0,
					postal_code: this.postalCode.replace(/\s/g, ''),
					first_name: this.primaryContact.first_name,
					last_name: this.primaryContact.last_name,
					email: this.primaryContact.email,
					phone1: this.primaryContact.mobile_phone,
					phone2: this.primaryContact.other_phone,
					sf_contact_id: this.primaryContact.id
				};
			}
			else
			{
				addr = { ...this.addresses[this.selection] };
			}

			// check availability
			this.loading = true;
			try
			{
				var params, result, stubResult;

				// get estimate from server, this is required to setup the stub record below
				params = { postal_code: addr.postal_code, num_rooms: this.bedrooms, bathrooms: this.bathrooms };
				result = await bookingApi.get('/booking/estimates/homepage', { params });

				// bail out if no franchise was found for whatever reason
				if (!result.data.found)
				{
					alerts.show('We\'re sorry! Unfortunately, AspenClean does not provide service in the requested area. Please, check another location.', 'We\'re sorry', 'warning');
					return;
				}
				
				// assign address province if new
				if (addr.id == 0)
					addr.province = result.data.franchise.province;

				// initialize new booking form data
				var bookingData = {
					booking: { 
						contactFieldsLocked: false,
						cleaningFreq: this.cleaningFreq,
						serviceType: this.serviceType,
						postalCode: addr.postal_code,
					},
					homeInfo: {
						numRooms: this.bedrooms,
						bathrooms: this.bathrooms,
						kitchens: this.selectedAddress.kitchens || 1,
						extraRooms: this.selectedAddress.extra_rooms || 0,
						sqftSize: this.selectedAddress.size_sq_ft || this.getBookingOptionsByRoom('sqft_size', this.bedrooms)[0].option_name,
						levels: this.selectedAddress.levels || 1,
						hardsurfaceFloors: this.selectedAddress.hard_surface_floors_in_bedrooms ? this.selectedAddress.hard_surface_floors_in_bedrooms.toLowerCase() : 'no',
						pets: this.selectedAddress.pets ? this.selectedAddress.pets.toLowerCase() : 'no',
						petName: this.selectedAddress.pet_s_name || '',
						specialReqs: this.selectedAddress.special_requirements || '',
						//sqftSize: this.getBookingOptionsByRoom('sqft_size', this.bedrooms)[0].option_name,
					},
					access: {
						initialAccessType: this.selectedAddress.home_access || '',
						initialAccessDetails: this.selectedAddress.access_details || '',
						garbage: this.selectedAddress.garbage_disposal || '',
						garbageOther: this.selectedAddress.garbage_disposal_other || '',
						parking: this.selectedAddress.parking || '',
						parkingOther: this.selectedAddress.parking_other || '',
						regularAccessType: this.selectedAddress.regular_cleaning_access || '',
						regularAccessDetails: this.selectedAddress.regular_cleaning_access_details || '',
						initialAlarmCode: this.selectedAddress.alarm_code,
						regularAlarmCode: this.selectedAddress.regular_alarm_code,
						initialGarageCode: this.selectedAddress.garage_code,
						regularGarageCode: this.selectedAddress.regular_garage_code,
					},
					address: camelCaseFields(addr),
					franchise: result.data.franchise,
					//selectedAddress: addr.id,
				};


				// start a stub booking record
				stubResult = await bookingApi.post('/booking/estimates/start_new', {
					address_id: addr.id == 0 ? 'new' : addr.id,
					contact_id: addr.contact_id,
					local_date: moment().format('YYYY-MM-DD[T]HH:MM:ssZZ'),
					franchise_id: result.data.franchise.id,
					duration: Math.round(parseFloat(result.data.estimate.initial_minutes)),
					address: addr,
					num_rooms: this.bedrooms,
					bathrooms: this.bathrooms,
					contact_fields_locked: true,
					booking: {
						cleaning_freq: bookingData.booking.cleaningFreq,
						service_type: bookingData.booking.serviceType,
						postal_code: bookingData.address.postalCode.replace(/\s/g, ''),
					}
				});

				bookingData.booking.stubBookingId = stubResult.data.booking_id;
				bookingData.selectedAddress = stubResult.data.address_id;

				// store booking data in session storage and redirect to booking form
				window.sessionStorage.setItem('obe-db-booking-data', JSON.stringify(bookingData));
				window.location = `${bookingUrl}?fromdb=true`;
			}
			finally
			{
				this.loading = false;
			}

		}
	},

	mounted()
	{
		this.loadAddresses();
	}
}
</script>