<template>
	<div :class="classes">
		<div class="obe-option-selector__column" v-for="item in items" :key="item[itemValue]">
			<a
				href="#"
				class="obe-option-selector__option"
				:class="getLinkClasses(item[itemValue])"
				@click.prevent="setValue(item[itemValue])">
				{{ item[itemText] }}
			</a>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		value: { default: '' },
		items: { type: Array, required: true },
		itemText: { type: String, default: 'text' },
		itemValue: { type: String, default: 'value' },
		disabled: { type: Boolean, default: false },
		filled: { type: Boolean, default: false },
		highlight: { default: null },
	},

	computed: {
		classes()
		{
			var classes = ['obe-option-selector'];

			if (this.disabled)
				classes.push('obe-option-selector--disabled');

			if (this.filled)
				classes.push('obe-option-selector--filled');

			return classes;
		},
	},

	methods: {
		// returns true if this.value matches val or if val is contained within value
		isSelected(val)
		{
			if (typeof(this.value) == 'object' && this.value instanceof Array)
				return this.value.indexOf(val) >= 0;
			else 
				return this.value === val;
		},

		isHighlighted(val)
		{
			if (typeof(this.value) == 'object' && this.value instanceof Array)
				return this.highlight.indexOf(val) >= 0;
			else 
				return this.highlight === val;
		},

		getLinkClasses(val)
		{
			return {
				'obe-option-selector__option--selected': this.isSelected(val),
				'obe-option-selector__option--highlighted': this.isHighlighted(val),
			};
		},

		setValue(val)
		{
			// do nothing if disabled
			if (this.disabled)
				return;

			// is this component used to select mulitple options?
			if (typeof(this.value) == 'object' && this.value instanceof Array)
			{
				// create copy of component value
				var newVal = this.value.slice();

				// get position of val within newVal
				var pos = newVal.indexOf(val);

				// remove val if present
				if (pos >= 0)
					newVal.splice(pos, 1);
				// otherwise add it
				else
					newVal.push(val);

				// send new list to owner
				this.$emit('input', newVal);
			}
			// otherwise send the value clicked on
			else
			{
				this.$emit('input', val);
			}
		}
	}
}
</script>