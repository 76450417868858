
export default {
	orderHistoryItems: state => state.orderList.map(id => state.orders[id]),
	productCategoryList: state => state.categoryList.map(id => state.categories[id]),

	getProductsForCategory: state => id => state.categories[id].products.map(id => state.products[id]),
	
	cartQuantity: state => {
		var qty = 0;
		for(var key in state.cart)
			qty += state.cart[key];
		return qty;
	},

	cartItems: state => {
		var items = [];
		
		for(var key in state.cart)
		{
			var product = state.products[key];
			items.push({ ...product, quantity: state.cart[key] });
		}

		return items;
	},

}