<template>
	<div>
		<template v-if="loading">
			Loading appointment schedule...
		</template>

		<template v-else>
			<appointment-block 
				:date-options="listingDates"
				:current.sync="currentDate">
			</appointment-block>
		</template>
	</div>
</template>

<script>
import moment from 'moment';

import AppointmentBlock from './AppointmentBlock.vue';

const now = moment();

export default {
	components: { AppointmentBlock },

	data()
	{
		return {
			loading: true,
			currentDate: 0,
		}
	},

	computed: {
		listingDates()
		{
			var now = moment();
			var month = now.month();
			var year = now.year();
			var dates = [];

			for(var i=0; i<4; i++)
			{
				dates.push({ month, year });

				month++;
				if (month > 11)
				{
					month = 0;
					year++;
				}
			}

			return dates;
		}
	},

	methods: {
		async refreshAppts()
		{
			this.loading = true;

			try
			{
				await this.$store.dispatch('schedule.getAppointmentList');
				//await this.$store.dispatch('schedule.getAvailGrades');
			}
			finally
			{
				this.loading = false;
			}
		},
	},

	async mounted()
	{
		this.refreshAppts();
	}
}
</script>