<template>
	<div>
		<div class="obe-db-subheading obe-db-subheading--black">Upcoming orders</div>

		<div v-if="loading" class="obe-db-subheading">Loading...</div>

		<template v-else>
			<div class="obe-order-row-group">
				<template v-if="orderHistoryItems.length > 0">
					<div v-for="order in orderHistoryItems" :key="order.id" class="obe-order-row">
						<div class="obe-order-row__date">{{ order.delivery_date | dateFormat('MMM Do, YYYY') }}</div>
						<div class="obe-order-row__desc">
							{{ order | productsDesc(1) }}
						</div>
						<div class="obe-order-row__total">{{ order.total | currency }}</div>
						<div class="obe-order-row__edit">
							<router-link class="obe-order-row__edit-link" :to="`/catalog?prevOrder=${order.id}`">
								<span class="obe-sr-only">Edit order</span>
								<span class="obe-i-edit-box"></span>
							</router-link>
							&nbsp;
							<a href="#" class="obe-order-row__delete-link" @click.prevent="cancelOrder(order.id)">
								<span class="obe-sr-only">Cancel order</span>
								<span class="obe-i-delete"></span>
							</a>
						</div>
					</div>
				</template>

				<div v-else>
					<div class="obe-db-subheading obe-db-subheading--green">You have no upcoming orders</div>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

import api from 'obe/dashboard/api';
import filters from 'obe/shared/filters';
import alerts from 'obe/shared/alerts';

export default {
	mixins: [filters],

	filters: {
		productsDesc(order, max)
		{
			var txt = order.products;

			if (order.total_products_ordered > max)
				txt += ` (+${order.total_products_ordered - max} more)`;
			
			return txt;
		}
	},

	data()
	{
		return {
			loading: false,
		}
	},

	computed: {
		...mapGetters(['orderHistoryItems']),
	},

	methods: {
		async refreshOrderHistory()
		{
			this.loading = true;
			try
			{
				await this.$store.dispatch('catalog.getOrderHistory');
			}
			finally
			{
				this.loading = false;
			}
		},

		async cancelOrder(id)
		{
			if (!await alerts.confirm('Are you sure you want to cancel this order?'))
				return;

			this.loading = true;

			try
			{
				var result = await api.post(`/product_orders/cancel_order/${id}`);
				await this.refreshOrderHistory();

				this.$store.commit('SET_DASHBOARD_UPCOMING_ORDERS', result.data.upcoming_orders);
			}
			finally
			{
				this.loading = false;
			}
		}
	},

	mounted()
	{
		this.refreshOrderHistory();
	},

	beforeRouteLeave(to, from, next)
	{
		var catalogRoutes = ['db-catalog', 'db-catalog-checkout', 'db-catalog-order-history'];

		if (catalogRoutes.indexOf(to.name) == -1)
			this.$store.commit('CLEAR_CATALOG_ORDER');

		next();
	}
}
</script>