<template>
	<div>
		<template v-if="apptId != null">
			<div class="obe-header-image">
				<img src="/images/feedback-header.jpg">
			</div>

			<div class="obe-container">
				<layout row wrap>
					<flex md9>
						<obe-form ref="form">
							<form-field type="vert" label="How would you rate the quality of your last natural cleaning service?">
								<slider
									numbered
									v-model="rating"
									:label="ratingLabel"
									:min="1"
									:max="5">
								</slider>
							</form-field>

							<file-selector
								multiple
								type="vert"
								label="Upload any images you may have"
								:files.sync="images"
								:allowed="['image/jpeg', 'image/png', 'image/gif', 'image/wbmp']">
							</file-selector>

							<text-field
								multiline
								type="vert"
								v-model="comments"
								label="Comments? (Feedback only. Any updates/changes, please contact us.)">
							</text-field>
						</obe-form>
					</flex>

					<flex md3>
						<layout column fill-height justify="space-between">
							<flex :grow="1" class="obe-blurb">
								<p>At AspenClean, we’re committed to providing our clients with the highest quality products and services - every time. Your feedback will be used for quality assurance and employee assessment purposes, and also enables us to continue to deliver a service that we can be proud of. </p>
							</flex>
							<flex><push-button block color="orange" @click="submit" :loading="sending">{{ sending ? 'Submitting feedback...' : 'Submit' }}</push-button></flex>
						</layout>
					</flex>
				</layout>
			</div>
		</template>

		<template v-else>
			No service appointment specified
		</template>

		<!--
		<modal :show="sending" :dismiss-outside-click="false" :show-close="false" max-width="400px">
			<card>
				<div class="obe-db-heading">Submitting...</div>
				<div class="obe-blurb">Please wait while your feedback is submitted...</div>
			</card>
		</modal>
		-->
	</div>
</template>

<script>
import api from 'obe/dashboard/api';
import alerts from 'obe/shared/alerts';

const ratingLabels = ['Very Poor', 'Poor', 'Fair', 'Good', 'Very Good'];

export default {
	data()
	{
		return {
			sending: false,
			apptId: null,
			images: [],
			rating: 5, 
			comments: '',
		}
	},

	computed: {
		ratingLabel()
		{
			return ratingLabels[this.rating-1];
		}
	},

	methods: {
		async submit()
		{
			var formData = new FormData();
			
			// add rating and comments to payload
			formData.append('feedback[sf_appt_id]', this.$route.query.sa);
			formData.append('feedback[rating]', this.rating);
			formData.append('feedback[comments]', this.comments);

			// add image uploads
			for(var f of this.images)
				formData.append('feedback[images][]', f);
			this.sending = true;

			try
			{
				await api.post('/feedback/submit', formData);
				await alerts.show('Your feedback has been submitted successfully!', 'Success', 'success');
				//this.$router.push({ path: '/schedule' });
			}
			finally
			{
				this.sending = false;
			}
		}
	},

	mounted()
	{
		if (this.$route.query.sa)
			this.apptId = this.$route.query.sa;
	}
}
</script>
