<template>
	<div class="obe-db-item-box obe-db-item-box--has-status">
		<div class="obe-db-item-box__status"  :style="{ 'background-color': statusColor }"></div>

		<div class="obe-db-item-box__title">{{ title }}</div>
		
		<div class="obe-db-item-box__detail">
			<div class="obe-db-item-box__detail-name">Amount</div>
			<div class="obe-db-item-box__detail-value">{{ invoice.total_amount | currency }}</div>
		</div>
		
		<div class="obe-db-item-box__detail">
			<div class="obe-db-item-box__detail-name">Status</div>
			<div class="obe-db-item-box__detail-value" :style="{ color: statusColor }">{{ this.showPaid }}</div>
		</div>
	</div>
</template>

<script>
import moment from 'moment';

import filters from 'obe/shared/filters';

export default {
	mixins: [filters],

	props: {
		invoice: { type: Object, required: true }
	},

	computed: {
		statusColor()
		{
			let isRedo = (this.invoice.work_order.is_redo || this.invoice.work_order.service_type.toLowerCase() === 'redo')

			if (isRedo || this.invoice.is_paid) {
				return '#00a651'
			}

			return '#f35b7b';
		},

		title()
		{
			var date = moment(this.invoice.service_date, 'YYYY-MM-DD');
			return date.format('MMMM Do, YYYY');
		},

		showPaid() {
			let isRedo = (this.invoice.work_order.is_redo || this.invoice.work_order.service_type.toLowerCase() === 'redo')

			if (isRedo || this.invoice.is_paid) {
				return 'Paid';
			}

			return 'To Pay';
		}
	}
}
</script>