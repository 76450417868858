<template>
	<div class="obe-help-section">
		<div class="obe-help-section__title">{{ category.name }}</div>
		<div class="obe-help-section__items">
			<help-item v-for="item in items" :key="item.id" :item="item"></help-item>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';

import HelpItem from './HelpItem.vue';

export default {
	components: { HelpItem },

	props: { 
		category: { type: Object, required: true },
	},

	computed: {
		...mapState({ 
			categories: state => state.help.categories,
			helpItems: state => state.help.helpItems,
		}),

		items()
		{
			return this.categories[this.category.id].items.map(id => this.helpItems[id]);
		}
	}
}
</script>