
/**
 * Default properties to pass to <form-field> tags when creating high level form fields
 */

export default {
	props: {
		hideLabel: { type: Boolean, default: false },
		hideValidationError: { type: Boolean, default: false },
		label: { type: String, default: '' },
		type: { type: String, default: 'horz' },
		disabled: { type: Boolean, default: false },
		rules: { type: Array, default: () => ([]) },
		validateValue: { default: null },

		labelCols: { type: Number, default: 6 },
		fieldCols: { type: Number, default: 6 },

		errorMsg: { type: String, default: null },
		fieldClass: { type: String, default: '' },
	},

	computed: {
		formFieldAttrs()
		{
			return {
				label: this.label,
				hideValidationError: this.hideValidationError,
				type: this.type,
				disabled: this.disabled,
				rules: this.rules,
				validateValue: this.validateValue,
				hideLabel: this.hideLabel,
				labelCols: this.labelCols,
				fieldCols: this.fieldCols,
				fieldClass: this.fieldClass,
			};
		}
	},

	methods: {
	}
}