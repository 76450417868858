
export default {
	contacts: {},
	contactList: [],

	addresses: {},
	addressList: [],
	
	currentContact: null,
	currentAddress: null,
}