

<script functional>
const sizing = ['xs', 'sm', 'md', 'lg', 'xl']

export default {
	props: {
		order: { type: [String, Number] },
		grow: { type: [String, Number] },
		shrink: { type: [String, Number] },
	},

	methods: {
		getSizingClasses(sel)
		{
			var classes = [];

			for(var i=1; i<=12; i++)
			{
				var val = this.$attrs[`${sel}${i}`];
				if (val != null)
					classes.push(`obe-flex--${sel}-${i}`);
			}
			return classes;
		},

		getOffsetClasses(sel)
		{
			var classes = [];

			for(var i=1; i<=12; i++)
			{
				var val = this.$attrs[`${sel}offset${i}`];
				if (val != null)
					classes.push(`obe-flex--${sel}-offset-${i}`);
			}
		
			return classes;
		},

		getPushClasses(sel)
		{
			var classes = [];

			for(var i=1; i<=12; i++)
			{
				var val = this.$attrs[`${sel}push${i}`];
				if (val != null)
					classes.push(`obe-flex--${sel}-push-${i}`);
			}
		
			return classes;
		},

		getStyles()
		{
			var styles = {};

			if (this.order)
				styles.order = this.order;

			if (this.grow)
				styles['flex-grow'] = this.grow;

			if (this.shrink)
				styles['flex-shrink'] = this.shrink;

			return styles;
		},

		getClasses()
		{
			var classes = ['obe-flex'];

			// apply responsive sizing classes
			for(var size of sizing)
			{
				classes = classes.concat(this.getSizingClasses(size));
				classes = classes.concat(this.getOffsetClasses(size));
				classes = classes.concat(this.getPushClasses(size));
			}

			return classes;
		}
	},

	render(h)
	{
		var args = { 
			class: this.getClasses(),
			style: this.getStyles(),
			//attr: { style: this.getStyles() }
		};

		return h('div', args, [this.$slots.default]);
	}
}
</script>