<template>
	<div>
		<template v-if="loading">
			<div class="obe-db-subheading">Loading credit cards...</div>
		</template>
		<div class="obe-credit-card-list" v-else>
			<div class="obe-credit-card" v-for="card in paymentMethodList" :key="card.id">
				<div class="obe-credit-card__display">
					<div class="obe-credit-card__brand">{{ card.card_type }}</div>
					<div class="obe-credit-card__last4">{{ card.last4 }}</div>
					<div class="obe-credit-card__expiry">{{ card | expiryDate }}</div>
				</div>

				<div class="obe-credit-card__options">
					<radio label="Default card" :value="card.id" v-model="defaultCard" />

					<div class="obe-credit-card__actions">
						<!--
						<a href="#" class="obe-credit-card__action" :title="`Edit credit card ending in ${card.last4}`" @click.prevent="editCard(card.id)">
							<i class="obe-i-edit-pencil"></i>
							<span class="obe-sr-only">Edit card</span>
						</a>
						-->
						<a v-if="currentUser.is_staff_session || !card.is_default" href="#" class="obe-credit-card__action" :title="`Edit credit card ending in ${card.last4}`" @click.prevent="deleteCard(card.id)">
							<i class="obe-i-delete"></i>
							<span class="obe-sr-only">Delete card</span>
						</a>
					</div>
				</div>
			</div>

			<a href="#" class="obe-new-card-btn" @click.prevent="editCard(0)">
				<span class="obe-new-card-btn__plus">+</span>
				<span class="obe-new-card-btn__label">Add new card</span>
			</a>
		</div>
	</div>
</template>

<script>
import { sprintf } from 'sprintf-js';
import { mapState, mapGetters } from 'vuex';

import alerts from 'obe/shared/alerts';
import api from 'obe/dashboard/api';

import types from '../store/types';

export default {

	filters: { 
		expiryDate(val)
		{
			return sprintf('%02d/%02d', val.expiry_month, val.expiry_year.toString().slice(2));
		}
	},

	data()
	{
		return {
			loading: false,
		}
	},

	computed: {
		...mapGetters(['paymentMethodList']),

		...mapState({
			methods: state => state.payments.methods,
			currentUser: state => state.dashboard.currentUser
		}),

		defaultCard: 
		{
			get()
			{
				for(var pm of this.paymentMethodList)
					if (pm.is_default)
						return pm.id;

				return null;
			},

			set(val)
			{
				this.setDefault(val);
			}
		}
	},

	methods: {
		async loadPaymentMethods()
		{
			this.loading = true;

			try
			{
				await this.$store.dispatch('paymentMethods.getPaymentMethods');
			}
			finally
			{
				this.loading = false;
			}
		},

		async setDefault(id)
		{
			if (this.methods[id].is_default)
				return;

			this.loading = true;

			try
			{
				await api.post(`/payment_methods/${id}/set_default`);
				await this.loadPaymentMethods();
			}
			finally
			{
				this.loading = false;
			}
		},

		editCard(id)
		{
			this.$store.commit(types.SET_CURRENT_METHOD, id);
		},

		async deleteCard(id)
		{
			if (!await alerts.confirm('Are you sure you want to delete this credit card?', 'Confirm Deletion'))
				return;


			this.loading = true;

			try
			{
				await api.delete(`/payment_methods/${id}`);
				this.loadPaymentMethods();
			}
			finally
			{
				this.loading = false;
			}
		}
	},

	mounted()
	{
		this.loadPaymentMethods();
	}
}
</script>
