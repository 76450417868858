<template>
	<div class="obe-appt-estimate">
		<template>
			<div class="obe-db-heading-block">Estimate</div>

			<template v-if="estimate != null">
				<div class="obe-appt-estimate__quote">
					<div v-if="this.apptLoyaltyCode && showPersonHoursOrDurationDiscounted" class="obe-appt-estimate__quote-item-initial-price">
						<div class="obe-appt-estimate__quote-item-name">Person-Hours</div><span class="obe-appt-estimate__quote-item-initial-price">{{ this.getPersonHours() }}</span>
					</div>
					<div v-if="this.apptLoyaltyCode && showPersonHoursOrDurationDiscounted" class="obe-appt-estimate__quote-item-discounted-price">
						<div class="obe-appt-estimate__quote-item-name"></div><span class="obe-appt-estimate__quote-item-amount">{{ ((estimateFields.minutes) / 60.0).toFixed(2)  }}</span>
					</div>
					<div v-else class="obe-appt-estimate__quote-item">
						<div class="obe-appt-estimate__quote-item-name">Person-Hours</div><span class="obe-appt-estimate__quote-item-amount">{{ this.getPersonHours(false) }}</span>
					</div>
					<div v-if="this.apptLoyaltyCode && showPersonHoursOrDurationDiscounted" class="obe-appt-estimate__quote-item-initial-price">
						<div class="obe-appt-estimate__quote-item-name">Duration</div><span class="obe-appt-estimate__quote-item-initial-price" style="margin: auto 0">{{ this.getDurationHours() }}</span>
					</div>
					<div v-if="this.apptLoyaltyCode && showPersonHoursOrDurationDiscounted" class="obe-appt-estimate__quote-item-discounted-price">
						<div class="obe-appt-estimate__quote-item-name">(Team of 3)</div><span class="obe-appt-estimate__quote-item-amount">{{ (estimateFields.minutes / 3) | duration }}</span>
					</div>
					<div v-else class="obe-appt-estimate__quote-item">
						<div class="obe-appt-estimate__quote-item-name">Duration</div>
						<div class="obe-appt-estimate__quote-item-name">(Team of 3)</div><span class="obe-appt-estimate__quote-item-amount">{{ this.getDurationHours(false) }}</span>
					</div>
					<div v-if="this.apptDiscounts.length === 0">
						<div v-if="this.apptLoyaltyCode && this.estimate.initial_amount !== this.estimate.regular_amount" class="obe-appt-estimate__quote-item-initial-price">
							<div class="obe-appt-estimate__quote-item-name">Cleaning Service</div><span class="obe-appt-estimate__quote-item-initial-price">{{ this.getCleaningServiceAmount() | currency }}</span>
						</div>
						<div v-if="this.apptLoyaltyCode && this.estimate.initial_amount !== this.estimate.regular_amount" class="obe-appt-estimate__quote-item-discounted-price">
							<div class="obe-appt-estimate__quote-item-name"></div><span class="obe-appt-estimate__quote-item-amount">{{ estimateFields.amount | currency }}</span>
						</div>
						<div v-else class="obe-appt-estimate__quote-item">
							<div class="obe-appt-estimate__quote-item-name">Cleaning Service</div><span class="obe-appt-estimate__quote-item-initial-price">{{ this.getCleaningServiceAmount(false) | currency }}</span>
						</div>
					</div>
					<div v-else>
						<div v-if="this.apptDiscounts[0].discount_type === 'booking'">
							<div class="obe-appt-estimate__quote-item-initial-price">
								<div class="obe-appt-estimate__quote-item-name">Cleaning Service</div><span class="obe-appt-estimate__quote-item-initial-price">{{ this.estimate.initial_amount | currency }}</span>
							</div>
							<div class="obe-appt-estimate__quote-item-discounted-price">
								<!-- We only care about the first discount throughout this site -->
								<div class="obe-appt-estimate__quote-item-name">{{ this.apptDiscounts[0].title }}</div><span class="obe-appt-estimate__quote-item-amount">{{ this.discountedCleaningService() | currency }}</span>
							</div>
						</div>
					</div>
					<template v-if="productOrder">
						<div class="obe-appt-estimate__divider"></div>
						<div v-if="productLoyaltyCode" class="obe-appt-estimate__quote-item-initial-price">
							<div class="obe-appt-estimate__quote-item-name">Products</div><span class="obe-appt-estimate__quote-item-initial-price">{{ actualInitialProductTotal | currency }}</span>
						</div>
						<div v-if="productLoyaltyCode" class="obe-appt-estimate__quote-item-discounted-price">
							<div class="obe-appt-estimate__quote-item-name"></div><span class="obe-appt-estimate__quote-item-amount">{{ productTotal | currency }}</span>
						</div>
						<div v-else class="obe-appt-estimate__quote-item">
							<div class="obe-appt-estimate__quote-item-name">Products</div><span class="obe-appt-estimate__quote-item-initial-price">{{ actualInitialProductTotal | currency }}</span>
						</div>
					</template>
					<div v-if="this.apptDiscounts.length === 0">
						<div v-if="this.apptLoyaltyCode && this.estimate.initial_amount !== this.estimate.regular_amount" class="obe-appt-estimate__quote-item-total">
							<div class="obe-appt-estimate__quote-item-name">Total</div><span class="obe-appt-estimate__quote-item-amount-initial">{{ actualInitialGrandTotal | currency }}</span>
						</div>
						<div v-if="this.apptLoyaltyCode && this.estimate.initial_amount !== this.estimate.regular_amount" class="obe-appt-estimate__quote-item-discounted-price">
							<div class="obe-appt-estimate__quote-item-name"></div><span class="obe-appt-estimate__quote-item-amount-discounted">{{ grandTotal | currency }}</span>
						</div>
						<div v-else class="obe-appt-estimate__quote-item-total">
							<div class="obe-appt-estimate__quote-item-name">Total</div><span class="obe-appt-estimate__quote-item-amount">{{ grandTotal | currency }}</span>
						</div>
					</div>
					<div v-else>
						<div v-if="this.apptDiscounts[0].discount_type === 'booking'">
							<div class="obe-appt-estimate__quote-item-total">
								<div class="obe-appt-estimate__quote-item-name">Total</div><span class="obe-appt-estimate__quote-item-amount-initial">{{ actualInitialGrandTotal | currency }}</span>
							</div>
							<div class="obe-appt-estimate__quote-item-discounted-price">
								<div class="obe-appt-estimate__quote-item-name"></div><span class="obe-appt-estimate__quote-item-amount-discounted">{{ this.discountedGrandTotal() | currency }}</span>
							</div>
						</div>
					</div>
				</div>
			</template>
		</template>
	</div>
</template>

<script>

import { mapState, mapGetters } from 'vuex';
import filters from 'obe/shared/filters';

export default {
	mixins: [filters],

	computed: {
		...mapGetters(['apptProductList']),
		...mapState({
			booking: state => state.schedule.apptBooking,
			estimate: state => state.schedule.estimate,
			apptFranchise: state => state.schedule.apptFranchise,
			isRecurring: state => state.schedule.isRecurring,
			productOrder: state => state.schedule.apptProductOrder,
			currAppt: state => state.schedule.currentAppt,
			apptDiscounts: state => state.schedule.currentApptDiscounts,
			apptLoyaltyCode: state => state.schedule.currentApptLoyaltyCode,
			productLoyaltyCode: state => state.schedule.productLoyaltyCode,
			overrides: state => state.schedule.overrides,
		}),

		estimateFields()
		{
			var amount, minutes;

			if (this.isRecurring)
			{
				amount = this.estimate.regular_amount;
				minutes = this.estimate.regular_minutes;
			}
			else
			{
				amount = this.estimate.initial_amount;
				minutes = this.estimate.initial_minutes;
			}

			return { amount, minutes };
		},

		productTotal()
		{
			var subTotal = 0;
			var total = 0;

			for(var p of this.apptProductList)
				subTotal += parseFloat(p.price) * p.quantity;

			total = this.amountAfterProductTaxes(subTotal);

			if (this.productLoyaltyCode === 'D') {
				var total_with_discount = total - (total * 0.25);

				return total_with_discount;
			}

			return total;
		},	

		actualInitialProductTotal()
		{
			var total = 0;

			for(var p of this.apptProductList)
				total += parseFloat(p.price) * p.quantity;

			total = this.amountAfterProductTaxes(total);

			return total;
		},

		grandTotal()
		{
			if (this.overrides.apply_overrides) {
				var type = this.booking.cleaning_freq == 'one-time' ? 'initial' : 'regular';
				var minutes = this.overrides[`${type}_duration`];
				var cost = this.overrides[`${type}_cost`];

				const minute_presence = (minutes && minutes !== '');
				const cost_presence = (cost && cost !== '');

				if (minute_presence && cost_presence) {
					return parseFloat(this.overrides[`${type}_cost`]) + this.productTotal;
				}
			}

			return parseFloat(this.estimateFields.amount) + this.productTotal;
		},

		// Only for showing initial amount without discount
		actualInitialGrandTotal()
		{
			return parseFloat(this.estimate.initial_amount) + this.actualInitialProductTotal;
		},

		showPersonHoursOrDurationDiscounted() {
			var showDiscounted = true;

			if (this.estimate.initial_minutes === this.estimateFields.minutes || this.currAppt.is_regular_appointment) {
				showDiscounted = false;
			}

			return showDiscounted;
		},
	},
	methods: {
		amountAfterProductTaxes(amount)
		{
			var price = amount;
			var originalPrice = amount;

			if (this.apptFranchise.taxes.length > 0) {
				this.apptFranchise.taxes.forEach((tax) => {
					var percent = parseFloat(tax.percent);
					percent = percent > 0 ? percent : 0;

					price += ((percent / 100.0) * originalPrice)
				});
			}

			return _.round(price, 2)
		},

		getPersonHours(show_discounted=true) {
			if (show_discounted && this.apptLoyaltyCode !== '') {
				var minutes = parseFloat(this.estimateFields.minutes);
				var personHours = ((minutes) / 60.0).toFixed(2)

				return personHours;
			}

			if (this.overrides.apply_overrides) {
				var type = this.booking.cleaning_freq == 'one-time' ? 'initial' : 'regular';
				var minutes = this.overrides[`${type}_duration`];
				var cost = this.overrides[`${type}_cost`];

				const minute_presence = (minutes && minutes !== '');
				const cost_presence = (cost && cost !== '');

				if (minute_presence && cost_presence) {
					var personHours = (parseFloat(minutes) / 60.0).toFixed(2);

					return personHours;
				}
			}

			return (parseFloat(this.estimateFields.minutes) / 60).toFixed(2);
		},

		getDurationHours(show_discounted=true) {
			// Format time from 1hr 50min to 1h 50m
			if (show_discounted && this.apptLoyaltyCode !== '') {
				var minutes = parseFloat(this.estimateFields.minutes);

				return this.duration(minutes);
			}

			if (this.overrides.apply_overrides) {
				var type = this.booking.cleaning_freq == 'one-time' ? 'initial' : 'regular';
				var minutes = this.overrides[`${type}_duration`];
				var cost = this.overrides[`${type}_cost`];

				const minute_presence = (minutes && minutes !== '');
				const cost_presence = (cost && cost !== '');

				if (minute_presence && cost_presence) {
					var calcDuration = (parseFloat(minutes) / 3).toFixed(2)
					var duration = this.duration(calcDuration);

					return duration;
				}
			}

			const calculatedDuration = (parseFloat(this.estimateFields.minutes) / 3).toFixed(2)
			var duration = this.duration(calculatedDuration);

			return duration;
		},

		// Minutes should be passed
		duration(val, mult=1)
		{
			var num = val * mult;
			var hours = Math.floor(num / 60);
			var minutes = _.padStart(Math.round(num % 60), 2, '0');

			return `${hours}h ${minutes}m`;
		},

		getCleaningServiceAmount(show_discounted=true) {
			if (show_discounted) {
				if (this.currAppt.is_regular_appointment && this.apptLoyaltyCode != '') {
					return parseFloat(this.estimate.initial_amount);
				}
			}

			if (this.overrides.apply_overrides) {
				var type = this.booking.cleaning_freq == 'one-time' ? 'initial' : 'regular';
				var amount = this.overrides[`${type}_cost`];
				var minutes = this.overrides[`${type}_duration`];
				
				const minute_presence = (minutes && minutes !== '');
				const amount_presence = (amount && amount !== '');

				if (minute_presence && amount_presence) {
					return parseFloat(amount);
				}
			}

			return this.estimateFields.amount;
		},

		discountedCleaningService() {
			var discount = this.apptDiscounts[0];
			var total = this.estimate.initial_amount;

			if (discount.deduct_type === 'percent') {
				total = total - (total * (discount.deduct_amount / 100));
				total = _.round(total, 2);

				return total;
			} else {
				total = total - discount.deduct_amount;

				return total;
			}
		},

		discountedGrandTotal() {
			var discount = this.apptDiscounts[0];
			var total = this.estimate.initial_amount;

			if (discount.deduct_type === 'percent') {
				total = total - (total * (discount.deduct_amount / 100));
				total = _.round(total, 2);
			} else {
				total = total - discount.deduct_amount;
			}

			var amount = total + this.productTotal;
			return amount;
		}
	},
}
</script>
