
export const visitOptions = [
	{ text: 'Just this visit', value: 'this' },
	{ text: 'This and all future visits', value: 'all' },
];

export const statusColors = {
	// upcoming
	'none': '#5887da',
	'new': '#5887da',
	'accepted': '#5887da',
	'booked': '#5887da',

	// scheduled
	'scheduled': '#5bc173',
	'dispatched': '#5bc173',
	'in progress': '#5bc173',

	// completed
	'completed': '#dbdbdb',

	// cancelled
	'cannot complete': '#ff0505',
	'canceled': '#ff0505',
	'rejected': '#ff0505',
}
