<template>
	<div>
		<div v-if="status != 'forgot-phone'" class="obe-login">
			<div class="obe-login__wrapper">
				<div class="obe-login__header-icon"><span class="obe-i-lock"></span></div>

				<div class="obe-login__title">Forgot Email Address</div>

				<fade-transition>
					<!-- step 1: user enters their phone # -->
					<div v-if="status == 'start'">
						<div class="obe-login__body">Please enter your phone number</div>

						<obe-form ref="form">
							<layout row wrap>
								<flex md6>
									<text-field
										hide-label
										placeholder="Mobile Phone #"
										v-model="phone"
										:rules="rules.phone">
									</text-field>
								</flex>
								
								<flex md6>
									<push-button block label="Send Code" @click="sendCode" :loading="loading"></push-button>
								</flex>

								<flex xs12>
									<div class="obe-login__links">
										<a href="#" class="obe-link" @click.prevent="status = 'forgot-phone'">Forgot which phone number you registered with?</a><br>
										<router-link to="/login" class="obe-link">Back to login</router-link>
									</div>
								</flex>
							</layout>
						</obe-form>
					</div>

					<!-- step 2: code sent to phone/code was rejected -->
					<div v-else-if="status == 'code-sent' || status == 'code-rejected'">
						<div v-if="status == 'code-sent'" class="obe-login__body">Please enter the code we sent you</div>
						<div v-if="status == 'code-rejected'" class="obe-login__body">The code you entered was invalid or has expired</div>

						<obe-form ref="verifyForm">
							<layout row wrap>
								<flex md6>
									<text-field
										hide-label
										placeholder="6 digit code"
										input-type="number"
										v-model="code"
										:rules="rules.code">
									</text-field>
								</flex>

								<flex md6>
									<push-button block color="orange" @click="verifyCode" :loading="loading">Verify</push-button>
								</flex>
							</layout>
						</obe-form>
					</div>

					<!-- step 3: code verified successfully -->
					<div v-else-if="status == 'code-accepted'">
						<div class="obe-login__body">Thank you! We have sent login information to the email address listed below</div>

						<text-field
							hide-label readonly
							:value="recoveredEmail">
						</text-field>

						<push-button block to="/home">Close</push-button>
					</div>

				</fade-transition>
			</div>
		</div>

		<div v-else class="obe-login">
			<div class="obe-login__wrapper">
				<div class="obe-login__header-icon"><span class="obe-i-lock"></span></div>

				<div class="obe-login__title">Forgot your phone number?</div>

				<div class="obe-login__body">Please contact us to recover your account</div>

				<div class="obe-login__phone">1-855-925-9900</div>

				<push-button wide @click="status = 'start'">Back</push-button>
			</div>
		</div>

	</div>
</template>

<script>
import api from 'obe/dashboard/api';

/*
statuses:
	start
	code-sent
	code-accepted
	code-rejected
*/

export default {
	data()
	{
		return {
			phone: '',
			code: '',
			status: 'start',
			loading: false,
			recoveredEmail: null,

			rules: {
				phone: [ v => !!v || 'Please enter your phone number' ],
				code: [ 
					v => !!v || 'Please enter the code we sent you',
					v => v.length == 6 || 'Code must be six digits long'
				],
			}
		}
	},

	methods: {
		async sendCode()
		{
			if (!this.$refs.form.validate())
				return;

			this.loading = true;

			try
			{
				var params = { phone: this.phone };

				await api.post('/account_recovery/send_recovery_code', params);

				this.status = 'code-sent';
			}
			finally
			{
				this.loading = false;
			}
		},

		async verifyCode()
		{
			if (!this.$refs.verifyForm.validate())
				return;

			this.loading = true;

			try
			{
				var params = { code: this.code };
				var result = await api.post('/account_recovery/verify_recovery_code', params);

				if (result.data.success)
				{
					this.status = 'code-accepted';
					this.recoveredEmail = result.data.email;
				}
				else
				{
					this.status = 'code-rejected';
				}
			}
			finally
			{
				this.loading = false;
			}
			
		}
	}
}
</script>