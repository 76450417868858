<template>
	<div>
		<div class="obe-db-section-title">
			<div class="title-item">
				<div class="obe-db-heading">
					<span class="obe-db-heading__icon"><i class="obe-i-credit-card"></i></span>
					<span class="obe-db-heading__text">Credit Cards</span>
				</div>
			</div>

			<div class="title-item">
				<div class="obe-heading obe-heading--grey obe-heading--px16">
					If you need to update your credit card information, please choose the new one as the default card and delete the old credit card
				</div>
			</div>
		</div>

		<credit-card-list ref="cards"></credit-card-list>

		<layout row wrap>
			<flex xl6>
				<invoices></invoices>
			</flex>
		</layout>

		<modal max-width="600px" :show.sync="showCardForm" v-if="showCardForm">
			<credit-card-form @close="onCardFormClose"></credit-card-form>
		</modal>
	</div>
</template>

<script>

import { mapState } from 'vuex';

import types from '../store/types';

import CreditCardList from './CreditCardList.vue';
import CreditCardForm from './CreditCardForm.vue';
import Invoices from './Invoices.vue';

export default {
	components: { CreditCardList, CreditCardForm, Invoices },

	data()
	{
		return {
			loadingMethods: false,
		}
	},

	computed: {
		...mapState({
			currentMethod: state => state.payments.currentMethod,
		}),

		showCardForm: {
			get()
			{
				return this.currentMethod != null;
			},

			set(val)
			{
				if (!val)
					this.$store.commit(types.SET_CURRENT_METHOD, null);
			}
		}
	},

	methods: {

		onCardFormClose(refresh)
		{
			this.showCardForm = false;

			if (refresh)
				this.$refs.cards.loadPaymentMethods();
		}
	},
}
</script>
