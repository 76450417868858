import _ from 'lodash/string';

export function nthNumber(num)
{
	if (num == 0)
		return '';

	var str = parseInt(num, 10).toString();
	var suffixes = ['st', 'nd', 'rd', 'th'];

	// single digit numbers
	if (str.length == 1)
	{
		if (num <= 3)
			return `${num}${suffixes[num-1]}`;
		else
			return `${num}th`;
	}
	// multi digit numbers
	else if (str.length > 1)
	{
		// x10-x19
		if (str.match(/(1[0-9])$/))
			return `${num}th`;
		// the rest
		else if (str[str.length-1] == '1')
			return `${num}st`;
		else if (str[str.length-1] == '2')
			return `${num}nd`;
		else if (str[str.length-1] == '3')
			return `${num}rd`;
		else
			return `${num}th`;
	}
	else
	{
		return `!ERR: ${num}`;
	}
}


// see: https://gist.github.com/andjosh/6764939
// code changed for formatting
export function scrollTo(element, to, duration) 
{
	var start = element.scrollTop,
		change = to - start,
		currentTime = 0,
		increment = 20;

	var animateScroll = function()
	{ 
		var val;
		currentTime += increment;
		val = easeInOutQuad(currentTime, start, change, duration);
		element.scrollTop = val;
		if(currentTime < duration)
			setTimeout(animateScroll, increment);
	};

	animateScroll();
}
 
 //t = current time
 //b = start value
 //c = change in value
 //d = duration
function easeInOutQuad(t, b, c, d) 
{
	t /= d/2;
	if (t < 1) 
		return c/2*t*t + b;
	
	t--;
	return -c/2 * (t*(t-2) - 1) + b;
};

export function getElementPosition(el)
{
    var rect = el.getBoundingClientRect(),
    	scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
		scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
}
 
export function camelCaseFields(obj)
{
	var res = {};
	for(var src in obj)
	{
		var dst = _.camelCase(src);
		res[dst] = obj[src];
	}

	return res;
}

export default {
	nthNumber,
	scrollTo,
}