<template>
	<div class="obe-checkout-item">
		<div class="obe-checkout-item__name">{{ item.name }}</div>

		<div class="obe-checkout-item__details">

			<!-- ITEM QUANTITY -->
			<div class="obe-checkout-item__qty">
				<a href="#" class="obe-checkout-item__qty-arrow" @click.prevent="updateQty(-1)">
					<span class="obe-sr-only">Decrease quantity</span>
					<span class="obe-i-minus"></span>
				</a>

				<span class="obe-checkout-item__qty-value">{{ item.quantity }}</span>

				<a href="#" class="obe-checkout-item__qty-arrow" @click.prevent="updateQty(1)">
					<span class="obe-sr-only">Increase quantity</span>
					<span class="obe-i-plus"></span>
				</a>
			</div>

			<!-- ITEM SINGLE PRICE -->
			<div class="obe-checkout-item__single-price" :class="{ 'obe-checkout-item__single-discounted-price': productLoyaltyCode == 'D' }">{{ item.price | currency }}</div>
			<div class="obe-checkout-item__single-price-text-left" v-if="productLoyaltyCode == 'D'">{{ this.findDiscountedPrice(item.price)  | currency }}</div>

			<!-- ITEM TOTAL PRICE -->
			<div class="obe-checkout-item__line-price">{{ totalPrice | currency }}</div>

			<!-- ITEM ACTIONS -->
			<a href="#" class="obe-checkout-item__delete" @click.prevent="remove">
				<span class="obe-sr-only">Remove from cart</span>
				<span class="obe-i-delete"></span>
			</a>

		</div>
	</div>
</template>

<script>

import { mapState, mapGetters } from 'vuex';
import alerts from 'obe/shared/alerts';
import filters from 'obe/shared/filters';
import types from '../store/types';
import catalogScreen from '../catalog-screen';

export default {
	mixins: [filters, catalogScreen],

	props: {
		item: { type: Object, required: true }
	},

	computed: {
		...mapGetters(['cartQuantity']),
		...mapState({
			cart: state => state.catalog.cart,
			productLoyaltyCode: state => state.dashboard.productLoyaltyCode,
		}),

		totalPrice()
		{
			var itemPrice = parseFloat(this.item.price) || 0;

			if (this.productLoyaltyCode == 'D') {
				itemPrice = this.findDiscountedPrice(itemPrice);
			}

			return (itemPrice * this.item.quantity);
		},

		quantity: {
			get()
			{
				return this.cart[this.item.id] || 0;
			},

			set(val)
			{
				this.$store.commit(types.CART_UPDATE_PRODUCT_QTY, { id: this.item.id, quantity: val });
			}
		}
	},

	methods: {
		updateQty(inc)
		{
			var qty = this.quantity + inc;

			if (qty <= 0)
				this.remove();
			else
				this.quantity = qty;
		},

		findDiscountedPrice(price) {
			let discount = (price * 0.25).toFixed(2);
			price = price - discount;

			return price;
		},

		async remove()
		{
			if (await alerts.confirm('Are you sure you want to remove this product?', 'Confirm', 'question'))
			{
				this.$store.commit(types.CART_REMOVE_PRODUCT, { id: this.item.id });
				if (this.cartQuantity == 0)
					this.$router.push({ path: this.prevUrl });
			}
		}
	}
}
</script>
