
export default {
	getBookingOptionsByRoom: state => (field, rooms) => {
		if (state.roomOptions[field] && state.roomOptions[field][rooms-1])
		{
			var list = state.roomOptions[field][rooms-1];
			return list.map(id => state.optionMap[id]);
		}
		else
		{
			//console.log(`no options found for ${field} with ${rooms}`)
			return [];
		}
	},

	mainFranchiseList: state => state.franchiseIds.map(id => state.franchises[id]),

	getFranchiseByTerritoryId: state => id => {
		var franchiseId = state.franchisesByTerritory[id];
		return franchiseId ? state.franchises[franchiseId] : null;
	}
}