<template>
	<div class="obe-checkbox" :class="{ 'obe-checkbox--checked': checked, 'obe-checkbox--disabled': disabled }" @click="toggle">
		<input type="checkbox" :checked="checked" :disabled="disabled" @input="setValue($event.target.checked)">
		<div class="obe-checkbox__box"></div>
		<div class="obe-checkbox__label" :class="{ 'obe-checkbox__label--blue': blueText }">
			<slot>{{ label }}</slot>
		</div>
	</div>
</template>

<script>
export default {
    model: {
        prop: 'data',
        event: 'input',
    },
    
    props: {
        value: { type: [Boolean, String, Number], default: false },
        data: { type: [Boolean, Array], default: false },
        label: { type: String, default: '' },
        disabled: { type: Boolean, default: false },
        blueText: { type: Boolean, default: false },
    },

    computed: {
        checked()
        {
            if (this.data instanceof Array)
                return this.data.indexOf(this.value) >= 0;
            else if (typeof(this.data) == 'boolean')
                return this.data;
        },
    },
    
    methods: {
		setValue(flag)
		{
            // do nothing if disabled
            if (this.disabled) return;

            // toggle value
            if (this.data instanceof Array)
            {
                var newData = this.data.slice();
                var pos = newData.indexOf(this.value);
                
				if (!flag && pos >= 0)
                    newData.splice(pos, 1);
                else if (flag && pos == -1)
                    newData.push(this.value);
                
                this.$emit('input', newData);
            }
            else if (typeof(this.data) == 'boolean')
            {
                this.$emit('input', flag);
            }
		},

		toggle(event)
		{
            if (event.target.tagName == 'A')
                return true;

            // do nothing if disabled
            if (this.disabled) return false;

            this.setValue(!this.checked);
            
            return false;
		},

        /*toggle()
        {
            // do nothing if disabled
            if (this.disabled) return;

            // toggle value
            if (this.data instanceof Array)
            {
                var newData = this.data.slice();
                var pos = newData.indexOf(this.value);
                if (pos >= 0)
                    newData.splice(pos, 1);
                else
                    newData.push(this.value);
                
                this.$emit('input', newData);
            }
            else if (typeof(this.data) == 'boolean')
            {
                this.$emit('input', !this.data);
            }
        }*/
    }
}
</script>