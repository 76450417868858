
import types from './types';

export default {
	[types.SET_PAYMENT_METHODS](state, list)
	{
		var methods = {};

		if (list && list !== null) {
			state.methodList = list.map(m => {
				methods[m.id] = { ...m };
				return m.id;
			});
		} else {
			state.methodList = [];
		}

		state.methods = methods;
	},

	[types.SET_CURRENT_METHOD](state, id)
	{
		if (id == null)
			state.currentMethod = null;
		else if (id == 0)
			state.currentMethod = { id: 0, is_default: false, use_primary_addr: false };
		else if (state.methods[id])
			state.currentMethod = { ...state.methods[id] };
	},

	[types.UPDATE_CURRENT_METHOD](state, data)
	{
		if (state.currentMethod != null)
			state.currentMethod = { ...state.currentMethod, ...data };
	},

	[types.SET_INVOICE_LIST](state, list)
	{
		var invoices = {};

		if (list && list !== null) {
			state.invoiceList = list.map(i => {
				invoices[i.id] = { ...i };
				return i.id;
			});
		} else {
			state.invoiceList = [];
		}

		state.invoices = invoices;
	},

	[types.SET_STATEMENT_INFO](state, info)
	{
		state.statement = { paid: info.paid || 0, due: info.due || 0 };
	},

	[types.SET_PUBLIC_PAYMENT_GATEWAY](state, key)
	{
		state.publicPaymentGatewayKey = key;
	},
}