
import moment from 'moment';

import api from 'obe/dashboard/api';

import types from './types';
import { SET_CURRENT_USER } from '../../../../dashboard/store/types';

export default {
	async ['contacts.fetchContactList']({ commit })
	{
		var result;

		result = await api.get('/contacts');

		commit(types.SET_CUSTOMER_CONTACTS, result.data.contacts);
	},

	async ['contacts.fetchContact']({ commit }, params)
	{
		var result = await api.get(`/contacts/${params.id}`);
		commit(types.SET_CURRENT_CONTACT, result.data.contact);
	},

	async ['contacts.saveCurrentContact']({ state, commit })
	{
		var result, req = { contact: { ...state.currentContact } };

		if (state.currentContact == null)
			return;

		if (state.currentContact.id == 0)
			result = await api.post('/contacts', req);
		else
			result = await api.put(`/contacts/${state.currentContact.id}`, req);

		// Update contact in dashboard state if primary contact has changed
		if (result.data && result.data.contact && result.data.contact.is_primary) {
			commit(SET_CURRENT_USER, result.data.contact);
		}

		return result.data;
	},

	async ['contacts.fetchContactAddresses']({ state, commit })
	{
		// don't need to do anything if there is no current contact or the contact hasn't been saved yet
		if (!state.currentContact || state.currentContact.id == 0)
			return;

		// pull addresses from SF
		var result = await api.get(`/contacts/${state.currentContact.id}/addresses`);

		// add to state
		commit(types.SET_CONTACT_ADDRESSES, result.data.addresses);
	},

	async ['contacts.fetchAddress']({ commit }, params)
	{
		var result = await api.get(`/contacts/${params.contactId}/addresses/${params.id}`);
		commit(types.SET_CURRENT_ADDRESS, result.data.address);
	},

	async ['contacts.saveCurrentAddress']({ state }, params)
	{
		// don't need to do anything if there is no current contact/address in state
		if (!state.currentAddress)// || !state.currentContact)
			return;

		// send address data to server
		var result, now = moment().toISOString(), url = `/contacts/${params.contactId}/addresses`;

		// create new address
		if (state.currentAddress.id == 0)
			result = await api.post(url, { address: state.currentAddress, cancel_date: now });
		// otherwise update existing
		else
			result = await api.put(`${url}/${state.currentAddress.id}`, { address: state.currentAddress, cancel_date: now });

		return result.data;
	}

}