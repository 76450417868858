<template>
	<div class="obe-cart-summary">
		<div class="obe-cart-summary__title">Total Product Cost</div>

		<table class="obe-cart-summary__table" cellspacing="0" cellpadding="0">
			<tr class="obe-cart-summary__amount-row">
				<td class="obe-cart-summary__amount-label">Subtotal</td>
				<td class="obe-cart-summary__amount-value">{{ subtotal | currency }}</td>
			</tr>
			
			<!-- regular discounts -->
			<tr v-if="checkingDiscounts" class="obe-cart-summary__amount-row">
				<td class="obe-cart-summary__amount-label">Checking discounts...</td>
			</tr>
			<template v-else>
				<tr v-for="d in discounts" :key="d.id" class="obe-cart-summary__amount-row">
					<td class="obe-cart-summary__amount-label">{{ d.title }}</td>
					<td class="obe-cart-summary__amount-value">{{ d | deduction(subtotal) }}</td>
				</tr>
			</template>

			<!-- promo code -->
			<tr v-if="promoCode" class="obe-cart-summary__amount-row">
				<td class="obe-cart-summary__amount-label">{{ promoCode.discount.title }}</td>
				<td class="obe-cart-summary__amount-value">{{ promoCode.discount | deduction(subtotal) }}</td>
			</tr>

			<!-- subtotal and taxes -->
			<tr class="obe-cart-summary__amount-row" v-for="tax in summary.taxes" :key="tax.name">
				<td class="obe-cart-summary__amount-label">{{ tax.name }}</td>
				<td class="obe-cart-summary__amount-value">{{ tax.amount | currency }}</td>
			</tr>

			<tr class="obe-cart-summary__total-row">
				<td class="obe-cart-summary__total-label">Total</td>
				<td class="obe-cart-summary__total-value">{{ summary.total | currency }}</td>
			</tr>

			<tr v-if="loyaltyCode === 'D'" class="obe-cart-summary__saving-row">
				<td class="obe-cart-summary__saving-label">You Saved</td>
				<td class="obe-cart-summary__saving-value">{{ summary.savings | currency }}</td>
			</tr>
		</table>

		<div class="obe-cart-summary__buttons">
			<push-button block color="orange" @click="submitOrder" :loading="loading">Submit Order</push-button>
		</div>

		<div class="obe-cart-summary__info">
			This amount will be added to your regular cleaning invoice
		</div>
	</div>
</template>

<script>

import _ from 'lodash/math';
import { mapState, mapGetters } from 'vuex';
import alerts from 'obe/shared/alerts';
import filters from 'obe/shared/filters';
import types from '../store/types';

export default {
	mixins: [filters],

	filters: {
		deduction(d, subtotal)
		{
			var amount = 0;

			if (d.deduct_type == 'percent')
				amount = subtotal * (d.deduct_amount / 100.0);
			else if (d.deduct_type == 'flat')
				amount = d.deduct_amount;

			return `-$${_.round(amount, 2).toFixed(2)}`;
		}
	},

	data()
	{
		return {
			checkingDiscounts: false,
			loading: false,
			showLoyalty:false,
		}
	},

	computed: {
		...mapGetters(['cartItems']),

		...mapState({
			discounts: state => state.catalog.discounts,
			promoCode: state => state.catalog.promoCode,
			taxes: state => state.catalog.taxes,
			loyaltyCode: state => state.dashboard.productLoyaltyCode
		}),

		subtotal()
		{
			var subtotal = 0;

			for(var item of this.cartItems)
			{
				var price = parseFloat(item.price) || 0;
				subtotal += _.round(price * item.quantity, 2);
			}

			if (this.loyaltyCode != "") {
				subtotal = subtotal - _.round(subtotal * 0.25, 2);
			}

			return subtotal;
		},

		deductions()
		{
			var amount = 0;

			if (this.discounts != null)
			{
				var discounts = this.discounts.slice();

				if (this.promoCode)
					discounts.push(this.promoCode.discount);

				for(var d of discounts)
				{
					if (d.deduct_type == 'percent')
						amount += this.subtotal * (d.deduct_amount / 100);
					else if (d.deduct_type == 'flat')
						amount += d.deduct_amount;
				}
			}
			return _.round(amount, 2);
		},

		summary()
		{
			var subtotal = this.subtotal - this.deductions;
			var total = 0;
			var savings = 0;
			var originalPriceItems = 0;
			var taxes = [];

			if (subtotal < 0)
				subtotal = 0;

			total = subtotal;

			for (var tax of this.taxes)
			{
				var amount = _.round((tax.percent / 100.0) * subtotal, 2);
				total += amount;
				taxes.push({ name: tax.name, amount: amount });
			}

			subtotal = _.round(subtotal, 2);
			total = _.round(total, 2);

			if (this.loyaltyCode === 'D') {
				for(var item of this.cartItems)
				{
					var price = parseFloat(item.price) || 0;
					originalPriceItems += _.round(price * item.quantity, 2);
				}

				savings = _.round(originalPriceItems * 0.25, 2);
			}

			return { subtotal, taxes, total, savings };
		}
	},

	methods: {
		async getDiscounts()
		{
			this.checkingDiscounts = true;

			try
			{
				await this.$store.dispatch('catalog.getDiscounts', { force: true });
			}
			finally
			{
				this.checkingDiscounts = false;
			}
		},
		async getUserInfo()
		{
		
			try
			{
				await this.$store.dispatch('catalog.getUserInfo', { force: true });
			}
			finally
			{
				// this.checkingDiscounts = false;
			}
		},
		async submitOrder()
		{
			this.loading = true;

			try
			{
				await this.$store.dispatch('catalog.submitOrder', { total: this.summary.total, loyaltyCode: this.loyaltyCode });

				alerts.show('Your order has been submitted successfully', 'success');

				this.$store.commit(types.CLEAR_CATALOG_ORDER);
				this.$router.push({ path: '/catalog/order_history' });
			}
			finally
			{
				this.loading = false;
			}
		}
	},

	mounted()
	{
		this.getDiscounts();
		this.getUserInfo();
	},

}
</script>
