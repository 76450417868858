
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
	computed: {
		...mapGetters(['appointmentList']),
	},

	props: {
		calendarDate: { type: Object, required: true }
	},

	methods: {
		// return list of appointments grouped by date
		filterApptsForMonth(month, year, returnList)
		{
			var dateOf, dateStr;
			var appts = returnList ? [] : {};
			var now = moment().date(1).month(month).year(year);
			var min = moment(now).startOf('month');
			var max = moment(now).endOf('month');

			for(var i=0; i<this.appointmentList.length; i++)
			{
				var a = this.appointmentList[i];
				var res;

				dateOf = moment(a.arrival_window_start_time);
				dateStr = dateOf.format('YYYY-MM-DD', dateOf);

				if (!dateOf.isBetween(min, max))
					continue;
			
				var res = {
					appt: { ...a },
					date: dateOf,
					dateStr: dateStr,
				};

				if (returnList)
				{
					appts.push(res);
				}
				else 
				{
					// add to date list
					if (appts[dateStr] == null)
						appts[dateStr] = [];

					appts[dateStr].push(res);
				}
			}

			return appts;
		},

	}
}