
import Vue from 'vue';

import types from './types';

export default {
	[types.SET_CATALOG_DISCOUNTS](state, list)
	{
		if (list && list !== null) {
			state.discounts = list.map(d => ({ ...d }));
		} else {
			state.discounts = [];
		}
	},

	[types.SET_CATALOG_PROMO_CODE](state, promoCode)
	{
		if (promoCode == null)
		{
			state.promoCode = null;
		}
		else
		{
			var pc = {};
			pc.discount = { ...promoCode.discount };
			pc.discountCode = { ...promoCode.discount_code };
			state.promoCode = pc;
		}
	},
	[types.SET_USER_INFO](state, loyaltyCode)
	{
		if (loyaltyCode == "")
		{
			state.loyaltyCode = "";
		}
		else
		{
		
			state.loyaltyCode = loyaltyCode;
		}
	},
	[types.SET_CATALOG_APPT](state, payload)
	{
		if (payload != null)
		{
			state.noAppointments = false;
			state.currentOrder = payload.order != null ? { ...payload.order } : null;
			state.nextAppt = { ...payload.nextAppt };
			state.franchise = { ...payload.franchise };
			state.taxes = payload.taxes.map(t => ({ ...t }));
		}
		else
		{
			state.noAppointments = true;
			state.currentOrder = null;
			state.nextAppt = null;
			state.franchise = null;
			state.taxes = null;
		}
	},

	[types.CART_UPDATE_PRODUCT_QTY](state, payload)
	{
		Vue.set(state.cart, payload.id, payload.quantity);
	},

	[types.SET_CART_CONTENTS](state, contents)
	{
		state.cart = { ...contents };
	},

	[types.CART_REMOVE_PRODUCT](state, payload)
	{
		if (state.cart[payload.id])
			Vue.delete(state.cart, payload.id);
	},

	[types.SET_CATALOG_PRODUCTS](state, list)
	{
		var products = {};
		var categories = {};

		// iterate over each category in list
		state.categoryList = list.map(c => {
			var cat = { ...c };
			cat.products = [];
		
			// get products
			for(var i=0; i<c.products.length; i++)
			{
				var p = { ...c.products[i] };
				products[p.id] = p;
				cat.products.push(p.id);
			}

			categories[cat.id] = cat;
			return cat.id;
		});

		state.products = products;
		state.categories = categories;
	},

	[types.SET_ORDER_LIST](state, list)
	{
		var orders = {};

		if (list && list !== null) {
			state.orderList = list.map(o => {
				orders[o.id] = { ...o };
				return o.id;
			});
		} else {
			state.orderList = [];
		}

		state.orders = orders;
	},

	[types.CLEAR_CATALOG_ORDER](state)
	{
		state.noAppointments = false;
		state.discounts = [];
		state.nextAppt = null;
		state.currentOrder = null;
		state.promoCode = null;
		state.franchise = null;
		state.taxes = null;
		state.cart = {};
		state.products = {};
		state.productList = [];
		state.categories = {};
		state.categoryList = [];
	}
}