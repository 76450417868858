<template>
	<div class="reschedule-wrapper">
		<div v-if="loading" class="obe-body-tex">Loading...</div>
		<obe-form v-else ref="form">
			<layout row wrap>
				<!--- INTERFACE HEADER -->
				<flex md8>
					<div class="obe-schedule-pad">
						<div class="obe-card-title obe-mb-0">Reschedule</div>
						<div class="obe-sub-title">Please select new cleaning date</div>
					</div>
				</flex>

				<flex md4>
					<div class="obe-month-switcher">
						<a class="obe-month-switcher__arrow" @click.prevent="changeMonth(-1)">
							<span class="obe-i-left-arrow-bold"></span>
							<span class="obe-sr-only">Previous Month</span>
						</a>
						
						<div class="obe-month-switcher__month">{{ monthName }}</div>
						
						<a href="#" class="obe-month-switcher__arrow" @click.prevent="changeMonth(1)">
							<span class="obe-i-right-arrow-bold"></span>
							<span class="obe-sr-only">Next Month</span>
						</a>
					</div>
				</flex>

				<!-- SECHEDULING OPTIONS -->
				<flex md8>

					<!-- SELECT INITIAL ARRIVAL DATE ON CALENDAR -->
					<schedule-calendar ref="calendar" :min-date="minDate" :calendar-date="calendarDate" v-model="arrivalDate"></schedule-calendar>

				</flex>

				<flex md4>
					<layout column fill-height justify="space-between">
						<flex>
							<!-- INITIAL ARRIVAL WINDOW -->
							<dropdown-field
								type="vert"
								label="Select time preference"
								v-model="arrivalWindow"
								:items="availableTimeOptions">
							</dropdown-field>

							<!-- GRADE INFO -->
							<fade-transition>
								<div v-if="selectedGrade != null">
									<div class="obe-avail-legend">
										<div class="obe-avail-legend__item">
											<span class="dot" :style="{ backgroundColor: selectedGrade.color }"></span>
											<div class="obe-avail-legend__title">{{ selectedGrade.name }}</div>
											<div class="obe-avail-legend__desc">{{ selectedGrade.description }}</div>
										</div> 
									</div>
								</div>
							</fade-transition>
						</flex>

						<flex>
							<div v-if="canRescheduleAll" class="obe-form-field">
								<checkbox v-model="rescheduleAll" label="Reschedule all subsequent bookings"/>
							</div>
							<push-button block color="orange" :loading="saving" @click="submit">Reschedule</push-button>
						</flex>
					</layout>
				</flex>
			</layout>
		</obe-form>
	</div>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';

import api from 'obe/dashboard/api';
import alerts from 'obe/shared/alerts';
import { timeOptions } from 'obe/screens/booking-form/info';

import types from '../store/types';
import ScheduleCalendar from './RescheduleCalendar.vue';

const DATE_FORMAT = 'YYYY-MM-DD';

const now = moment();
const minDate = moment(now).startOf('month');
const maxDate = moment(minDate).add(4, 'M').startOf('month');

export default {
	components: { ScheduleCalendar },

	minDate, 
	maxDate,
	timeOptions,

	data()
	{
		return {
			saving: false,
			loading: true,
			rescheduleAll: false,
			arrivalWindow: '',
			arrivalDate: '',
			calendarDate: { month: now.month(), year: now.year() },
		}
	},

	computed: {
		...mapState({
			booking: state => state.schedule.apptBooking,
			workOrder: state => state.schedule.apptWorkOrder,
			currentAppt: state => state.schedule.currentAppt,
			availGrades: state => state.schedule.availGrades,
		}),

		minDate()
		{
			// if there is an earliest reschedule date use that
			if (this.currentAppt.earliest_reschedule_date)
				return moment(this.currentAppt.earliest_reschedule_date).format('YYYY-MM-DD');

			// otherwise use tomorrow
			else
				return moment().add(1, 'days').format('YYYY-MM-DD');
		},

		selectedGrade()
		{
			return this.availGrades[this.arrivalDate] || null;
		},

		monthName()
		{
			return this.getDate().format('MMMM YYYY');
		},

		canMoveBack()
		{
			var date = this.getDate().add(-1, 'M');
			return date.isAfter(minDate);
		},

		canMoveForward()
		{
			var date = this.getDate().add(1, 'M');
			return date.isBefore(maxDate);
		},

		canRescheduleAll()
		{
			var status = this.currentAppt.work_order_generation_status;
			return this.booking.cleaning_freq != 'one-time' && status != null && status.toLowerCase() == 'complete';
		},

		availableTimeOptions()
		{
			// When grade is Limited then time must be flexible
			let availabilityGrade = this.availGrades[this.arrivalDate];
			let _that = this; // 'this' is not available inside filter

			if (availabilityGrade && availabilityGrade['name'] === 'Limited') {
				return _.filter(timeOptions, function(time) {
					if (time.value === 'flexible-8am-4pm') {
						_that.arrivalWindow = time.value;

						return time;
					};
				});
			}

			return this.$options.timeOptions;
		},
	},

	methods: {
		async loadAvailGrades()
		{
			this.loading = true;

			try
			{
				await this.$store.dispatch('schedule.getAvailGrades', {
					earliest_date: minDate.format('YYYY-MM-DD'),
					latest_date: maxDate.format('YYYY-MM-DD'),
				});
			}
			finally
			{
				this.loading = false;
			}
		},

		validate()
		{
			return this.$refs.calendar.validate();
		},

		getDate()
		{
			return moment(now).month(this.calendarDate.month).year(this.calendarDate.year).date(1);
		},		

		changeMonth(dir)
		{
			var date = this.getDate().add(dir, 'M');

			if (date.isSameOrAfter(minDate) && date.isSameOrBefore(maxDate))
			{
				this.calendarDate = {
					month: date.month(),
					year: date.year(),
				};
			}
		},

		onDateSelected(dateInfo)
		{
			this.dateInfo = dateInfo;
		},

		async submit()
		{
			this.saving = true;

			try
			{
				let all = this.booking.cleaning_freq != 'one-time' && this.rescheduleAll;
				const work_order = {
					cleaning_frequency: this.workOrder.cleaning_frequency,
					initial_work_order: this.workOrder.initial_work_order,
				};

				if (all)
				{
					let params = {
						work_order_id: this.currentAppt.parent_record_id,
						work_order: work_order,
						appt_id: this.currentAppt.id,
						arrival_window: this.arrivalWindow,
						to_date: this.arrivalDate,
						service_territory_id: this.currentAppt.service_territory_id,
						//to_date: moment(this.arrivalDate, 'YYYY-MM-DD').format('YYYY-MM-DD[T]HH:MM:ssZZ'),
					};

					await api.post('/appointments/reschedule_all', params);
					await alerts.show('Appointment(s) rescheduled successfully', 'Success', 'success');
				}
				else
				{
					let params = {
						work_order_id: this.currentAppt.parent_record_id,
						work_order: work_order,
						appt_id: this.currentAppt.id,
						arrival_window: this.arrivalWindow,
						to_date: this.arrivalDate,
						service_territory_id: this.currentAppt.service_territory_id,
						//to_date: moment(this.arrivalDate, 'YYYY-MM-DD').format('YYYY-MM-DD[T]HH:MM:ssZZ'),
					};

					await api.post('/appointments/reschedule', params);
					await alerts.show('Appointment rescheduled successfully', 'Success', 'success');
				}

				this.$router.push('/schedule');
			}
			finally
			{
				this.saving = false;
			}
		}
	},

	mounted()
	{
		this.loadAvailGrades();

		this.arrivalDate = moment(this.currentAppt.arrival_window_start_time).format('YYYY-MM-DD');

		if (this.booking.cleaning_freq == 'one-time')
			this.arrivalWindow = this.booking.initial_arrival_window;
		else
			this.arrivalWindow = this.booking.regular_arrival_time;
	}
}
</script>

<style scoped>
.reschedule-wrapper
{
	padding: 10px;
}

@media screen and (max-width: 575px)
{
	.reschedule-wrapper
	{
		padding: 0;
	}
}
</style>