export const SET_CATALOG_APPT = 'SET_CATALOG_APPT';
export const SET_CATALOG_DISCOUNTS = 'SET_CATALOG_DISCOUNTS';
export const SET_CATALOG_PROMO_CODE = 'SET_CATALOG_PROMO_CODE';
export const CART_UPDATE_PRODUCT_QTY = 'CART_UPDATE_PRODUCT_QTY';
export const SET_CART_CONTENTS = 'SET_CART_CONTENTS';
export const CART_REMOVE_PRODUCT = 'CART_REMOVE_PRODUCT';
export const SET_CATALOG_PRODUCTS = 'SET_CATALOG_PRODUCTS';
export const SET_ORDER_LIST = 'SET_ORDER_LIST';
export const CLEAR_CATALOG_ORDER = 'CLEAR_CATALOG_ORDER';
export const SET_USER_INFO = 'SET_USER_INFO';

export default {
	SET_CATALOG_APPT,
	SET_CATALOG_DISCOUNTS,
	SET_CATALOG_PROMO_CODE,
	CART_UPDATE_PRODUCT_QTY,
	SET_CART_CONTENTS,
	CART_REMOVE_PRODUCT,
	SET_CATALOG_PRODUCTS,
	SET_ORDER_LIST,
	CLEAR_CATALOG_ORDER,
	SET_USER_INFO
}