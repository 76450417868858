
export const freqOptions = [
	{ text: 'Only on first visit', value: 'initial' },
	{ text: 'Only on regular visits (not including our first visit)', value: 'regular' },
	{ text: 'On all visits', value: 'both' },
];

export const addonList = {
	oven_inside: { name: 'oven_inside', label: 'Oven Inside', icon: 'obe-i-oven2' },
	fridge_inside: { name: 'fridge_inside', label: 'Fridge Inside', icon: 'obe-i-fridge2' },
	windows_inside: { name: 'windows_inside', label: 'Windows Inside', icon: 'obe-i-window2' },
	cupboards_and_closets: { name: 'cupboards_and_closets', label: 'Cabinets & Closets Inside', icon: 'obe-i-cabinets2' },
	balcony_sweeping: { name: 'balcony_sweeping', label: 'Balconies Swept', icon: 'obe-i-balcony2' },
	blinds_wiped: { name: 'blinds_wiped', label: 'Blinds Wiped', icon: 'obe-i-blinds2' },
	walls_washed: { name: 'walls_washed', label: 'Walls Washed', icon: 'obe-i-walls2' },
	marks_on_walls: { name: 'marks_on_walls', label: 'Marks on Walls & Baseboards', icon: 'obe-i-marks2' },
	dishes_washed: { name: 'dishes_washed', label: 'Dishes Washed', icon: 'obe-i-dishes2' },
	linen_change: { name: 'linen_change', label: 'Linen & Towels Changed', icon: 'obe-i-linen2' },
	inspection: { name: 'inspection', label: 'Inspection for Damage, Toiletries & Supplies', icon: 'obe-i-inspection2' },
	laundry_options: { name: 'laundry_options', label: 'Laundry Options', icon: 'obe-i-laundry2' },
	disinfection: { name: 'disinfection', label: 'Disinfection Service', icon: 'obe-i-disinfection' },
};

export const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const timeOptions = [
	{ text: 'Flexible: 8am-4pm', value: 'flexible-8am-4pm' },
	{ text: '8am-1pm', value: '8am-1pm' },
	{ text: '11am-4pm', value: '11am-4pm' },
];

export const serviceTypeAddons = {
	'complete home': [
		'oven_inside',
		'fridge_inside',
		'windows_inside',
		'cupboards_and_closets',
		'balcony_sweeping',
		'blinds_wiped',
		'walls_washed',
		'marks_on_walls',
		'dishes_washed',
		'linen_change',
		'laundry_options',
		'inspection',
	],
	'moving': ['oven_inside','fridge_inside','windows_inside','balcony_sweeping','blinds_wiped','walls_washed','inspection'],
	'post construction': ['oven_inside', 'fridge_inside', 'balcony_sweeping'],
	'vacation rental': ['oven_inside','fridge_inside','windows_inside','cupboards_and_closets','balcony_sweeping','blinds_wiped','walls_washed','marks_on_walls','laundry_options','inspection'],
};

export const serviceTypes = [
	{ text: 'Complete Home', value: 'complete home' },
	{ text: 'Move In / Out', value: 'moving' },
	{ text: 'Post Construction', value: 'post construction' },
	{ text: 'Short-Term Rental', value: 'vacation rental' },
];

export const cleaningFreqOptions = [
	{ text: 'One-time', value: 'one-time' },
	{ text: 'Weekly', value: 'weekly' },
	{ text: 'Bi-weekly', value: 'bi-weekly' },
	{ text: 'Every 3 weeks', value: 'every-3-weeks' },
	{ text: 'Every 4 weeks', value: 'every-4-weeks' },
];

export const defaultAccess = {
	initialAccessType: '',
	initialAccessDetails: '',
	garbage: '',
	garbageOther: '',
	parking: '',
	parkingOther: '',
	regularAccessType: '',
	regularAccessDetails: '',
};