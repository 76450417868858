<template>
	<input
		:autofocus="autofocus" 
		:type="type"
		class="obe-text-field"
		:disabled="disabled"
		:placeholder="placeholder"
		:maxlength="maxlength"
		:value="value"
		@input="$emit('input', $event.target.value)">
</template>

<script>
export default {
	props: {
		type: { type: String, default: 'text' },
		disabled: { type: Boolean, default: false },
		placeholder: { type: String, default: '' },
		autofocus: { type: Boolean, default :false },
		maxlength: { type: [String,Number] },
		value: { default: '' },
	}
}
</script>