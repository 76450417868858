
import router from 'obe/dashboard/router';

import Screen from './comp/LoginScreen.vue';
import ForgotPassword from './comp/ForgotPassword.vue';
import ResetPassword from './comp/ResetPassword.vue';
import ForgotEmail from './comp/ForgotEmail.vue';

router.addRoutes([
	{
		name: 'db-login',
		path: '/login',
		props: true,
		meta: { noUserRequired: true },
		component: Screen
	},

	{
		name: 'db-forgot-password',
		path: '/forgot_password',
		props: true,
		meta: { noUserRequired: true },
		component: ForgotPassword
	},

	{
		name: 'db-reset-password',
		path: '/reset_password',
		props: true,
		meta: { noUserRequired: true },
		component: ResetPassword
	},

	{
		name: 'db-forgot-email',
		path: '/forgot_email',
		props: true,
		meta: { noUserRequired: true },
		component: ForgotEmail
	}

]);
