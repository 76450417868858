<template>
	<div class="obe-account-container">
		<!-- ACCOUNT FORM -->
		<layout row wrap>
			<flex lg2>
				<div class="obe-account-title">
					<div class="obe-account-title__top">
						<div class="obe-account-title__icon"><span class="obe-i-user"></span></div>
						<div class="obe-account-title__primary">Account</div>
					</div>
				</div>
			</flex>

			<flex lg10>
				<account-form></account-form>
			</flex>
		</layout>

		<!-- PASSWORD FORM -->
		<layout row wrap>
			<flex lg2>
				<div class="obe-account-title">
					<div class="obe-account-title__top">
						<div class="obe-account-title__icon"><span class="obe-i-lock"></span></div>
						<div class="obe-account-title__primary">Password</div>
					</div>
					<div class="obe-account-title__desc">Use this form to change your current password</div>
				</div>
			</flex>

			<flex lg10>
				<password-form></password-form>
			</flex>
		</layout>
	</div>
</template>

<script>
import AccountForm from './AccountForm.vue';
import PasswordForm from './PasswordForm.vue';

export default {
	components: { AccountForm, PasswordForm },
}
</script>

<style scoped>

.account-icon
{
	font-size: 56px;
}
</style>