<template>
	<div>
		<template v-if="loading">
			Loading...
		</template>

		<template v-else>
			<!-- PRIMARY ADDRESS -->
			<div class="obe-heading obe-heading--px24 obe-heading--blue">Primary Contact / Address</div>
			<div v-for="addr in primaryContactList" :key="addr.id" class="obe-address-selector">
				<div class="obe-address-selector__addr">
					<div class="obe-address-line">
						<span class="obe-address-line__name">
							<span class="name-item">{{ addr.first_name }} {{ addr.last_name }}</span>
							<span class="name-item">{{ addr.email }}</span>
							<span class="name-item">{{ addr.mobile_phone }}</span>
						</span>

						<div class="obe-address-line__controls">
							<router-link class="obe-address-line__edit" :to="`/contacts/${addr.id}`">
								<span class="obe-sr-only">Edit Contact</span>
								<span>Edit</span>
							</router-link>
							<a href="#" class="obe-address-line__delete" @click.prevent="deleteContact(addr.id)">
								<span class="obe-sr-only">Delete Contact</span>
								<span class="obe-i-delete"></span>
							</a>
						</div>
					</div>
				</div>
			</div>

			<!-- OTHER ADDRESSES -->
			<div class="obe-heading obe-heading--px24">Contacts</div>
			<div v-for="addr in customerContactList" :key="addr.id" class="obe-address-selector">
				<div class="obe-address-selector__addr">
					<div class="obe-address-line">
						<span class="obe-address-line__name">
							<span class="name-item">{{ addr.first_name }} {{ addr.last_name }}</span>
							<span class="name-item">{{ addr.email }}</span>
							<span class="name-item">{{ addr.mobile_phone }}</span>
						</span>

						<div class="obe-address-line__controls">
							<router-link class="obe-address-line__edit" :to="`/contacts/${addr.id}`">
								<span class="obe-sr-only">Edit Contact</span>
								<span>Edit</span>
							</router-link>
							<a href="#" class="obe-address-line__delete" @click.prevent="deleteContact(addr.id)">
								<span class="obe-sr-only">Delete Contact</span>
								<span class="obe-i-delete"></span>
							</a>
						</div>
					</div>
				</div>
			</div>

			<ul class="obe-button-menu">
				<li><push-button wide to="/contacts/new">Add Contact</push-button></li>
			</ul>

			<!--
			<modal :show.sync="showAddrForm" max-width="800px">
				<card>
					<address-form v-if="currentAddress != null" @closed="onAddressFormClosed"></address-form>
				</card>
			</modal>
			-->

		</template>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import api from 'obe/dashboard/api';
import alerts from 'obe/shared/alerts';

import types from '../store/types';

//import AddressForm from './ContactForm.vue';

export default {
	//components: { AddressForm },

	filters: {
		truncated(val)
		{
			if (!val)
				val = '';
			
			if (val.length >= 60)
				return `${val.slice(0, 60)}...`;
			else
				return val;
		}
	},

	data()
	{
		return {
			loading: true,

		}
	},

	computed: {
		...mapState({
			contacts: state => state.contacts.contacts,
			currentContact: state => state.contacts.currentContact,
			//primaryAddressId: state => state.addresses.primaryAddressId,
		}),

		...mapGetters([
			'customerContactList', 'primaryContactList'
		]),

		/*showAddrForm: {
			get()
			{
				return this.currentContact != null;
			},

			set(val)
			{
				if (!val)
					this.$store.commit(types.SET_CURRENT_CONTACT, null);
			}
		},*/
	},

	methods: {
		async refreshContacts()
		{
			try
			{
				this.loading = true;
				await this.$store.dispatch('contacts.fetchContactList');
				//this.primary = this.primaryAddressId;
			}
			finally
			{
				this.loading = false;
			}
		},

		async deleteContact(id)
		{
			var con = this.contacts[id];

			// make sure this address is present in state
			if (!con)
				return;

			// prevent users from deleting primary contacts
			if (con.is_primary)
			{
				alerts.show('Cannot delete your primary contact!', 'error');
				return;
			}

			if (!await alerts.confirm('Are you sure you want to delete this contact?', 'Confirm', 'question'))
				return;

			this.loading = true;

			try
			{
				await api.delete(`/contacts/${id}`);
				await this.refreshContacts();
			}
			finally
			{
				this.loading = false;
			}
		},
	},

	mounted()
	{
		this.refreshContacts();
	}
}
</script>

<style lang="scss" scoped>
.loading
{
	opacity: 0.5;
}

.address-options
{
	max-width: 200px;
}
</style>
