
import router from 'obe/dashboard/router';

import Screen from './comp/ScheduleScreen.vue';
import Appointment from './comp/Appointment.vue';
import BookingSummary from './comp/BookingSummary.vue';

router.addRoutes([
	{
		name: 'db-scheduling',
		path: '/schedule',
		props: true,
		meta: { title: 'Schedule' },
		component: Screen
	},

	{
		name: 'db-appointment',
		path: '/appointment/:id',
		props: true,
		meta: { title: 'Appointment Management'},
		component: Appointment,
	},

	{
		name: 'db-booking-summary',
		path: '/booking_summary/:id',
		props: true,
		meta: { title: 'Booking Summary'},
		component: BookingSummary,
	},
]);
