<template>
	<div class="obe-radio" :class="{ 'obe-radio--checked': checked, 'obe-radio--disabled': disabled }" @click.prevent="updateValue">
		<input type="radio" :checked="checked" :disabled="disabled" @input="updateValue">
		<div class="obe-radio__circle"></div>
		<div class="obe-radio__label">
			<slot>{{ label }}</slot>
		</div>
	</div>
</template>

<script>
export default {
    model: {
        prop: 'data',
        event: 'input',
	},
	
    props: {
        value: { default: null },
        data: { default: '' },
        label: { type: String, default: '' },
        disabled: { type: Boolean, default: false },
    },
	
	computed: {
        checked()
        {
			return this.data == this.value;
        },
    },
	
	methods: {
		updateValue()
		{
			this.$emit('input', this.value);
		}

        /*toggle()
        {
            // do nothing if disabled
            if (this.disabled) return;

            // toggle value
            if (this.data instanceof Array)
            {
                var newData = this.data.slice();
                var pos = newData.indexOf(this.value);
                if (pos >= 0)
                    newData.splice(pos, 1);
                else
                    newData.push(this.value);
                
                this.$emit('input', newData);
            }
            else if (typeof(this.data) == 'boolean')
            {
                this.$emit('input', !this.data);
            }
        }*/
    }
}
</script>