<template>
	<div>
		<div class="obe-schedule-calendar" style="margin: 0;">
			<div class="obe-schedule-calendar__header">
				<div class="obe-schedule-calendar__header-label">Sun</div>
				<div class="obe-schedule-calendar__header-label">Mon</div>
				<div class="obe-schedule-calendar__header-label">Tue</div>
				<div class="obe-schedule-calendar__header-label">Wed</div>
				<div class="obe-schedule-calendar__header-label">Thu</div>
				<div class="obe-schedule-calendar__header-label">Fri</div>
				<div class="obe-schedule-calendar__header-label">Sat</div>
			</div>

			<div class="obe-schedule-calendar__days">
				<div class="obe-schedule-calendar__cell" v-for="day in dates" :key="day.key">
					<a v-if="day.available" href="#" :class="day.classes" :style="day.styles" @click.prevent="setArrivalDate(day)">{{ day.dayOfMonth }}</a>
					<span v-else :class="day.classes">{{ day.dayOfMonth }}</span>
				</div>
			</div>

			<div v-if="error != null" class="obe-schedule-calendar__error">
				<div class="obe-alert obe-alert--error">
					{{ error }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';

import { timeOptions } from 'obe/screens/booking-form/info';

export default {

	timeOptions,

	props: {
		calendarDate: { type: Object, required: true },
		minDate: { type: String, required: true },
		value: { type: String, required: true },
	},

	data()
	{
		return {
			dates: [],
			error: null,
			window: '',
		}
	},

	computed: {
		...mapState({
			apptFranchise: state => state.schedule.apptFranchise,
			apptBooking: state => state.schedule.apptBooking,
			availGrades: state => state.schedule.availGrades,
			appt: state => state.schedule.currentAppt,
			closures: state => state.schedule.franchiseClosures,
		}),

		franchiseDays()
		{
			var type, days = [];

			if (this.apptBooking.cleaning_freq == 'one-time')
			{
				if (this.apptBooking.service_type == 'post construction')
					type = 'post-construction';
				else
					type = 'one-time';
			}
			else
			{
				type = 'regular';
			}

			for(var d of this.apptFranchise.days)
				if (d.service_type == type)
					days.push(d.day);

			return days;
		}
	},

	watch: {
		calendarDate: {
			deep: true,
			handler(){
				this.getCalendarDates();
			}
		},

		value()
		{
			this.getCalendarDates();
		}
	},

	methods: {
		validate()
		{
			this.error = this.arrivalDate ? null : 'Please select a date above';
			return this.error == null;
		},

		// check the given date against any applicable closures
		isClosedOnDate(date)
		{
			// check fixed date closures (moment.month() results are 0-based so we add 1 below)
			for(var fixed of this.closures.fixed)
				if (fixed.month == date.month()+1 && fixed.day == date.date())
					return true;

			// check date range closures
			for(var range of this.closures.ranged)
			{
				// convert range start/end dates to moment instances
				var start = moment(range.start_date, 'YYYY-MM-DD').startOf('day');
				var end = moment(range.end_date, 'YYYY-MM-DD').endOf('day');

				// check if range is between start/end inclusively
				if (date.isSameOrAfter(start) && date.isSameOrBefore(end))
					return true;
			}

			// if none of the checks above were triggered then return false here
			return false;
		},

		getCalendarDates()
		{
			var baseCls = 'obe-schedule-calendar__date';
			var now = moment().month(this.calendarDate.month).year(this.calendarDate.year);
			//var minDate = moment().add(1, 'days');
			var minDate = moment(this.minDate, 'YYYY-MM-DD');//.add(1, 'days');
            var startDate = moment(now).date(1).day(0);
			var endDate = moment(now).endOf("month").day(6);
			var current = startDate;
			var dates = {};
			var startOfMonth = moment(now).startOf('month');
			var endOfMonth = moment(now).endOf('month');
			var nextApptDate = this.appt.next_appt_date != null ? moment(this.appt.next_appt_date) : null;

			// if min date is today then move it forward by a day
			if (moment().format('YYYY-MM-DD') == minDate.format('YYYY-MM-DD'))
				minDate.add(1, 'days');

            // calculate dates
            while(current.isSameOrBefore(endDate))
            {
				// create data for cell
				var currentUnix = current.unix();
				var dateStr = current.format('YYYY-MM-DD');
                var date = {
					key: dateStr,
					dayOfMonth: current.date(),
					date: moment(current),
					classes: [baseCls],
					styles: {},
					available: false,
				};

				// is the franchise closed on this date?
				if (this.isClosedOnDate(current))
				{
					date.classes.push(`${baseCls}--outside`);
				}
				// prevent initial cleanings from being reschedule after the recurring schedule starts
				else if (nextApptDate != null && current.isSameOrAfter(nextApptDate))
				{
					date.classes.push(`${baseCls}--outside`);
				}
				// is this date even available to pick?
				else if (current.isAfter(minDate) && current.isSameOrBefore(endDate) && this.franchiseDays.indexOf(current.day()) >= 0)
				{
					date.available = true;

					// is this the selected date
					if (this.value == dateStr)
					{
						date.classes.push(`${baseCls}--selected`);
					}
					else
					{
						date.grade = this.availGrades[dateStr] || null;

						if (date.grade)
						{
							date.classes.push(`${baseCls}--graded`);
							date.styles.borderColor = date.grade.color;
						}
						else
						{
							date.classes.push(`${baseCls}--available`);
						}
					}
				}
				else
				{
					date.classes.push(`${baseCls}--outside`);
				}

				//dates.push(date);
				dates[dateStr] = date;
                current.add(1, 'd');
			}	
			
			this.dates = dates;
		},

		setArrivalDate(info)
		{
			// set arrival date in statev
			var str = info.date.format('YYYY-MM-DD');
			this.$emit('input', str);

			// refresh info
			this.getCalendarDates();
		}
	},

	mounted()
	{
		this.getCalendarDates();
	}
}
</script>