
export default {
	notEmpty(v)
	{
		return !!v;
	},

	phone(v)
	{
		var invalid = v.match(/[^0-9\-()\s]/);
		var replaced = v.replace(/[^0-9]/g, '');
		return invalid == null && replaced.length >= 10;
	},

	email(v)
	{
		//var re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;
		var re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
		return !!v && v.trim().match(re) != null; 
	},

	password(v, minLength)
	{
		var min = minLength || 6;
		return v.length >= 6;
	},

	postalCode(pc)
	{
		var pc = pc.replace(/\s/g, '');
		var res = pc.match(/^[a-z][0-9][a-z][0-9][a-z][0-9]$/i) != null;
		return res;
	}
}