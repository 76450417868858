<template>
	<fade-transition>
		<div v-if="active" class="obe-alert-modal">
			<div class="obe-alert-modal__content">
				<card>
					<div class="obe-error-popup">
						<div :class="`obe-error-popup__icon obe-error-popup__icon-warning`"><span class="obe-i-attention"></span></div>
						<div class="obe-error-popup__msg">
							There are unsaved changes. If you leave this tab, you’ll lose your changes.
						</div>

						<ul class="obe-button-menu obe-button-menu--horz">
							<li><push-button wide ref="agreeBtn" @click="cancel">Continue Editing</push-button></li>
							<li><push-button wide color="gray" @click="agree">Discard Changes</push-button></li>
						</ul>
					</div>
				</card>
			</div>
		</div>
	</fade-transition>
</template>

<script>

const iconClasses = {
	info: 'obe-i-attention',
	success: 'obe-i-verified',
	warning: 'obe-i-attention',
	error: 'obe-i-attention',
}

export default {

	data()
	{
		return {
			msg: '',
			active: false,
		}
	},

	methods: {
		display()
		{
			this.active = true;

			return new Promise((resolve, reject) => {
				this.resolve = resolve;
				this.reject = reject;
			});
		},

		agree()
		{
			this.resolve(true);
			this.active = false;
		},

		cancel()
		{
			this.resolve(false);
			this.active = false;
		},
	}
}
</script>

