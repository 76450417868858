
export default {
	appts: {},
	apptList: [],
	apptsLoaded: false,
	franchiseClosures: {},

	currentAppt: null,
	apptBooking: null,
	apptAddons: null,
	apptFranchise: null,
	apptAccess: null,
	apptAddress: null,
	currentAddon: null,
	isRecurring: false,
	currentApptDiscounts: [],
	currentApptLoyaltyCode: '',
	productLoyaltyCode: '',

	overrides: {
		apply_overrides: false,
		service_type: null,
		initial_duration: null,
		initial_cost: null,
		regular_duration: null,
		regular_cost: null,
	},

	// Estimate changes base value so we need to keep track of base values.
	baseOverrides: {
		apply_overrides: false,
		service_type: 'complete home',
		initial_duration: null,
		initial_cost: null,
		regular_duration: null,
		regular_cost: null,
	},

	estimate: null,

	availGrades: {},

	apptProductOrder: {},
	apptProducts: {},
	apptProductList: [],
	showOfferBanner: false,
}