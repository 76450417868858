<template>
	<form-field type="vert">
		<template v-slot:label>
			<div class="obe-form-field__label">
				<checkbox :data="baseOverrides[name] != null" @input="updateOverride($event ? '' : null)">{{ label }}</checkbox>
			</div>
		</template>

		<dropdown
			:disabled="baseOverrides[name] == null"
			:value="selectedServiceType"
			:items="serviceTypes"
			:no-selection="noSelection"
			@input="updateOverride($event)"
		/>
	</form-field>
</template>


<script>

import { mapState } from 'vuex';
import types from '../store/types';

export default {

	props: {
		name: { type: String, required: true },
		label: { type: String },
	},

	data()
	{
		return {
			selectedServiceType: null,
			noSelection: 'Select a service type',
			serviceTypes: [
				{ text: 'Complete Home', value: 'complete home' },
				{ text: 'Move In/Out', value: 'moving' },
				{ text: 'Post Construction', value: 'post construction' },
				{ text: 'Vacation Rental', value: 'vacation rental' },
			],
		}
	},

	computed: {
		...mapState({
			baseOverrides: state => state.schedule.baseOverrides,
			booking: state => state.schedule.apptBooking,
		}),
	},

  mounted() {
		if (this.baseOverrides.service_type !== null || this.baseOverrides.service_type !== '') {
			this.selectedServiceType = this.baseOverrides.service_type;
		}
	},

	methods: {
		updateOverride(value)
		{
			this.selectedServiceType = value;
			this.$store.commit(types.SET_APPT_BASE_OVERRIDES, { [this.name]: value });
		},
	}
}
</script>
