<template>
	<form-field type="vert">
		<template v-slot:label>
			<div class="obe-form-field__label">
				<checkbox :data="baseOverrides[name] != null" @input="updateOverride($event ? '' : null)">{{ label }}</checkbox>
			</div>
		</template>

		<layout row>
			<flex sm6>
				<text-box v-model="hours" :disabled="value == null" placeholder="Hours" />
			</flex>
			<flex sm6>
				<text-box v-model="minutes" :disabled="value == null" placeholder="Minutes" :maxlength="2" />
			</flex>
		</layout>
	</form-field>
	
</template>

<script>
import { mapState } from 'vuex';

import types from '../store/types';

export default {
	props: {
		name: { type: String, required: true },
		label: { type: String },
	},

	computed: {
		...mapState({
			baseOverrides: state => state.schedule.baseOverrides,
		}),

		value()
		{
			var val = this.baseOverrides[this.name];
			return val != null ? (parseInt(this.baseOverrides[this.name], 10) || 0) / 3 : null;
		},

		hours:
		{
			get() 
			{ 
				return this.value != null ? Math.floor(this.value / 60) : ''; 
			},

			set(value) 
			{ 
				this.updateOverride((value * 60) + this.minutes); 
			}
		},

		minutes:
		{
			get()
			{
				return this.value != null ? this.value % 60 : ''; 
			},

			set(value)
			{
				var mins = parseInt(value, 10) || 0;
				this.updateOverride((this.hours * 60) + mins);
			}
		}
	},

	methods: {
		updateOverride(value)
		{
			if (value == null)
				this.$store.commit(types.SET_APPT_BASE_OVERRIDES, { [this.name]: null });
			else
				this.$store.commit(types.SET_APPT_BASE_OVERRIDES, { [this.name]: value * 3 });
		}
	}
}
</script>
