
import { getApi } from 'obe/api';

import types from './types';


export default {
	async ['db.getCurrentUser']({ commit })
	{
		var result = await getApi().get('/customers/sessions/current');
		var cust = result.data.customer;

		if (cust != null)
		{
			commit(types.SET_CURRENT_USER, cust);
			return true;
		}
		else
		{
			return false;
		}
	}
}