
export default {
	noAppointments: false,
	discounts: null,
	nextAppt: null,
	currentOrder: null,
	promoCode: null,
	franchise: null,
	taxes: null,
	
	cart: {},
	
	categories: {},
	categoryList: [],

	products: {},
	productList: [],

	orders: {},
	orderList: [],
}