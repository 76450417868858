<template>
	<form-field type="vert">
		<template v-slot:label>
			<div class="obe-form-field__label">
				<checkbox :data="baseOverrides[name] != null" @input="updateOverride($event ? '' : null)">{{ label }}</checkbox>
			</div>
		</template>

		<text-box
			:disabled="baseOverrides[name] == null"
			:value="baseOverrides[name] || ''"
			@input="updateOverride($event)"
		/>
	</form-field>
	
</template>

<script>
import { mapState } from 'vuex';

import types from '../store/types';

export default {
	props: {
		name: { type: String, required: true },
		label: { type: String },
	},

	computed: {
		...mapState({
			baseOverrides: state => state.schedule.baseOverrides,
		}),
	},

	methods: {
		updateOverride(value)
		{
			this.$store.commit(types.SET_APPT_BASE_OVERRIDES, { [this.name]: value });
		}
	}
}
</script>
