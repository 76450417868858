<template>
	<card>
		<!-- WARN USER IF WITHIN 24-48 HOURS OF ARRIVAL DATE -->
		<div v-if="showWarning && isAdmin" class="obe-warning-popup">
			<div class="obe-warning-popup__title">Skip existing booking</div>

			<div class="obe-warning-popup__desc">
				You are trying to cancel your booking within {{ hours }} hours of your cleaning service.<br>
				<strong>YOU WILL BE CHARGED {{ surcharge }} OF YOUR CLEANING SERVICE COST IF YOU PROCEED.</strong>
			</div>

			<ul class="obe-button-menu obe-button-menu--vert-center">
				<li><push-button wide block color="orange" @click="dismiss">Keep original booking</push-button></li>
				<li><push-button wide block color="text" @click="resume">Cancel at additional cost</push-button></li>
			</ul>
		</div>

		<!-- SHOW CANCELLATION INTERFACE IF ADMIN -->
		<div v-if="!showWarning && isAdmin" class="obe-warning-popup">
			<div class="obe-warning-popup__title">Cancel regular schedule</div>
			
			<div class="obe-warning-popup__desc">
				We are sorry to see you cancelling. Please let us know your reasons and our Customer Care Team will send you confirmation shortly.
			</div>

			<div class="obe-warning-popup__desc">
				<obe-form ref="form">
					<radio-group
						inline
						hide-label
						:items="$options.reasons"
						:rules="rules.reason"
						v-model="reason">
					</radio-group>

					<text-field
						hide-label
						multiline
						placeholder="Details"
						v-model="otherReason">
					</text-field>
				</obe-form>
			</div>

			<ul class="obe-button-menu obe-button-menu--horz">
				<li><push-button wide outline color="gray" @click="dismiss">Close</push-button></li>
				<li><push-button wide color="orange" :loading="saving" @click="submit">Request Cancellation</push-button></li>
			</ul>
		</div>

		<!-- OTHERWISE SHOW HELP INSTRUCTIONS -->
		<div v-if="!isAdmin" class="obe-warning-popup">
			<div class="obe-warning-popup__title">Need Help Cancelling?</div>

			<div class="obe-warning-popup__desc">
				<b><i>Please contact our Customer Care Team via email or phone to cancel your Recurring Schedule</i></b>
			</div>

			<ul class="obe-button-menu obe-button-menu--vert-center">
				<li><push-button wide block color="orange" @click="dismiss">Close</push-button></li>
			</ul>
		</div>
	</card>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';

import api from 'obe/dashboard/api';

export default {
	reasons: [
		{ text: 'Not Happy with Service', value: 'Not Happy with service' },
		{ text: 'Price', value: 'Price' },
		{ text: 'Moving', value: 'Moving' },
		{ text: 'Other', value: 'Other' },
	],

	data()
	{
		return {
			showWarning: false,
			hours: 0,
			surcharge: '',

			reason: '',
			otherReason: '',
			saving: false,

			rules: {
				reason: [ v => !!v || 'Please select an option above' ],
			}
		}
	},

	computed: {
		...mapState({
			appt: state => state.schedule.currentAppt,
			isAdmin: state => state.dashboard.currentUser.is_staff_session,
		}),
	},

	methods: {
		dismiss()
		{
			this.$emit('closed');
		},

		resume()
		{
			this.showWarning = false;
		},

		async submit()
		{
			if (!this.$refs.form.validate())
				return;

			this.saving = true;

			try
			{
				var params = {
					cancel_date: moment().toISOString(),
					maint_plan_id: this.appt.maintenance_plan_id,
					reason: this.reason,
					notes: this.otherReason,
				};

				await api.post('/appointments/cancel_schedule', params);

				this.$router.push('/schedule');
			}
			finally
			{
				this.saving = false;
			}
		}
	},

	mounted()
	{
		// get arrival date
		var arrival = moment(this.appt.arrival_window_start_time).startOf('day');

		// get difference between arrival date and today in hours
		var diff = moment.duration(arrival.diff(moment())).asHours();

		// if diff <= 24 then show 100% warning
		if (diff <= 24)
		{
			this.showWarning = true;
			this.hours = 24;
			this.surcharge = '100%';
		}
		// otherwise if within 48 hours..
		else if (diff <= 48)
		{
			this.showWarning = true;
			this.hours = 48;
			this.surcharge = '50%';
		}
	}
}
</script>