<template>
	<textarea 
		:type="type"
		class="obe-text-field obe-text-field--textarea"
		:disabled="disabled"
		:placeholder="placeholder"
		:value="value"
		@input="$emit('input', $event.target.value)">
	</textarea>
</template>

<script>
export default {
	props: {
		type: { type: String, default: 'text' },
		disabled: { type: Boolean, default: false },
		placeholder: { type: String, default: '' },
		value: { default: '' },
	}
}
</script>