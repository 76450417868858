<template>
	<div>
		<div class="obe-db-heading">
			<span class="obe-db-heading__icon"><i class="obe-i-invoice"></i></span>
			<span class="obe-db-heading__text">Invoices</span>
		</div>

		<obe-form ref="form">
			<layout row wrap>
				<flex md6>
					<form-field type="vert" label="From">
						<date-picker :value="from" @input="updateFromDate"></date-picker>
					</form-field>
				</flex>
				<flex md6>
					<form-field type="vert" label="To">
						<date-picker :value="to" @input="updateToDate"></date-picker>
					</form-field>
				</flex>
			</layout>
		</obe-form>

		<div class="obe-db-invoices">
			<div v-if="loading" class="obe-db-subheading">
				Loading invoices...
			</div>

			<template v-else-if="currentInvoiceList.length > 0">
				<invoice-item v-for="inv in currentInvoiceList" :key="inv.id" :invoice="inv"></invoice-item>
				<statement></statement>
			</template>

			<div v-else class="obe-db-subheading">No invoices found between {{ from }} - {{ to }}</div>
		</div>
	</div>
</template>

<script>
import moment from 'moment';
import { mapState, mapGetters } from 'vuex';

import InvoiceItem from './InvoiceItem.vue';
import Statement from './Statement.vue';

const DATE_FORMAT = 'YYYY-MM-DD';

export default {
	components: { InvoiceItem, Statement },

	data()
	{
		return {
			loading: false,
			from: moment().startOf('month').format(DATE_FORMAT),
			to: moment().endOf('month').format(DATE_FORMAT),
		}
	},

	computed: {
		...mapGetters(['currentInvoiceList']),
	},

	watch: {
		from()
		{
			this.getInvoices();
		},

		to()
		{
			this.getInvoices();
		}
	},

	methods: {
		updateFromDate(date)
		{
			var from = moment(date, DATE_FORMAT);
			var to = moment(this.to, DATE_FORMAT);
			
			// if the incoming date is actually after the end date then 
			// set the end date to the incoming date and replace the start date with the current end date
			if (from.isAfter(to))
			{
				this.from = this.to;
				this.to = date;
			}
			else
			{
				this.from = date;
			}
		},

		updateToDate(date)
		{
			var to = moment(date, DATE_FORMAT);
			var from = moment(this.from, DATE_FORMAT);

			// like above, if to is before from then swap them
			if (to.isBefore(from))
			{
				this.from = date;
				this.to = this.from;
			}
			else
			{
				this.to = date;
			}
		},

		async getInvoices()
		{
			var params = {
				from_date: moment(this.from, DATE_FORMAT).toISOString(),
				to_date: moment(this.to, DATE_FORMAT).toISOString(),
			};

			this.loading = true;
			
			try
			{
				await this.$store.dispatch('paymentMethods.getInvoices', params);
			}
			finally
			{
				this.loading = false;
			}
		}
	},

	mounted()
	{
		this.getInvoices();
	}
}
</script>