<template>

	<div class="obe">
		<div class="top-promotion-banner" v-if="this.showOfferBanner">SPECIAL OFFER - GET UP TO 25% OFF REGULAR SERVICES  |  <a  class="top-promotion-banner" href="#"  @click.prevent="newBooking = true">BOOK NOW</a></div>

		<div class="obe-dashboard" :class="{ 'obe-dashboard--collapsed': collapsed }">
			<div class="obe-mobile-menubar">
				<button v-if="currentUser != null"  type="button" class="menubar-toggle" aria-label="Toggle Menu" @click.prevent="mobileMenu = !mobileMenu">
					<span class="bar"></span>
					<span class="bar"></span>
					<span class="bar"></span>
				</button>
			</div>

			<sidebar :show-mobile-menu.sync="mobileMenu"></sidebar>

			<div class="obe-dashboard-main">

				<div class="obe-db-top">
					<div class="obe-db-top__title">{{ $route.meta.title }}</div>
					<template v-if="currentUser != null">
						<component v-if="$route.meta.topButtons" :is="$route.meta.topButtons"></component>
						<ul v-else class="obe-db-top__buttons">
							<li><a href="#" class="obe-db-top__btn-orange obe-db-top__btn-solid-green" @click.prevent="newBooking = true">BOOK NOW</a></li>
							<li class="relative">
								<div v-if="productLoyaltyCode == 'D'" class="pdt-off" >25% OFF</div>
								<router-link to="/catalog"  class="obe-db-top__btn-green"><b>SHOP PRODUCTS</b></router-link>

							</li>
						</ul>
					</template>
					<!--
					<ul class="obe-db-top__buttons" v-if="currentUser != null">
						<li><a href="#" class="obe-db-top__btn-orange">Book new cleaning</a></li>
						<li><a href="#" class="obe-db-top__btn-blue">Leave feedback</a></li>
						<li><router-link to="/catalog" class="obe-db-top__btn-green">Shop products</router-link></li>
					</ul>
					-->
				</div>

				<div class="obe-dashboard-main__content">
					<router-view></router-view>
				</div>
			</div>
		</div>

		<modal :show.sync="newBooking" max-width="800px">
		<new-booking-popup v-if="newBooking" />
		</modal>

		<alert-dialog ref="dialog" />
		<unsaved-changes-warning ref="unsaved" />
	</div>
</template>

<script>
import { mapState } from 'vuex';

import alerts from 'obe/shared/alerts';
import AlertDialog from 'obe/shared/comp/AlertDialog.vue';

import types from '../store/types';

import Sidebar from './Sidebar.vue';
import NewBookingPopup from './NewBookingPopup.vue';
import UnsavedChangesWarning from './UnsavedChangesWarning.vue';
import api from 'obe/dashboard/api';

export default {
	components: { Sidebar, NewBookingPopup, AlertDialog, UnsavedChangesWarning },

	provide() 
	{
		return {
			'$showNewBooking': this.showNewBooking,
			'$showUnsavedWarning': this.showUnsavedWarning,
		}
	},


	data()
	{
		return {
			newBooking: false,
			mobileMenu: false,
			loyaltyCode: null,
			productLoyaltyCode: null,
			showOfferBanner: false,
		}
	},

	computed: {

		...mapState({
			currentUser: state => state.dashboard.currentUser,
			collapsed: state => state.dashboard.collapsed,
			// loyaltyCode: state => this.$store.state.schedule.loyaltyCode
			
		}),
		// loyaltyCode(){

		// }
		// ...mapState(['loyaltyCode']),
		
	},
	
	methods: {
		async testAlert(event)
		{
			var res = await alerts.confirm('Testing alert dialog') ? 'Agreed' : 'Cancelled';
		},

		showNewBooking(flag)
		{
			this.newBooking = flag;
		},

		showUnsavedWarning()
		{
			return this.$refs.unsaved.display();
		},
		async getLoyaltyCode()
		{
			var result = await api.get('/appointments');
			this.loyaltyCode = result.data.loyalty_code;
			this.productLoyaltyCode = result.data.product_loyalty_code;
			this.showOfferBanner = result.data.show_offer_banner;

			// console.log(this.loyaltyCode, result)
			this.$store.commit(types.SET_PRODUCT_LOYALTY_CODE, result.data.product_loyalty_code);
		}
	},

	mounted()
	{
		alerts.setDialogComponent(this.$refs.dialog);
		this.getLoyaltyCode()
	}
}
</script>
