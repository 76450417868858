<template>
		<fade-transition>
			<div v-if="active" class="obe-alert-modal">
				<div class="obe-alert-modal__content">
					<card>
						<div class="obe-error-popup">
							<div :class="`obe-error-popup__icon obe-error-popup__icon-${this.type}`"><span :class="icon"></span></div>
							<div class="obe-error-popup__msg">{{ msg }}</div>

							<ul class="obe-button-menu obe-button-menu--horz">
								<li><push-button wide ref="agreeBtn" @click="agree">Ok</push-button></li>
								<li v-if="isConfirm"><push-button wide color="gray" @click="cancel">Cancel</push-button></li>
							</ul>
						</div>
					</card>
				</div>
			</div>
		</fade-transition>
</template>

<script>

const iconClasses = {
	info: 'obe-i-attention',
	success: 'obe-i-verified',
	warning: 'obe-i-attention',
	error: 'obe-i-attention',
}

export default {

	data()
	{
		return {
			msg: '',
			isConfirm: false,
			active: false,
			type: 'info',
		}
	},

	computed: {
		icon()
		{
			return iconClasses[this.type] || iconClasses.info;
		}
	},

	methods: {
		display(msg, type, confirm)
		{
			this.msg = msg;
			this.active = true;
			this.isConfirm = confirm;
			this.type = type;

			this.$nextTick().then(() => {
				this.$refs.agreeBtn.$el.focus();
			});

			return new Promise((resolve, reject) => {
				this.resolve = resolve;
				this.reject = reject;
			});
		},

		show(msg, type)
		{
			return this.display(msg, type || 'info', false);
		},

		confirm(msg, type)
		{
			return this.display(msg, type || 'info', true);
		},

		agree()
		{
			this.resolve(true);
			this.active = false;
		},

		cancel()
		{
			this.resolve(false);
			this.active = false;
		},
	}
}
</script>

