<template>
	<div class="obe-product-list__col">
		<div :class="classes">
			<!-- PRODUCT IMAGE -->
			<div class="obe-product__image" :style="bg"></div>

			<!-- PRODUCT INFO -->
			<div class="obe-product__info">
				<div class="obe-product__name">{{ product.name }}</div>
				<div class="obe-product__desc">{{ product.description }}</div>				
			</div>
			<div class="obe-product__price-info">
				<span class="obe-product__price" :class="{ 'obe-product__original_price': productLoyaltyCode=='D' , 'obe-product__right' : !productLoyaltyCode }">{{ product.price | currency }}</span>
				<span class="obe-product__price obe-product__right" v-if="productLoyaltyCode == 'D'">{{ findDiscountedPrice(product.price) | currency }}</span>
			</div>

			<!-- ADD PRODUCT TO CART -->
			<div class="obe-product__order" v-if="quantity == 0">
				<button class="obe-product__add-btn" @click.prevent="updateQty(1)">Add To Cart</button>
			</div>

			<!-- MODIFY PRODUCT QUANTITY IN CART -->
			<div class="obe-product__qty-fields" v-else>
				<a href="#" class="obe-product__qty-action" @click.prevent="updateQty(-1)">
					<span class="obe-sr-only">Remove Quantity</span>
					<span class="obe-i-minus"></span>
				</a>

				<span class="obe-product__qty">{{ quantity }}</span>

				<a href="#" class="obe-product__qty-action"  @click.prevent="updateQty(1)">
					<span class="obe-sr-only">Add Quantity</span>
					<span class="obe-i-plus"></span>
				</a>
			</div>
		</div>
	</div>
</template>

<script>

import { mapState } from 'vuex';
import _ from 'lodash/math';
import filters from 'obe/shared/filters';
import types from '../store/types';

export default {
	mixins: [filters],

	props: {
		product: { type: Object, required: true },
	},

	computed: {
		...mapState({
			cart: state => state.catalog.cart,
			productLoyaltyCode: state => state.dashboard.productLoyaltyCode,
		}),

		bg()
		{
			return { 'background-image': `url(${this.product.image_url})` }
		},

		classes()
		{
			var classes = ['obe-product'];

			if (this.quantity > 0)
				classes.push('obe-product--in-cart');

			return classes;
		},

		quantity: {
			get()
			{
				return this.cart[this.product.id] || 0;
			},

			set(val)
			{
				this.$store.commit(types.CART_UPDATE_PRODUCT_QTY, { id: this.product.id, quantity: val });
			}
		}
	},

	methods: {
		updateQty(inc)
		{
			// adjust qty
			var qty = this.quantity + inc;

			// qty can never be less than 0
			if (qty <= 0)
				qty = 0;

			if (qty <= 0)
				this.$store.commit(types.CART_REMOVE_PRODUCT, { id: this.product.id });
			else
				this.quantity = qty;
		},
		findDiscountedPrice(price) {
			this.discount = _.round(price * 0.25, 2);
			return price - this.discount;
		}
	},
}
</script>
