<template>
	<div>
		<obe-form ref="form">
			<!-- property size sliders -->
			<layout row wrap>
				<!-- "room" sliders -->
				<flex md8>
					<div class="obe-box">
						<layout row wrap>
							<flex md6>
								<slider-field 
								:disabled="enableInput()"
									slider-label="Bedrooms"
									type="vert" 
									:min="1"
									:max="10" 
									:value="propertyData.bedrooms"
									@input="setFieldAndOptions('bedrooms', $event)">
								</slider-field>
							</flex>
							<flex md6>
								<slider-field 
									type="vert"
									slider-label="Bathrooms" 
										:disabled="enableInput()"
									:value="propertyData.bathrooms"
									:items="roomOptions.bathrooms"
									@input="setFieldAndOptions('bathrooms', $event)">
								</slider-field>
							</flex>
							<flex md6>
								<slider-field 
									type="vert"
									slider-label="Kitchens" 
										:disabled="enableInput()"
									:value="propertyData.kitchens"
									@input="setField('kitchens', $event)"
									:items="roomOptions.kitchens">
								</slider-field>
							</flex>
							<flex md6>
								<slider-field 
									type="vert"
									slider-label="Extra Rooms" 
										:disabled="enableInput()"
									:value="propertyData.extra_rooms"
									@input="setField('extra_rooms', $event)"
									:items="roomOptions.extra_rooms">
								</slider-field>
							</flex>
						</layout>
					</div>
				</flex>

				<!-- home size sliders -->
				<flex md4>
					<div class="obe-box obe-box--no-border">
						<slider-field 
							type="vert"
								:disabled="enableInput()"
							slider-label="Size (sq.ft.)" 
							:value="propertyData.size_sq_ft"
							@input="setField('size_sq_ft', $event)"
							:items="roomOptions.size_sq_ft">
						</slider-field>

						<slider-field 
							type="vert"
								:disabled="enableInput()"
							slider-label="Levels" 
							:value="propertyData.levels"
							@input="setField('levels', $event)"
							:items="roomOptions.levels">
						</slider-field>
					</div>
				</flex>
			</layout>

			<layout row wrap class="obe-pt-6">
				<!-- hard surface floors / pets -->
				<flex md5>
					<div class="obe-form-field">
						<layout row align="center" stack="md">
							<flex><span class="obe-label-text">Hard surface floors in bedrooms</span></flex>
							<flex><toggle :value="propertyData.hard_surface_floors_in_bedrooms == 'Yes'" 	:disabled="enableInput()" @input="setField('hard_surface_floors_in_bedrooms', $event ? 'Yes' : 'No')"></toggle></flex>
						</layout>
					</div>
				</flex>
				<flex md3>
					<div class="obe-form-field">
						<layout row align="center" justify="end" stack="md">
							<flex><span class="obe-label-text">Pets</span></flex>
							<flex><toggle :value="propertyData.pets == 'Yes'" 	:disabled="enableInput()" @input="setField('pets', $event ? 'Yes' : 'No')"></toggle></flex>
						</layout>
					</div>
				</flex>
				<flex md4 v-if="propertyData.pets == 'Yes'">
					<text-field
						hide-label
						placeholder="Pet's name"
						:disabled="enableInput()"
						:value="propertyData.pet_s_name"
						@input="setField('pet_s_name', $event)">
					</text-field>
				</flex>
			</layout>

			<text-field
				type="vert"
				label="Property Notes"
				:value="propertyData.special_requirements"
				@input="setField('special_requirements', $event)"
				:maxlength="maxCharacterLimit"
			>
			</text-field>
			<div class="ml-2 help-text-cleaning-notes">Characters left: {{ this.charactersLeft }}</div>

		</obe-form>
	</div>
</template>

<script>
import _ from 'lodash/string';
import { mapGetters, mapState } from 'vuex';

import types from '../store/types';

export default {
	data()
	{
		return {
			/*propertyData: {
				numRooms: 1,
				bathrooms: 1,
				kitchens: 1,
				extraRooms: 0,
				sqftSize: 0,
				levels: 1,
				hardsurfaceFloors: false,
				pets: false,
				petName: '',
				specialReqs: '',
			},*/

			roomOptions: {
			},
			maxCharacterLimit: 300,
			charactersLeft: 300,
		}
	},

	computed: Object.assign({}, mapState({
		propertyData: state => state.contacts.currentAddress,
		appRoomOptions: state => state.dashboard.roomOptions,
	}), mapGetters([
		'getBookingOptionsByRoom',
	])),


	methods: {
		setField(field, val)
		{
			if (field === 'special_requirements') {
				const valueLength = val.length;

				this.charactersLeft = this.maxCharacterLimit - valueLength;
			}

			this.$store.commit(types.UPDATE_CURRENT_ADDRESS, { [field]: val });
		},

		// update the address property info and re-calculate options
		setFieldAndOptions(field, val)
		{
			this.setField(field, val);
			this.$nextTick(() => this.updateOptions(true));
		},
enableInput(e){
	
	return this.propertyData.hasOwnProperty('id') && this.propertyData.id !=0
},
		// if fixData = true then it will update the state data 
		updateOptions(fixData)
		{
			var fields = ['bathrooms', 'kitchens', 'extra_rooms', 'size_sq_ft', 'levels'];
			var propertyData = { };

			// map address fields to room option fields
			var fieldMap = {
				bathrooms: 'bathrooms',
				kitchens: 'kitchens',
				extra_rooms: 'extra_rooms',
				size_sq_ft: 'sqft_size',
				levels: 'levels',
			};

			for(var key in fieldMap)
			{
				var pos;
				var options = this.getBookingOptionsByRoom(fieldMap[key], this.propertyData.bedrooms);
				var values = options.map(o => o.option_name);

				// using a manual find because indexOf uses strict equality which messes with how the room options are stored
				if (fixData && !values.find(x => x == this.propertyData[key]))
					propertyData[key] = values[0];

				// HACK: if key is 'size_sq_ft' just swap it with sqft_size so that we can look up the correct sqft options
				this.$set(this.roomOptions, key, values);
			}

			if (fixData)
				this.$store.commit(types.UPDATE_CURRENT_ADDRESS, propertyData);
		},
		/*updateOptions()
		{
			var fields = ['bathrooms', 'kitchens', 'extra_rooms', 'sqft_size', 'levels'];
			var roomOptions = {};
			var propertyData = {};

			for(var f of fields)
			{
				var pos;
				var key = _.camelCase(f);
				var options = this.getBookingOptionsByRoom(f, this.propertyData.numRooms);
				var lists = {
					values: options.map(opt => opt.id),
					labels: options.map(opt => opt.option_name),
				};

				pos = lists.values.indexOf(this.propertyData[key]);

				if (lists.labels.indexOf(lists.labels[pos]) < 0)
					this.$set(this.propertyData, key, lists.values[0]);

				this.$set(this.roomOptions, key, lists);
			}
		}*/
	},

	mounted()
	{	
		this.updateOptions(true);

		if (this.propertyData && this.propertyData.special_requirements) {
			this.charactersLeft = this.maxCharacterLimit - this.propertyData.special_requirements.length;
		}
	},
}
</script>
