<template>
	<div class="obe-account-form">
		<obe-form ref="form">
			<layout row wrap reverse stack="md">
				<!-- PASSWORD SECURITY INDICATOR -->
				<flex md6>
					<password-security v-model="passwordValid" :password="newPassword" />
				</flex>

				<!-- PASSWORD ENTRY -->
				<flex md6>
					<text-field
						type="vert"
						label="New password"
						input-type="password"
						:rules="rules.newPassword"
						v-model="newPassword">
					</text-field>

					<text-field
						type="vert"
						label="Confirm password"
						input-type="password"
						:rules="rules.confPassword"
						v-model="confPassword">
					</text-field>
				</flex>

			</layout>

			<!-- CAPTCHA -->
			<layout row wrap>
				<flex md6 mdpush6>
					<div class="obe-db-subheading">Please complete the captcha below to proceed</div>
				</flex>
				<flex md6 class="obe-mb-4">
					<div class="obe-g-recaptcha" ref="recaptcha" data-size="compact"></div>
				</flex>
				<flex md6 class="obe-text--align-right">
					<ul class="obe-button-menu">
						<li><push-button color="orange" @click="save" :saving="saving">Update password</push-button></li>
					</ul>
				</flex>
			</layout>
		</obe-form>
	</div>
</template>

<script>
import alerts from 'obe/shared/alerts';
import validate from 'obe/shared/validators';
import api from 'obe/dashboard/api';
import { appConfig } from 'obe/config';


export default {
	data()
	{
		return {
			saving: false,
			newPassword: '',
			confPassword: '',
			passwordValid: false,

			rules: {
				newPassword: [
					v => !!v || 'Please enter your new password',
					v => this.passwordValid || 'Password does not meet the specified requirements',
				],

				confPassword: [
					v => !!v || 'Please re-enter your new password',
					v => v == this.newPassword || 'Passwords do not match!',
				]
			}
		}
	},

	methods: {
		async save()
		{
			var req, recaptcha = grecaptcha.getResponse();

			if (!this.$refs.form.validate())
				return;

			if (!recaptcha)
			{
				alerts.show('Please complete the recaptcha', '', 'warning');
				return;
			}
			
			req = {
				passwords: { password: this.newPassword, password_confirmation: this.confPassword },
				recaptcha_response: recaptcha,
			};

			this.saving = true;

			try
			{
				await api.post('/account/password', req);
				alerts.show('Password updated', 'Success', 'success');

				this.newPassword = '';
				this.confPassword = '';
			}
			finally
			{
				grecaptcha.reset();
				this.saving = false;
			}
		}
	},

	mounted()
	{
		var rc = this.$refs.recaptcha;
		var key = process.env.VUE_APP_RECAPTCHA_SITE_KEY;

		grecaptcha.render(rc, { sitekey: key });
	},
}
</script>
