<template>
	<div class="obe-modal" :class="{ 'obe-modal--visible': show }">
		<div class="obe-modal__content" :class="{ 'obe-modal__content--full-width': fullWidth }" :style="{ 'max-width': fullWidth ? 'none' : maxWidth }">
			<div ref="content">
				<slot></slot>
			</div>
			
			<a v-if="$breakpoints.mdAndUp && showClose" href="#" class="obe-modal__close-btn" @click.prevent="dismiss">&times;</a>
			
			<push-button v-if="$breakpoints.mdAndDown && showClose" block outline color="grey" @click="dismiss">Close</push-button>

			
			<!--
			<div class="obe-modal__close" v-if="showClose">
				<push-button small color="white" @click="dismiss">Close</push-button>
			</div>
			-->
		</div>
	</div>
</template>

<script>
export default {
	props: {
		// display a close button?
		showClose: { type: Boolean, default: true },

		// display the modal?
		show: { type: Boolean, default: false },

		// full width?
		fullWidth: { type: Boolean, default: false },

		// dismiss the modal if the user clicks outside of the main content slot?
		dismissOutsideClick: { type: Boolean, default: true },

		// max modal width
		maxWidth: { type: [String, Number], default: '1350px' },
	},

	watch: {
		show()
		{
			if (this.show)
				document.body.classList.add('obe-modal-shown');
			else
				document.body.classList.remove('obe-modal-shown');

			//document.body.classList.toggle('obe-modal-shown', this.show);
		}
	},

	methods: {
		dismiss()
		{
			this.$emit('update:show', false);
		},
	},

	mounted()
	{
		// add outside click handler
		this.onOutsideClick = event => {
			if (!this.$refs.content.contains(event.target))
			{
				this.dismiss();
				event.preventDefault();
				event.stopPropagation();
				return false;
			}
		};

		if (this.dismissOutsideClick)
			this.$el.addEventListener('mousedown touchstart', this.onOutsideClick);
	},

	beforeDestroy()
	{
		if (this.dismissOutsideClick)
			this.$el.removeEventListener('mousedown touchstart', this.onOutsideClick);
	}
}
</script>
