<template>
	<form-field v-bind="formFieldAttrs" :validate-value="value">
		<div class="obe-radio-group" :class="{ 'obe-radio-group--centered': centered }">
			<radio
				v-for="item in items" :key="item.value"
				:class="{ 'obe-radio--inline': inline }"
				:label="item[itemText]"
				:value="item[itemValue]"
				:data="value"
				@input="$emit('input', $event)">
			</radio>
		</div>
	</form-field>
</template>

<script>
import formFieldProps from '../mixins/form-field';

export default {
	mixins: [formFieldProps],

	props: {
		inline: { type: Boolean, default: false },
		value: { default: 0 },
		disabled: { type: Boolean, default: false },
		centered: { type: Boolean, default: false },

		items: { type: Array, default: () => ([]) },
		itemText: { type: String, default: 'text' },
		itemValue: { type: String, default: 'value' },
	}
}
</script>
