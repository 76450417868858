<template>
	<div>
		<template v-if="loading">
			<loading-spinner>Loading...</loading-spinner>
		</template>

		<template v-else>
			<template v-if="apptOptions.length > 0">
				<!-- CATALOG HEADER -->
				<div class="obe-catalog-top">

					<!-- show this interface if we're starting a new product order -->
					<template v-if="currentOrder == null">
						<div class="obe-catalog-date-header">
							<div class="header-item">
								<div class="obe-catalog-header">
									<div class="obe-catalog-header__top">Choose a product</div>
									<div class="obe-catalog-header__bottom">
										and our team will deliver it to you during your next cleaning on: 
										<!-- <span class="obe-catalog-header__date">
											<span class="obe-i-calendar2"></span> {{ nextAppt.delivery_date | dateFormat('MMM Do, YYYY') }}
										</span> -->
									</div>
								</div>
							</div>
							<div class="header-item">
								<dropdown :items="apptOptions" v-model="apptId" @change="startOrderAppt"></dropdown>
							</div>
						</div>
					</template>

					<!-- show this interface if we're modifying an existing order -->
					<div v-else class="obe-catalog-header">
						<div class="obe-catalog-header__top">Modify existing product order</div>
						<div class="obe-catalog-header__bottom">
							You are updating products being ordered for your appointment scheduled for: 
							<span class="obe-catalog-header__date">
								<span class="obe-i-calendar2"></span> {{ nextAppt.delivery_date | dateFormat('MMM Do, YYYY') }}
							</span>
						</div>
						<!--
						<div class="obe-catalog-header__actions">
							<push-button outline small @click="startNewOrder">Start New Order</push-button>
						</div>
						-->
					</div>

					<div class="obe-catalog-minicart">
						<router-link to="/catalog/checkout" class="obe-catalog-minicart__icon"><span class="obe-i-shopping-cart"></span></router-link>
						<span class="obe-catalog-minicart__qty">{{ cartTotal }}</span>
					</div>
				</div>

				<!-- PRODUCTS LISTED BY CATEGORY -->
				<template v-if="nextAppt">
					<div v-for="cat in productCategoryList" :key="cat.id">
						<div class="obe-db-subheading">{{ cat.name }}</div>
						<div class="obe-product-list">
							<product-item v-for="p in getProductsForCategory(cat.id)" :key="p.id" :product="p"></product-item>
						</div>
					</div>
				</template>

				<!-- BUTTONS -->
				<!-- only show this button if this is a new order -->
				<ul class="obe-button-menu obe-button-menu--horz" v-if="apptId && cartTotal > 0 && (currentOrder == null || $breakpoints.mdAndDown)">
					<li><push-button wide color="orange" to="/catalog/checkout">Continue</push-button></li>
				</ul>
			</template>

			<template v-else>
				<div class="obe-db-subheading">You have no upcoming appointments</div>
				<ul class="obe-button-menu obe-button-menu--left">
					<li><push-button large wide outline color="green" open="https://aspenclean.com/collections/all">Order online</push-button></li>
					<li><push-button large wide outline color="green" open="https://aspenclean.com/pages/store-locator">Find a retailer</push-button></li>
				</ul>
			</template>
		</template>
	</div>
</template>

<script>
import moment from 'moment';
import { mapState, mapGetters } from 'vuex';

import store from 'obe/dashboard/store';
import filters from 'obe/shared/filters';
import alerts from 'obe/shared/alerts';

import types from '../store/types';

import ProductItem from './ProductItem.vue';

export default {
	components: { ProductItem },
	mixins: [filters],

	data()
	{
		return {
			loading: true,
			apptId: '',
		}
	},

	computed: {
		...mapGetters(['productCategoryList', 'getProductsForCategory', 'mainFranchiseList', 'appointmentList', 'getFranchiseByTerritoryId']),
		...mapState({ 
			cart: state => state.catalog.cart,
			nextAppt: state => state.catalog.nextAppt,
			currentOrder: state => state.catalog.currentOrder,
			noAppts: state => state.catalog.noAppointments,
			apptsLoaded: state => state.schedule.apptsLoaded,
			appts: state => state.schedule.appts,
			//franchises: state => state.dashboard.franchises,
			//franchisesByTerritory: state => state.dashboard.franchisesByTerritory,
		}),

		apptOptions()
		{
			return this.appointmentList.filter(a => this.canEditAppt(a)).map(a => ({
				text: moment(a.arrival_window_start_time).format('MMM Do, YYYY'),
				value: a.id,
			}));
		},

		cartTotal()
		{
			var total = 0;
			for(var key in this.cart)
				total += this.cart[key];

			return total;
		}
	},

	/*watch: {
		apptId(newVal)
		{
			this.startOrderAppt();
		}
	},*/

	methods: {
		canEditAppt(appt)
		{
			var arrival = moment(appt.arrival_window_start_time);
			var today = moment();

			// can't add products if appointment is past the current date
			if (today.isSameOrAfter(arrival))
				return false;

			// can only add products if the status is booked (aka status == 'none')
			//if (['none', 'new', 'accepted', 'scheduled'].indexOf(appt.status.toLowerCase()) == -1)
			if (appt.status.toLowerCase() != 'none')
				return false;

			return true;
		},

		async getNextAppt()
		{
			this.loading = true;

			try
			{
				await this.$store.dispatch('catalog.getNextAppt');
			}
			finally
			{
				this.loading = false;
			}
		},

		async getPrevOrder(id)
		{
			this.loading = true;

			try
			{
				await this.$store.dispatch('catalog.getPreviousOrder', { id });
			}
			finally
			{
				this.loading = false;
			}
		},

		async getProducts()
		{
			this.loading = true;
			
			try
			{
				await this.$store.dispatch('catalog.getProducts');
			}
			finally
			{
				this.loading = false;
			}
		},

		async initData()
		{
			// load appointmetns if the user hasn't been to the schedule screen yet
			if (!this.apptsLoaded)
				await this.$store.dispatch('schedule.getAppointmentList');

			// load previous order data
			if (this.$route.query.prevOrder)
			{
				await this.getPrevOrder(this.$route.query.prevOrder);
			}
			/*// otherwise start new order for next upcoming appointment
			else if (this.appointmentList.length > 0)
			{
				this.apptId = this.appointmentList[0].id;
			}*/

			// get product catalog
			if (this.nextAppt != null)
			{
				this.apptId = this.nextAppt.id;
				await this.getProducts();
			}

			this.loading = false;
		},

		async startNewOrder()
		{
			if (!await alerts.confirm('You will lose any changes to this order', 'Are you sure?', 'question'))
				return;

			this.$router.replace('/catalog');
			this.initData();
		},

		async startOrderAppt()
		{
			var appt, franchise;

			// make sure appt id is valid
			appt = this.appts[this.apptId];
			if (!appt)
			{
				alerts.show(`Invalid appointment id: ${this.apptId}`, 'error')
				return;
			}

			// get service franchise info
			franchise = this.getFranchiseByTerritoryId(appt.parent_record.service_territory_id);
			if (!franchise)
			{
				alerts.show(`Unable to find franchise based on service territory id: ${appt.parent_record.service_territory_id}`, 'error');
				return;
			}

			// start a new product order in the state
			this.$store.dispatch('catalog.startNewOrder', {
				franchise,
				apptId: this.apptId,
				workOrderId: appt.parent_record_id, 
				deliveryDate: appt.arrival_window_start_time,
				postalCode: appt.parent_record.postal_code,
			});

			await this.getProducts();
		}
	},

	mounted()
	{
		this.initData();
	},

	beforeRouteEnter(to, from, next)
	{
		var catalogRoutes = ['db-catalog', 'db-catalog-checkout', 'db-catalog-order-history'];

		if (catalogRoutes.indexOf(from.name) == -1)
			store.commit('CLEAR_CATALOG_ORDER');

		next();
	}

	/*beforeRouteLeave(to, from, next)
	{
		var catalogRoutes = ['db-catalog', 'db-catalog-checkout', 'db-catalog-order-history'];

		console.log(`to: ${to.name}`);

		if (catalogRoutes.indexOf(to.name) == -1)
			this.$store.commit('CLEAR_CATALOG_ORDER');

		next();
	}*/
}
</script>