<template>
	<block-section>
		<template v-slot:title>
			<div class="products-title">
				<div class="title-main">
					<div class="obe-db-subheading obe-db-subheading--black obe-mb-0">Products ordered with this cleaning appointment</div>
				</div>

				<div v-if="canEdit" class="title-buttons">
					<push-button wide outline color="grey" :to="`/catalog?prevOrder=${order.id}`">Edit order</push-button>
				</div>
			</div>
		</template>

		<template v-if="loading">
			Loading...
		</template>
		
		<div v-else-if="order && apptProductList.length > 0" class="obe-appt-product-list">
			<div v-for="p in apptProductList" :key="p.id" class="obe-appt-product">
				<div class="obe-appt-product__image-wrapper">
					<div class="obe-appt-product__image">
						<img :src="p.image_url">
						<span class="obe-appt-product__qty">{{ p.quantity }}</span>
					</div>
				</div>
				<div class="obe-appt-product__title">
					{{ p.name }}
				</div>
			</div>
		</div>

		<div v-else  class="obe-appt-product-list">
			<div class="obe-appt-product-list__empty">
				No products ordered for this appointment
			</div>
		</div>
	</block-section>
</template>

<script>
import moment from 'moment';
import { mapState, mapGetters } from 'vuex';

import api from 'obe/dashboard/api';

import types from '../store/types';

import BlockSection from './BlockSection.vue';

export default {
	components: { BlockSection },

	data()
	{
		return {
			loading: false,
			//products: [],
			//order: null,
		}
	},

	computed: {
		...mapGetters(['apptProductList']),
		...mapState({
			appt: state => state.schedule.currentAppt,
			order: state => state.schedule.apptProductOrder,
		}),

		// returns true if the status is in the valid array or if today's date is before the arrival time
		canEdit()
		{
			var valid = ['none', 'new', 'accepted', 'booked'];
			var status = this.appt.status.trim().toLowerCase();
			var dateOf = moment(this.appt.arrival_window_start_time);

			return this.order != null && valid.indexOf(status) >= 0 && moment().isBefore(dateOf);
		}
	},

	methods: {

		// look for products ordered with the current appointment
		async refreshProducts()
		{
			this.loading = true;

			try
			{
				await this.$store.dispatch('schedule.fetchApptProducts');
			}
			finally
			{
				this.loading = false;
			}
		}
	},

	mounted()
	{
		this.refreshProducts();
	}
}
</script>

<style scoped>
.products-title
{
	align-items: center;
	display: flex;
	margin-bottom: 50px;
}

.products-title .title-buttons
{
	margin-left: 123px;
}

@media screen and (max-width: 575px)
{
	.products-title
	{
		display: block;
		margin-bottom: 20px;
	}

	.products-title .title-buttons
	{
		margin: 20px 0 20px 0;
	}
}
</style>
