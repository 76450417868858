<template>
	<div :class="classes" @click.prevent="toggle">
		<input type="checkbox" :checked="value" :disabled="disabled" @input="$emit('input', $event.target.checked)">
		<div class="obe-toggle__handle">{{ value ? onText : offText }}</div>
	</div>
</template>

<script>
export default {
	props: {
		value: { type: Boolean, default: false },
		offText: { type: String, default: 'No' },
		onText:  { type: String, default: 'Yes' },
		disabled: { type: Boolean, default: false },
	},

	computed: {
		classes()
		{
			var classes = ['obe-toggle'];

			if (this.disabled)
				classes.push('obe-toggle--disabled');
			
			if (this.value)
				classes.push('obe-toggle--on');

			return classes;
		}
	},

	methods: {
		toggle()
		{
			if (!this.disabled)
				this.$emit('input', !this.value);
		}
	}
}
</script>