<template>
	<div class="obe-db-appt-items">
		<appointment-item
			top-detail
			v-for="item in apptList"
			:key="item.appt.id"
			:item="item">
		</appointment-item>
		<div  v-if="!promoSectionisHidden && this.showOfferBanner" class="promo">
			<div class="promo-card-close" v-on:click="promoSectionisHidden = !promoSectionisHidden"><button></button></div>
			<div class="promo-card__title">Get up to 25% OFF</div>
			<div class="promo-card__detail">
				<div class="">Set up a regular cleaning schedule today and enjoy a clean house tomorrow!</div>
			</div>
			<div class="promo-card__button">
				<a href="#" @click.prevent="startNewBooking" class="promo-book-now">Book Now</a>
			</div>
		</div>
	</div>

</template>

<script>

import moment from 'moment';
import { mapGetters, mapState } from 'vuex';
import filters from 'obe/shared/filters';
import ApptList from '../appt-list';
import AppointmentItem from './AppointmentItem.vue';

export default {
	inject: ['$showNewBooking'],
	mixins: [ApptList, filters],
	components: { AppointmentItem },

	data()
	{
		return {
			dates: [],
			window: '',
			promoSectionisHidden: false,
		}
	},

	computed: {
		...mapState({
			appts: state => state.schedule.appts,
			loyaltyCode: state => state.schedule.loyaltyCode,
			showOfferBanner: state => state.schedule.showOfferBanner,
		}),

		apptList()
		{
			return this.filterApptsForMonth(this.calendarDate.month, this.calendarDate.year, true);
		}
	},

	methods: {
		startNewBooking()
		{	
			this.$showNewBooking(true);
		}
	}
}
</script>