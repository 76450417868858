
import router from 'obe/dashboard/router';

import Signup from './comp/Signup.vue';
import Complete from './comp/Complete.vue';

router.addRoutes([
	{
		name: 'db-signup',
		path: '/signup',
		props: true,
		meta: { noUserRequired: true },
		component: Signup
	},

	{
		name: 'db-signup-complete',
		path: '/complete_signup',
		props: true,
		meta: { noUserRequired: true },
		component: Complete
	},
]);
