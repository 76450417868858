<template>
	<block-section collapsible>
		<template v-slot:title>
			<div class="obe-db-heading">Staff Override Fields</div>
		</template>

		<obe-form ref="form">
			<form-field hide-label type="vert">
				<checkbox v-model="apply_overrides" label="Apply Overrides" />
			</form-field>

			<div v-if="apply_overrides">
				<layout row>
					<flex md12>
						<override-entry-select-field name="service_type" label="Override Service Type (Eg. Redo)" />
					</flex>
				</layout>

				<layout row v-if="currAppt.cleaning_frequency.toLowerCase() === 'one-time'">
					<flex md6>
						<override-duration-field name="initial_duration" label="Override Initial / One-time Duration (hh:mm, eg. 1:30)" />
					</flex>

					<flex md6>
						<override-entry-field name="initial_cost" label="Override Initial / One-time Cost (Eg. 195.00 or 195.50)" />
					</flex>
				</layout>

				<layout row v-else>
					<flex md6>
						<override-duration-field name="regular_duration" label="Override Regular Duration  (hh:mm, eg. 1:30)" />
					</flex>

					<flex md6>
						<override-entry-field name="regular_cost" label="Override Regular Cost (Eg. 195.00 or 195.50)" />
					</flex>
				</layout>
			</div>
		</obe-form>
	</block-section>
</template>

<script>
import { mapState } from 'vuex';

import types from '../store/types';
import BlockSection from './BlockSection.vue';
import OverrideEntryField from './OverrideEntryField.vue';
import OverrideDurationField from './OverrideDurationField.vue';
import OverrideEntrySelectField from './OverrideEntrySelectField.vue';

export default {
	components: { BlockSection, OverrideEntryField, OverrideDurationField, OverrideEntrySelectField },
	computed: {
		...mapState({
			baseOverrides: state => state.schedule.baseOverrides,
			currAppt: state => state.schedule.currentAppt,
		}),

		apply_overrides: { 
			get() { return this.baseOverrides.apply_overrides; },
			set(value) { 
				const emptyOverrides = {
					apply_overrides: false,
					service_type: null,
					initial_duration: null,
					initial_cost: null,
					regular_duration: null,
					regular_cost: null,
				};

				if (value) {
					this.$store.commit(types.SET_APPT_BASE_OVERRIDES, { apply_overrides: value });
				} else {
					this.$store.commit(types.SET_APPT_BASE_OVERRIDES, emptyOverrides);
				}
			},
		}
	}
}
</script>
