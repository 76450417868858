
import api from 'obe/dashboard/api';

import types from './types';

export default {
	async ['account.getProfileData']({ commit })
	{
		var result = await api.get('/account');
		commit(types.SET_PROFILE_DATA, result.data.account);
	},

	async ['account.updateProfileData']({ state })
	{
		await api.post('/account', { account: state.profile });
	}
}