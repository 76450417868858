<template>
	<div :class="classes">
		<template v-if="!hideLabel">
			<layout v-if="type == 'horz' && $breakpoints.mdAndUp" row wrap align="center">
				<flex v-bind="labelFlex">
					<slot name="label">
						<label class="obe-form-field__label">{{ label }}</label>
					</slot>
				</flex>
				<flex v-bind="fieldFlex">
					<slot></slot>

					<div class="obe-form-field__error" v-if="displayError">{{ displayError }}</div>
				</flex>
			</layout>

			<div v-else column>
				<slot name="label">
					<label class="obe-form-field__label">{{ label }}</label>
				</slot>

				<slot></slot>

				<div class="obe-form-field__error" v-if="displayError">{{ displayError }}</div>
			</div>
		</template>

		<template v-else>
			<slot></slot>

			<div class="obe-form-field__error" v-if="displayError">{{ displayError }}</div>
		</template>
	</div>
</template>

<script>
import formFieldProps from '../mixins/form-field';

export default {
	mixins: [formFieldProps],
	inject: ['$addToValidation','$removeFromValidation'],

	data()
	{
		return { 
			error: null,
		}
	},

	computed: {
		displayError()
		{
			if (this.hideValidationError)
				return null;
			else if (this.error != null)
				return this.error;
			else if (this.errorMsg != null)
				return this.errorMsg;
			else
				return null;
		},

		labelFlex()
		{
			return { xs12: true, [`md${this.labelCols}`]: true };
		},

		fieldFlex()
		{
			return { xs12: true, [`md${this.fieldCols}`]: true };
		},

		classes()
		{
			var classes = ['obe-form-field']; //, `obe-form-field--${this.type}`];

			if (this.$breakpoints.mdAndUp)
				classes.push(`obe-form-field--${this.type}`);
			else
				classes.push('obe-form-field--vert');

			if (this.disabled)
				classes.push('obe-form-field--disabled');

			if (this.error)
				classes.push('obe-form-field--has-error');

			if (this.fieldClass)
				classes.push(`obe-form-field--${this.fieldClass}`);

			return classes;
		},

		layoutAttrs()
		{
			if (this.type == 'horz')
				return { row: true, justify: 'space-between', align: 'center' };
			else if (this.type == 'vert')
				return { column: true };
			else
				return {};
		},
	},

	methods: {
		hasRules()
		{
			return (this.rules && this.rules instanceof Array && this.rules.length > 0);
		},

		validateField()
		{
			// go through each rule, if any rule function returns a string then use that as the error message and bail out
			this.error = null;
			for(var rule of this.rules)
			{
				// execute rule logic
				var result = rule.call(this, this.validateValue);
				if (typeof(result) == 'string')
				{
					this.error = result;
					return false;
				}
			}

			// if no rules failed then return true
			return true;
		}
	},

	mounted()
	{
		// only register control for validation if there are rules defined
		if (this.$addToValidation)
			if (this.rules && this.rules instanceof Array && this.rules.length > 0)
				this.$addToValidation(this);
	},

	destroyed()
	{
		if (this.$removeFromValidation)
			this.$removeFromValidation(this);
	}

}
</script>