<template>
	<div :class="classes">
		<div class="obe-switch__label">{{ labels[0] }}</div>

		<input type="button" class="obe-focus-hack" @click.prevent="toggle" :value="hiddenLabel">
		<div class="obe-switch__widget"  @click="toggle">
			<div class="obe-switch__handle"></div>
		</div>

		<div class="obe-switch__label">{{ labels[1] }}</div>
	</div>
</template>

<script>
export default {
	props: {
		value: { default: '' },
		options: { type: Array },
		labels: { type: Array },
		disabled: { type: Boolean, default: false },
	},

	computed: {
		// label for screen readers
		hiddenLabel()
		{
			return this.labels.join(' or ');
		},

		index: {
			get() 
			{
				var index = this.options.indexOf(this.value);
				return index >= 0 ? index : 0;
				
			},

			set(val)
			{
				if (val >= 0 && val <= 1)
					this.$emit('input', this.options[val]);
			}
		},

		classes()
		{
			var classes = ['obe-switch'];

			if (this.disabled)
				classes.push('obe-switch--disabled');

			if (this.index == 1)
				classes.push('obe-switch--toggled');
			
			return classes;
		}
	},

	methods: {
		toggle()
		{
			if (!this.disabled)
				this.index = this.index == 1 ? 0 : 1;
		}
	}
}
</script>