
import router from 'obe/dashboard/router';

import Screen from './comp/PaymentScreen.vue';

router.addRoutes([
	{
		name: 'db-payments',
		path: '/payments',
		props: true,
		meta: { title: 'Payments & Billing' },
		component: Screen
	}
]);
