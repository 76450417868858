
import api from 'obe/dashboard/api';

import types from './types';

export default {
	async ['help.getHelpData']({ commit })
	{
		var result = await api.get('/help_items');
		commit(types.SET_HELP_DATA, result.data.categories);
	}
}