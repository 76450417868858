
import router from 'obe/dashboard/router';

import CatalogTopButtons from './comp/CatalogTopButtons.vue';
import CatalogScreen from './comp/CatalogScreen.vue';
import CheckoutScreen from './comp/CheckoutScreen.vue';
import OrderHistoryScreen from './comp/OrderHistoryScreen.vue'

router.addRoutes([
	{
		name: 'db-catalog',
		path: '/catalog',
		props: true,
		meta: { title: 'Product orders', topButtons: CatalogTopButtons },
		component: CatalogScreen
	},
	{
		name: 'db-catalog-checkout',
		path: '/catalog/checkout',
		props: true,
		meta: { title: 'Your order', topButtons: CatalogTopButtons },
		component: CheckoutScreen
	},
	{
		name: 'db-catalog-order-history',
		path: '/catalog/order_history',
		props: true,
		meta: { title: 'Order history' },//, topButtons: CatalogTopButtons },
		component: OrderHistoryScreen
	},
]);
