<template>
	<div class="obe-login">
		<fade-transition>
			<div v-if="!success" class="obe-login__wrapper">
				<div class="obe-login__header-icon"><span class="obe-i-user"></span></div>

				<div class="obe-login__title">Customer Dashboard Access</div>

				<div class="obe-login__body">
					Please enter your email address below. If you are an existing customer of AspenClean you will receive 
					an email with instructions on how to access your customer dashboard.
				</div>

				<obe-form ref="form">
					<layout row wrap>
						<flex md6>
							<text-field
								hide-label
								placeholder="Email address"
								v-model.trim="email"
								:rules="rules.email">
							</text-field>
						</flex>
						
						<flex md6>
							<push-button block label="Submit" @click="submit" :loading="loading"></push-button>
						</flex>

						<flex xs12>
							<router-link to="/login" class="obe-link">Back to login</router-link>
						</flex>
					</layout>
				</obe-form>
			</div>

			<div v-else class="obe-login__wrapper">
				<div class="obe-login__header-icon"><span class="obe-i-user"></span></div>

				<div class="obe-login__title">Thank you!</div>

				<div class="obe-login__body">
					You will receive an email with instructions on how to access your customer dashboard shortly.
				</div>
			</div>
		</fade-transition>
	</div>
</template>

<script>
import api from 'obe/dashboard/api';
import alerts from 'obe/shared/alerts';
import validate from 'obe/shared/validators';

export default {
	data()
	{
		return {
			email: '',
			loading: false,
			success: false,
			rules: {
				email: [ 
					v => !!v || 'Please enter your email address',
					v => validate.email(v) || 'Invalid email address',
				]
			}
		}
	},

	methods: {
		async submit()
		{
			if (!this.$refs.form.validate())
				return;
			
			this.loading = true;

			try
			{
				await api.post('/signup/check_email', { email: this.email });
				this.success = true;
			}
			finally
			{
				this.loading = false;
			}
		}
	}
}
</script>