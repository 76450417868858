<template>
	<div>
		<div class="obe-checkout-header">
			<div class="obe-checkout-heading">
				Our team will deliver your order during your next cleaning on:
			</div>
			<div class="obe-checkout-delivery-date">
				<span class="obe-checkout-delivery-date__icon obe-i-calendar"></span>
				{{ nextAppt.delivery_date | dateFormat('MMM Do, YYYY')}}
			</div>
		</div>

		<layout row wrap>
			<flex xl8 class="obe-mb-4">
				<checkout-item v-for="item in cartItems" :key="item.id" :item="item"></checkout-item>

				<promo-code-form></promo-code-form>
			</flex>

			<flex xl4>
				<cart-summary></cart-summary>
			</flex>
		</layout>
	</div>
</template>

<script>

import { mapState, mapGetters } from 'vuex';
import filters from 'obe/shared/filters';
import store from 'obe/dashboard/store';
import types from '../store/types';
import CheckoutItem from './CheckoutProduct.vue';
import PromoCodeForm from './PromoCodeForm.vue';
import CartSummary from './CartSummary.vue';

export default {
	components: { CheckoutItem, PromoCodeForm, CartSummary },
	
	mixins: [filters],

	data()
	{
		return {
		}
	},

	computed: {
		...mapGetters(['cartQuantity', 'cartItems']),
		...mapState({
			nextAppt: state => state.catalog.nextAppt,
		})
	},

	beforeRouteEnter(to, from, next)
	{
		var current = store.state.catalog.currentOrder;
		var backUrl = '/catalog';

		if (current)
			backUrl = `${backUrl}?prevOrder=${current.id}`;

		if (store.state.catalog.nextAppt == null || store.getters.cartQuantity == 0)
			next(backUrl);
		else
			next();
	},

	beforeRouteUpdate(to, from, next)
	{
		var current = store.state.catalog.currentOrder;
		var backUrl = '/catalog';

		if (this.nextAppt == null || this.cartQuantity == 0)
			next(backUrl);
		else
			next();
	},

	beforeRouteLeave(to, from, next)
	{
		var catalogRoutes = ['db-catalog', 'db-catalog-checkout', 'db-catalog-order-history'];

		if (catalogRoutes.indexOf(to.name) == -1)
			this.$store.commit('CLEAR_CATALOG_ORDER');

		next();
	}
}
</script>
