<template>
	<block-section collapsible="">
		<template v-slot:title>
			<div class="obe-db-heading">Access</div>
		</template>
		
		<div class="appt-access-container">
			<obe-form ref="form">
				<!-- initial access fields -->
				<layout row wrap class="obe-pt-3">
					<flex xs12>
						<div class="obe-db-subheading">Initial cleaning</div>
					</flex>

					<flex xs12>
						<dropdown-field 
							label="Home access"
							no-selection="Please select an option"
							:value="apptAccess[`${prefix}_access_type`]"
							@input="setField(`${prefix}_access_type`, $event)"
							:label-cols="3"
							:field-cols="9"
							:items="$options.accessOptions"
							:rules="rules[`${prefix}AccessType`]">
						</dropdown-field>
					</flex>

					<flex xs12>
						<text-field 
							label="Access details"
							placeholder="Code, buzzer #, key location, etc"
							:value="apptAccess[`${prefix}_access_details`]"
							@input="setField(`${prefix}_access_details`, $event)"
							:label-cols="3"
							:field-cols="9"
							:rules="rules[`${prefix}AccessDetails`]">
						</text-field>
					</flex>

					<flex md6>
						<text-field 
							label="Alarm code"
							:maxlength="15"
							:value="apptAccess[`${prefix}_alarm_code`]"
							@input="setField(`${prefix}_alarm_code`, $event)">
						</text-field>
					</flex>

					<flex md6>
						<text-field 
							label="Garage code"
							:maxlength="15"
							:value="apptAccess[`${prefix}_garage_code`]"
							@input="setField(`${prefix}_garage_code`, $event)">
						</text-field>
					</flex>

					<flex md6>
						<dropdown-field
							label="Garbage disposal"
							:value="apptAccess.garbage"
							@input="setField('garbage', $event)"
							:items="$options.garbageOptions"
							:label-cols="6"
							:rules="rules.garbage"
							>
						</dropdown-field>

						<text-field
							:value="apptAccess.garbage_other"
							@input="setField('garbage_other', $event)"
							v-if="apptAccess.garbage == 'other'"
							:rules="rules.garbageOther">
						</text-field>
					</flex>

					<flex md6>
						<dropdown-field
							label="Parking"
							:value="apptAccess.parking"
							@input="setField('parking', $event)"
							:items="$options.parkingOptions"
							:label-cols="6"
							:rules="rules.parking">
						</dropdown-field>

						<text-field
							:value="apptAccess.parking_other"
							@input="setField('parking_other', $event)"
							v-if="apptAccess.parking == 'other'"
							:rules="rules.parkingOther"
							>
						</text-field>
					</flex>
				</layout>

				<!--
				<fade-transition>
					<layout row wrap v-if="showRegularFields">
						<flex xs12>
							<div class="obe-db-subheading">{{ cleaningFreq | capitalize }} cleaning</div>
						</flex>

						<flex xs12>
							<dropdown-field 
								label="Home access"
								no-selection="Please select an option"
								:value="apptAccess.regular_access_type"
								@input="setField('regular_access_type', $event)"
								:label-cols="3"
								:field-cols="9"
								:items="$options.accessOptions"
								:rules="rules.regularAccessType">
							</dropdown-field>
						</flex>

						<flex xs12>
							<text-field 
								label="Access details"
								placeholder="Code, buzzer #, key location, etc"
								:label-cols="3"
								:field-cols="9"
								:value="apptAccess.regular_access_details"
								@input="setField('regular_access_details', $event)"
								:rules="rules.regularAccessDetails">
							</text-field>
						</flex>

						<flex md6>
							<text-field 
								label="Alarm code"
								:maxlength="15"
								:value="apptAccess.regular_alarm_code"
								@input="setField('regular_alarm_code', $event)">
							</text-field>
						</flex>

						<flex md6>
							<text-field 
								label="Garage code"
								:maxlength="15"
								:value="apptAccess.regular_garage_code"
								@input="setField('regular_garage_code', $event)">
							</text-field>
						</flex>
					</layout>
				</fade-transition>
				-->

			</obe-form>

			<push-button v-if="canSave" wide outline color="orange"  :loading="saving" @click="updateAccess">Update Access Details</push-button>
		</div>
	</block-section>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';

import filters from 'obe/shared/filters';
import alerts from 'obe/shared/alerts';

import types from '../store/types';

import BlockSection from './BlockSection.vue';

export default {
	components: { BlockSection },
	mixins: [filters],

	accessOptions: [
		{ text: 'Door code', value: 'door-code' },
		{ text: 'I will be home', value: 'will be at home' },
		{ text: 'I will hide key', value: 'will hide key' },
		{ text: 'I will drop off key at AspenClean\'s office', value: 'drop key off' },
		{ text: 'Other', value: 'other' },
	],

	parkingOptions: [
		{ text: 'Driveway', value: 'drive_way' },
		{ text: 'Free street parking', value: 'street' },
		{ text: 'Paid parking (charges apply)', value: 'paid' },
		{ text: 'Other, please specify', value: 'other' },
	],

	garbageOptions: [
		{ text: 'Bin in the garage', value: 'garage' },
		{ text: 'Dumpster in the back of the house', value: 'back_dumpster' },
		{ text: 'Dumpster in the front of the house', value: 'front_dumpster' },
		{ text: 'Other, please specify', value: 'other' },
	],

	props: {
		canSave: { type: Boolean, default: true },
	},

	computed: {
		...mapState({
			cleaningFreq: state => state.schedule.apptBooking.cleaning_freq,
			apptAccess: state => state.schedule.apptAccess,
			appt: state => state.schedule.currentAppt,
		}),

		prefix()
		{
			return this.cleaningFreq == 'one-time' ? 'initial' : 'regular';
		},

		showRegularFields()
		{
			return ['weekly', 'bi-weekly', 'every-3-weeks', 'every-4-weeks'].indexOf(this.cleaningFreq) >= 0;
		},

		// returns false if the current appointments' arrival date is in the past
		allowSave()
		{
			var arrival = moment(this.appt.arrival_window_start_time);
			return arrival.isAfter(moment());
		}
	},

	data()
	{
		return {
			saving: false,
			//prefix: 'initial',
			rules: {
				initialAccessType: 		[ v => !!v || 'Please select an access type' ],
				initialAccessDetails: 	[ v => ['door-code', 'will hide key', 'other'].indexOf(this.apptAccess.initial_access_type) == -1 || !!v || 'Please enter access details above' ],
				garbage: 				[ v => !!v || 'Please select an option above' ],
				garbageOther: 			[ v => !!v || 'Please specify instructions above' ],
				parking: 				[ v => !!v || 'Please select an option above' ],
				parkingOther: 			[ v => !!v || 'Please specify instructions above' ],
				regularAccessType: 		[ v => !!v || 'Please select an access type' ],
				regularAccessDetails: 	[ v => ['door-code', 'will hide key', 'other'].indexOf(this.apptAccess.regular_access_type) == -1 || !!v || 'Please enter access details above' ],
			}
		};	
	},

	methods: {
		setField(field, val)
		{
			this.$store.commit('UPDATE_APPT_ACCESS', { [field]: val });
		},

		validate()
		{
			return this.$refs.form.validate();
		},

		// updates access only
		async updateAccess()
		{
			if (!this.validate())
				return;

			this.saving = true;

			try
			{
				await this.$store.dispatch('schedule.updateAppointmentAccess');
				await alerts.show('Access details for this appointment updated successfully', 'success');
			}
			finally
			{
				this.saving = false;
			}
		}
	}
}
</script>

<style scoped>
.appt-access-container
{
	max-width: 710px;
}
</style>