<template>
	<div :class="classes">
		<div class="obe-db-sidebar__main">
			<div class="obe-db-sidebar__logo">
				<a href="#" class="obe-db-logo"></a>
			</div>

			<div class="obe-db-sidebar__menu-area" v-if="currentUser != null">
				<div class="obe-db-sidebar__username" v-if="currentUser != null">
					<div class="obe-db-username">
						<span class="obe-db-username__label">Hello,</span>
						<span class="obe-db-username__name">{{ currentUser.first_name }} {{ currentUser.last_name }}</span>
					</div>
				</div>

				<ul class="obe-db-sidebar-menu">
					<li v-for="item in menu" :key="item.to">
						<router-link class="obe-db-sidebar-menu__item" :to="{ name: item.name }" :class="{ 'obe-db-sidebar-menu__item--active': item.name == $route.name }">
							<span class="obe-db-sidebar-menu__icon"><i :class="item.icon"></i></span>
							<span class="obe-db-sidebar-menu__label">{{ item.text }}</span>
						</router-link>
					</li>
					<li>
						<a href="/dashboard/logout" class="obe-db-sidebar-menu__item">
							<span class="obe-db-sidebar-menu__icon"><i class="obe-i-sidebar-logout"></i></span>
							<span class="obe-db-sidebar-menu__label">Sign Out</span>
						</a>
					</li>
				</ul>
			</div>
		</div>

		<div class="obe-db-sidebar__bottom">
			<div class="obe-db-sidebar__collapse-tools">
				<a href="#" class="obe-db-sidebar__collapse" @click.prevent="toggleCollapsed"><span class="obe-i-left-arrow"></span></a>
			</div>

			<div class="obe-db-sidebar__bottom-info">
				AspenClean<br>
				<a href="https://aspenclean.com/en-ca/pages/terms" target="_blank">Terms &amp; Conditions</a>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';

import types from '../store/types';

export default {
	props: {
		showMobileMenu: { type: Boolean, default: false },
	},

	data()
	{
		return {
			menu: [
				{ name: 'db-account', icon: 'obe-i-sidebar-account', text: 'Account' },
				{ name: 'db-contacts', icon: 'obe-i-sidebar-address', text: 'Contacts/Addresses' },
				{ name: 'db-scheduling', icon: 'obe-i-sidebar-schedule', text: 'Schedule' },
				{ name: 'db-payments', icon: 'obe-i-sidebar-payment', text: 'Payment & Billing' },
				{ name: 'db-help', icon: 'obe-i-sidebar-help', text: 'Help' },
			]
		}
	},

	computed: {
		...mapState({
			currentUser: state => state.dashboard.currentUser,
			collapsed: state => state.dashboard.collapsed,
		}),

		classes()
		{
			var base = 'obe-db-sidebar';
			var cls = base;

			// only collapse on desktops 
			if (this.collapsed && this.$breakpoints.large)
				cls += ` ${base}--collapsed`;
			
			// only toggle visible if mobile
			if (this.showMobileMenu && this.$breakpoints.mobile)
				cls += ` ${base}--visible`;

			return cls;
		}
	},

	watch: {
		showMobileMenu()
		{
			if (this.showMobileMenu)
				document.querySelector('body').classList.add('obe-sidebar-open');
			else
				document.querySelector('body').classList.remove('obe-sidebar-open');
		}, 

		'$route': function(){
			this.$emit('update:showMobileMenu', false);
		}
	},

	methods: {
		toggleCollapsed()
		{
			this.$store.commit(types.SET_DASHBOARD_COLLAPSE, !this.collapsed);
		},
	},

}
</script>