
//import 'babel-polyfill';
//import 'obe/styles/obe.scss';
import 'obe/styles/dashboard.scss';

// setup OBE Dashboard VM
import DashboardVM from 'obe/dashboard';

setTimeout(() => DashboardVM.$mount('#app-root'), 100);

//DashboardVM.$mount('#app-root');