<template>
	<div class="obe-form">
		<slot></slot>
	</div>
</template>

<script>
import { scrollTo, getElementPosition } from 'obe/shared/util';

export default {
	provide() {
		return {
			'$addToValidation': this.addControl,
			'$removeFromValidation': this.removeControl,
		}	
	},

	data()
	{
		return {
			valid: true,
			controls: [],
		}
	},

	computed: {
		// returns a list of non-empty error strings
		formErrors()
		{
			return this.controls.map(c => c.error || '').filter(err => err.trim());
		}
	},

	methods: {
		addControl(ctrl)
		{
			this.controls.push(ctrl);
		},

		removeControl(ctrl)
		{
			var pos = this.controls.indexOf(ctrl);
			if (pos >= 0)
				this.controls.splice(pos, 1);
		},

		isValid()
		{
			return this.valid;
		},

		validate()
		{
			// reset valid flag to true
			this.valid = true;

			// go through each control, if it defines a function called validateField execute it
			// if the function returns false then the entire validation is invalid
			for(var ch of this.controls)
				if (ch.validateField && typeof(ch.validateField) == 'function')
					if (!ch.validateField.call(this))
						this.valid = false;

			// if validation failed scroll document to first failed field
			/*if (!this.valid)
			{
				var field = document.querySelector('.obe-form-field--has-error');
				var pos = getElementPosition(field);
				scrollTo(document.body, pos.top, 750);
			}*/

			// return current value of valid flag
			return this.valid;
		}
	}
}
</script>