
export const SET_CUSTOMER_CONTACTS = 'SET_CUSTOMER_CONTACTS';
export const SET_CURRENT_CONTACT = 'SET_CURRENT_CONTACT';
export const UPDATE_CURRENT_CONTACT = 'UPDATE_CURRENT_CONTACT';
export const SET_CONTACT_ADDRESSES = 'SET_CONTACT_ADDRESSES';``
export const SET_CURRENT_ADDRESS = 'SET_CURRENT_ADDRESS';
export const UPDATE_CURRENT_ADDRESS = 'UPDATE_CURRENT_ADDRESS';

export default {
	SET_CUSTOMER_CONTACTS,
	SET_CURRENT_CONTACT,
	UPDATE_CURRENT_CONTACT,
	SET_CONTACT_ADDRESSES,
	SET_CURRENT_ADDRESS,
	UPDATE_CURRENT_ADDRESS,
}