<template>
	<form-field v-bind="formFieldAttrs">
		<div class="obe-stripe-card">
			<div ref="cardElement"></div>
		</div>
	</form-field>
</template>

<script>
import { getStripe } from 'obe/shared/stripe';

import formFieldProps from '../mixins/form-field';
import { mapState } from 'vuex';

export default {
	mixins: [formFieldProps],

	computed: {
		...mapState({
			publicGatewayKey: (state) => {
				if (state.bookingForm) {
					return state.bookingForm.publicPaymentGatewayKey;
				}

				if (state.payments) {
					return state.payments.publicPaymentGatewayKey;
				}
			}
		}),
	},

	methods: {
		async getToken(data)
		{
			return await this.stripe.createToken(this.stripeCard, data);
		}
	},

	async mounted()
	{
		this.stripe = await getStripe(this.publicGatewayKey);
		this.stripeCard = this.stripe.elements().create('card', { hidePostalCode: true });
		this.stripeCard.mount(this.$refs.cardElement);
	},

	beforeDestroy()
	{
		if (this.stripeCard)
			this.stripeCard.destroy();
	}
}
</script>
