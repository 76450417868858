<template>
	<div class="address-form-screen">
		<template v-if="loading">
			Loading...
		</template>

		<template v-else>
			<div v-if="formError != null" class="obe-alert obe-alert--error">
				{{ formError }}
			</div>

			<!-- MAIN ADDRESS DETAILS -->
			<div class="address-form-section">
				<layout row wrap>
					<flex lg2>
						<div class="obe-account-title">
							<div class="obe-account-title__top">
								<div class="obe-account-title__primary">Address</div>
							</div>
						</div>
					</flex>

					<flex lg10>
						<obe-form ref="form">
							<layout row wrap>
								<flex xs12>
									<text-field
										type="vert"
										label="Street Address"
										:value="address.street"
										@input="setField('street', $event)"
										:rules="rules.street">
									</text-field>
								</flex>
								<flex md6>
									<text-field
										type="vert"
										label="Unit"
										:value="address.apartment"
										@input="setField('apartment', $event)">
									</text-field>
								</flex>
								<flex md6>
									<text-field
										type="vert"
										label="City"
										:value="address.city"
										@input="setField('city', $event)"
										:rules="rules.city">
									</text-field>
								</flex>
								<flex md6>
									<text-field
										type="vert"
										label="Postal Code"
										:value="address.postal_code"
										@input="setField('postal_code', $event)"
										:rules="rules.postal_code">
									</text-field>
								</flex>
								<flex md6>
									<dropdown-field
										type="vert"
										label="Province / State"
										:items="$options.provinces"
										item-text="name"
										item-value="code"
										:value="address.province"
										@input="setField('province', $event)"
										:rules="rules.province">
									</dropdown-field>
								</flex>
								<!-- only allow users to set non-primary addresses as primary -->
								<flex xs12>
									<div class="obe-form-field">
										<div class="obe-checkbox-group">
											<checkbox v-model="isPrimary" :disabled="!canSetPrimary">{{ canSetPrimaryMessage }}</checkbox>
										</div>
									</div>
								</flex>
							</layout>
						</obe-form>
					</flex>
				</layout>
			</div>
			
			<!-- ADDRESS PROPERTY INFO -->
			<div class="address-form-section">
				<layout row wrap>
					<flex lg2>
						<div class="obe-account-title">
							<div class="obe-account-title__top">
								<div class="obe-account-title__primary">Property</div>
							</div>
						</div>
					</flex>
					<flex lg10>
						<property-info />
					</flex>
				</layout>
			</div>

			<!-- ADDRESS HOME ACCESS INFO -->
			<div class="address-form-section">
				<layout row wrap>
					<flex lg2>	
						<div class="obe-account-title">
							<div class="obe-account-title__top">
								<div class="obe-account-title__primary">Access</div>
							</div>
						</div>
					</flex>
					<flex lg10>
						<home-access ref="accessForm" />
					</flex>
				</layout>
			</div>

			<ul class="obe-button-menu obe-button-menu--left">
				<li><push-button wide color="orange" :loading="saving" @click="submit">Save Address</push-button></li>
				<li><push-button wide outline color="gray" :disabled="saving" :to="`/contacts/${contactId}`">Back</push-button></li>
			</ul>
		</template>
	</div>
</template>

<script>
import { mapState } from 'vuex';

import api from 'obe/dashboard/api';
import alerts from 'obe/shared/alerts';
import validate from 'obe/shared/validators';
import { provinces } from 'obe/shared/regions';

import types from '../store/types';

import HomeAccess from './AddressHomeAccess.vue';
import PropertyInfo from './AddressPropertyInfo.vue';

const addressDefaults = {
	id: 0,
	street: '',
	city: '',
	postal_code: '',
	province: '',
	bedrooms: 1,
	bathrooms: 1,
	kitchens: 1,
	extra_rooms: 0,
	size_sq_ft: '',
	levels: 1,
	hard_surface_floors_in_bedrooms: '',
	pets: '',
	pet_s_name: '',
	special_requirements: '',
	home_access: '',
	access_details: '',
	home_access_other: '',
	regular_cleaning_access: '',
	regular_cleaning_access_details: '',
	alarm_code: '',
	regular_alarm_code: '',
	garage_code: '',
	regular_garage_code: '',
	garbage_disposal: '',
	garbage_disposal_other: '',
	is_primary: false,
}

export default {
	provinces,

	components: { HomeAccess, PropertyInfo },

	props: {
		id: { type: String },
		contactId: { type: String },
	},

	data()
	{
		return {
			saving: false,
			loading: true,
			formError: null,
			canSetPrimary: false,
			canSetPrimaryMessage: 'Make it primary',

			rules: {
				street: [ v => !!v || 'Please enter the street address' ],
				city: [ v => !!v || 'Please enter the city' ],
				postal_code: [ 
					v => !!v || 'Please enter the postal code',
					v => validate.postalCode(v) || 'Invalid postal code',
				],
				province: [ v => !!v || 'Please select a province' ],
			}
		}
	},

	computed: {
		...mapState({
			address: state => state.contacts.currentAddress,
		}),

		isPrimary: {
			get()
			{
				return this.address.is_primary;
			},

			set(flag)
			{
				this.setField('is_primary', flag);
			}
		}
	},

	methods: {
		setField(name, val)
		{
			this.$store.commit(types.UPDATE_CURRENT_ADDRESS, { [name]: val });
		},

		async loadAddress()
		{
			this.loading = true;

			try
			{
				await this.$store.dispatch('contacts.fetchAddress', { contactId: this.contactId, id: this.id });
				this.isPrimary = this.address.is_primary;

				if (!this.address.is_primary) {
					this.canSetPrimary = true;
					this.canSetPrimaryMessage = 'Set as primary address';
				} else {
					this.canSetPrimary = false;
					this.canSetPrimaryMessage = 'This is your primary address';
				}
			}
			finally
			{
				this.loading = false;
			}
		},

		async checkPostalCode()
		{
			try
			{
				// check postal code
				var params = { postal_code: this.address.postal_code };
				var result = await api.get('/check_postal_code', { params });

				if (!result.data.found)
					this.formError = 'We\'re sorry! Unfortunately, AspenClean does not provide service in the requested area. Please, check another location.';

				return result.data.found;
			}
			catch(err)
			{
				return false;
			}
		},

		dismiss(refresh)
		{
			this.$emit('closed', refresh);
		},

		async submit()
		{
			if (!this.$refs.form.validate())
				return;

			this.saving = true;

			// check postal code
			if (!await this.checkPostalCode())
			{
				this.saving = false;
				return;
			}

			try
			{
				// save address
				var result = await this.$store.dispatch('contacts.saveCurrentAddress', { contactId: this.contactId });

				// tell container to hide popup 
				//this.dismiss(true);
				this.$router.push(`/contacts/${this.contactId}`);
			}
			finally
			{
				this.saving = false;
			}
		}
	},

	mounted()
	{
		if (this.id == 'new')
		{
			var newAddr = { ...addressDefaults };

			this.$store.commit(types.SET_CURRENT_ADDRESS, newAddr);
			this.isPrimary = false;
			this.canSetPrimary = true;
			this.canSetPrimaryMessage = 'Set as primary address';
			this.loading = false;
		}
		else
		{
			this.loadAddress();
		}
	}
}
</script>

<style scoped>
.address-form-screen
{
	max-width: 800px;
}

.address-form-section
{
	margin-bottom: 60px;
}

</style>
