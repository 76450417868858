<template>
	<div>
		<div v-if="loading" class="obe-db-subheading">Loading help content...</div>
		<help-item-group v-for="cat in helpCategories" :key="cat.id" :category="cat"></help-item-group>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import api from 'obe/dashboard/api';

import types from '../store/types';

import HelpItemGroup from './HelpItemGroup.vue';


export default {
	components: { HelpItemGroup },

	data()
	{
		return { 
			loading: false,
		}
	},

	computed: {
		...mapState({
			helpLoaded: state => state.help.loaded,
		}),

		...mapGetters([
			'helpCategories'
		])
	},

	methods: {
		async loadHelpData()
		{
			// don't need to reload help data repeatedly.
			if (this.helpLoaded)
				return;
			
			this.loading = true;
			try
			{
				await this.$store.dispatch('help.getHelpData');
			}
			finally
			{
				this.loading = false;
			}
		}
	},

	mounted()
	{
		this.loadHelpData();
		this.$store.commit(types.SET_EXPANDED_HELP_ITEM, 0);
	}
}
</script>