
import router from 'obe/dashboard/router';

import Screen from './comp/ContactsMain.vue';
import ContactScreen from './comp/ContactScreen.vue';
import AddressForm from './comp/AddressForm.vue';

router.addRoutes([
	{
		name: 'db-contacts',
		path: '/contacts',
		props: true,
		meta: { title: 'Contacts / Addresses' },
		component: Screen
	},
	{
		name: 'db-contact-screen',
		path: '/contacts/:id',
		props: true,
		meta: { title: 'Editing Contact' },
		component: ContactScreen,
	},
	{
		name: 'db-contact-address-form',
		path: '/contacts/:contactId/address/:id',
		props: true,
		meta: { title: 'Editing Address' },
		component: AddressForm,
	},
]);
