<template>
	<div class="obe-login login-content-centered">
		<div class="obe-login__wrapper-no-left-padding">
			<div class="obe-login__header-icon"><span class="obe-i-lock"></span></div>

			<div class="obe-login__title obe-login__title_forgot_password">Forgot Password</div>

			<div class="obe-login__body">Please enter your email address below. Instructions on how to reset your password will be emailed to that address</div>

			<fade-transition>
				<obe-form v-if="status == null" ref="form">
					<layout row wrap>
						<flex md6 xs12>
							<text-field
								hide-label
								placeholder="Email address"
								v-model.trim="email"
								:rules="rules.email">
							</text-field>
						</flex>
						
						<flex md6 xs12 class="obe-mb-5">
							<push-button block label="Reset password" @click="submit" :loading="loading"></push-button>
						</flex>

						<flex xs12>
							<router-link to="/login" class="obe-link">Back to login</router-link>
						</flex>
					</layout>
				</obe-form>

				<div v-else>
					<modal :show.sync="showPopup" max-width="800px">
						<card>
							<div class="obe-warning-popup__title">Need Help Resetting Password?</div>

							<div class="obe-warning-popup__desc">
								<b>{{ status }}</b>
							</div>

							<ul class="obe-button-menu obe-button-menu--vert-center">
								<li><push-button block label="Close" to="/home"></push-button></li>
							</ul>
						</card>
					</modal>
				</div>
			</fade-transition>
		</div>
	</div>
</template>

<script>
import api from 'obe/dashboard/api';

export default {
	data()
	{
		return {
			email: '',
			status: null,
			loading: false,
			showPopup: false,

			rules: {
				email: [ v => !!v || 'Please enter your email address' ],
			}
		}
	},

	methods: {
		async submit()
		{
			this.loading = true;

			try
			{
				var params = { email: this.email };
				
				const result = await api.post('/password_reset/send_reset_info', params);

				this.status = result.data.message;
				this.showPopup = true;
			}
			finally
			{
				this.loading = false;
			}
		}
	}
}
</script>
