<template>
	<div class="contact-screen">
		<template v-if="loading">
			<loading-spinner>Loading...</loading-spinner>
		</template>

		<template v-else>
			<!-- CONTACT FORM -->
			<div class="contact-screen-section">
				<div class="obe-heading obe-heading--px24">Details</div>
				<contact-form/>
			</div>

			<!-- CONTACT ADDRESS LIST -->
			<div v-if="contact.id != 0" class="contact-screen-section">
				<div class="obe-heading obe-heading--px24">Addresses</div>
				<address-list ref="addresses" />
			</div>
		</template>

		<!-- ADDRESS FORM -->
		<!-- <modal :show.sync="showAddrForm" max-width="800px">
			<card>
				<address-form v-if="currentAddress != null" @closed="onAddressFormClosed"></address-form>
			</card>
		</modal> -->

	</div>
</template>

<script>
import { mapState } from 'vuex';

import alerts from 'obe/shared/alerts';

import types from '../store/types';

import ContactForm from './ContactForm.vue';
import AddressList from './AddressList.vue';
import AddressForm from './AddressForm.vue';

export default {
	components: { ContactForm, AddressList, AddressForm },

	props: {
		id: { type: String, required: true },
	},

	data()
	{
		return {
			loading: true,
		}
	},

	computed: {
		...mapState({
			contact: state => state.contacts.currentContact,
			currentAddress: state => state.contacts.currentAddress,
		}),

		// short cut to get/set the visiblity of the address form popup
		showAddrForm: {
			get()
			{
				return this.currentAddress != null;
			},

			set(val)
			{
				if (!val)
					this.$store.commit(types.SET_CURRENT_ADDRESS, null);
			}
		},
	},

	methods: {
		// load contact from server
		async fetchContact()
		{
			this.loading = true;

			try
			{
				await this.$store.dispatch('contacts.fetchContact', { id: this.id });
			}
			finally
			{
				this.loading = false;
			}
		},

		onAddressFormClosed(refresh)
		{
			// refresh the address list
			if (refresh)
				this.$refs.addresses.refreshAddresses();

			// clear address from state
			this.$store.commit(types.SET_CURRENT_ADDRESS, null);
		}

	},

	mounted()
	{
		// edit a new contact if id is 'new'
		if (this.id == 'new')
		{
			var contact = {
				id: 0,
				first_name: '',
				last_name: '',
				email: '',
				mobile_phone: '',
				other_phone: '',
				is_primary: false,
			};

			this.$store.commit(types.SET_CURRENT_CONTACT, contact);

			this.loading = false;
		}
		// otherwise load contact data from server
		else
		{
			this.fetchContact();
		}
	}
}

</script>



<style scoped>
.contact-screen
{
	max-width: 800px;
}

.contact-screen-section
{
	margin-bottom: 60px;
}
</style>