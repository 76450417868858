<template>
	<div class="obe-db-appt-block">
		<div class="obe-db-appt-block__content">
			<!-- APPOINTMENTS BY CALENDAR/LIST -->
			<layout row wrap>
				<flex xl7>
					<ul class="obe-appt-legend">
						<li class="obe-appt-legend__upcoming">Upcoming</li>
						<li class="obe-appt-legend__scheduled">Scheduled</li>
						<li class="obe-appt-legend__completed">Completed</li>
					</ul>

					<!-- MONTH/YEAR -->
					<div class="obe-db-appt-block__title">
						<a v-if="current > 0" href="#" class="obe-db-appt-block__month-arrow" @click.prevent="gotoYear(-1)">
							<span class="obe-sr-only">Previous month</span>
							<span class="obe-i-left-arrow-bold"></span>
						</a>
						<div v-else class="obe-db-appt-block__month-arrow-disabled"><span class="obe-i-left-arrow-bold"></span></div>

						<div class="obe-db-appt-block__title-main">{{ title }}</div>

						<a v-if="current+1 < dateOptions.length" href="#" class="obe-db-appt-block__month-arrow" @click.prevent="gotoYear(1)">
							<span class="obe-sr-only">Next month</span>
							<span class="obe-i-right-arrow-bold"></span>
						</a>
						<div v-else class="obe-db-appt-block__month-arrow-disabled"><span class="obe-i-right-arrow-bold"></span></div>
					</div>

					<!-- CALENDAR -->
					<calendar  :calendar-date="date"></calendar>

					<!-- probably don't need to display this anymore -->
					<!--
					<ul class="obe-appt-legend obe-appt-legend--centered">
						<li class="obe-appt-legend__available">Available</li>
						<li class="obe-appt-legend__limited">Limited availability</li>
					</ul>
					-->
				</flex>
				<flex xl5>
					<!--- APPOINTMENT LIST -->
					<appointment-list :calendar-date="date"></appointment-list>
				</flex>
			</layout>
		</div>
	</div>
</template>

<script>
import moment from 'moment';

import Calendar from './Calendar.vue';
import AppointmentList from './AppointmentList.vue';

export default {
	components: { Calendar, AppointmentList },

	props: {
		current: { type: Number, default: 0 },
		dateOptions: { type: Array, required: true },
	},

	computed: {
		// convenience accessors for selected month/year for templating purposes
		date()
		{
			return this.dateOptions[this.current];
		},

		title()
		{
			var date = moment().month(this.date.month).year(this.date.year);
			return date.format('MMMM YYYY');
		}
	},

	methods: {
		gotoYear(dir)
		{
			var index = this.current + dir;

			if (index < 0)
				index = 0;
			else if (index >= this.dateOptions.length)
				index = this.dateOptions.length - 1;

			this.$emit('update:current', index);
		}
	}
}
</script>