<template>
	<div>
		<obe-form ref="form">
			<div v-if="formError != null" class="obe-alert obe-alert--error">
				{{ formError }}
			</div>

			<layout row wrap>
				<flex md6>
					<text-field
						type="vert"
						label="First Name"
						:value="contact.first_name"
						@input="setField('first_name', $event)"
						:rules="rules.first_name">
					</text-field>
				</flex>
				<flex md6>
					<text-field
						type="vert"
						label="Last Name"
						:value="contact.last_name"
						@input="setField('last_name', $event)"
						:rules="rules.last_name">
					</text-field>
				</flex>
				<flex md6>
					<text-field
						type="vert"
						label="Email"
						:value="contact.email"
						@input="setField('email', $event)"
						:rules="rules.email">
					</text-field>
				</flex>
				<flex md6>
					<text-field
						type="vert"
						label="Mobile phone"
						:value="contact.mobile_phone"
						@input="setField('mobile_phone', $event)"
						:rules="rules.phone1">
					</text-field>
				</flex>
				<flex md6>
					<text-field
						type="vert"
						label="Additional phone"
						:value="contact.other_phone"
						@input="setField('other_phone', $event)">
					</text-field>
				</flex>

				<!-- only allow users to set non-primary addresses as primary -->
				<flex xs12>
					<div class="obe-form-field">
						<div class="obe-checkbox-group">
							<checkbox v-model="isPrimary" :disabled="true">{{ canSetPrimaryMessage }}</checkbox>
						</div>
					</div>
				</flex>
			</layout>
		</obe-form>

		<ul class="obe-button-menu obe-button-menu--left">
			<li><push-button wide color="orange" :loading="saving" @click="submit">Save Contact</push-button></li>
			<li><push-button wide outline color="grey" :disabled="saving" to="/contacts">Back</push-button></li>
		</ul>
	</div>
</template>

<script>
import { mapState } from 'vuex';

import api from 'obe/dashboard/api';
import alerts from 'obe/shared/alerts';
import validate from 'obe/shared/validators';
import { provinces } from 'obe/shared/regions';

import types from '../store/types';

export default {
	provinces,

	data()
	{
		return {
			saving: false,
			formError: null,
			canSetPrimary: false,
			canSetPrimaryMessage: '',
			rules: {
				first_name: [ v => !!v || 'Please enter the contact\'s first name' ],
				last_name: [ v => !!v || 'Please enter the contact\'s last name' ],
				email: [ 
					v => !!v || 'Please enter the contact\'s email address',
					v => validate.email(v) || 'Invalid email address',
				],
				phone1: [
					v => !!v || 'Please enter the contact\'s mobile phone number',
					v => validate.phone(v) || 'Invalid phone number',
				]
			}
		}
	},

	computed: {
		...mapState({
			contact: state => state.contacts.currentContact,
		}),

		isPrimary: {
			get()
			{
				return this.contact.is_primary;
			},

			set(flag)
			{
				this.setField('is_primary', flag);
			}
		}
	},

	methods: {
		setField(name, val)
		{
			this.$store.commit(types.UPDATE_CURRENT_CONTACT, { [name]: val });
		},

		async submit()
		{
			if (!this.$refs.form.validate())
				return;

			this.saving = true;

			try
			{
				// save address
				var result = await this.$store.dispatch('contacts.saveCurrentContact');

				await alerts.show('Contact updated successfully', 'success');

				// if we set this address as the primary then lock it from being changed
				// this.canSetPrimary = !this.contact.is_primary;

				// update contact ID if this was a new contact
				if (this.contact.id == 0)
				{
					// replace ID
					this.setField('id', result.id);

					// replace URL
					this.$router.replace(`/contacts/${result.id}`);
				}
			}
			finally
			{
				this.saving = false;
			}
		}
	},

	mounted()
	{
		if (this.contact.id == 0) {
			this.canSetPrimary = false;
			this.canSetPrimaryMessage = 'In order to make this Contact Primary, you will need to add Address to the Contact after saving it.';
			return;
		}

		if (!this.contact.is_primary && this.contact.addresses.length > 0) {
			this.canSetPrimary = true;
			this.canSetPrimaryMessage = 'Set as primary contact';
		} else if (this.contact.is_primary) {
			this.canSetPrimary = false;
			this.canSetPrimaryMessage = 'This is your primary contact';
		} else if (this.contact.addresses.length < 1) {
			this.canSetPrimary = false;
			this.canSetPrimaryMessage = 'In order to make this Contact Primary, you need to add Address to the Contact';
		}
	}
}
</script>
