<template>
	<card>
		<div class="obe-warning-popup">
			<div class="obe-warning-popup__title">Reschedule or Skip?</div>

			<div class="obe-warning-popup__desc">
				If you'd like, you can reschedule this booking instead of cancelling. Just tell us a new date and time preference.
			</div>

			<ul class="obe-button-menu obe-button-menu--vert-center">
				<li><push-button wide color="orange" @click="$showRescheduleForm()">Reschedule</push-button></li>
				<li><push-button wide color="text" @click="$showApptCancelForm()">No thanks, I'd like to cancel this booking</push-button></li>
			</ul>
		</div>
	</card>
</template>

<script>
export default {
	inject: ['$showRescheduleForm', '$showApptCancelForm'],
}
</script>
