
import router from 'obe/dashboard/router';

import Screen from './comp/HelpScreen.vue';

router.addRoutes([
	{
		name: 'db-help',
		path: '/help',
		props: true,
		meta: { title: 'Help' },
		component: Screen
	}
]);
