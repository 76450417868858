<template>
	<obe-form ref="form">
		<!-- top title -->
		<div class="obe-sub-title obe-sub-title--blue obe-mb-0">Initial cleaning</div>

		<!-- initial access fields -->
		<layout row wrap class="obe-pt-4">
			<flex xs12>
				<dropdown-field 
					label="Home access"
					type="vert"
					no-selection="Please select an option"
					:value="accessData.home_access"
					@input="setField('home_access', $event)"
					:items="$options.accessOptions"
					:rules="rules.home_access">
				</dropdown-field>
			</flex>

			<flex xs12>
				<text-field 
					label="Access details"
					type="vert"
					placeholder="Code, buzzer #, key location, etc"
					:value="accessData.access_details"
					@input="setField('access_details', $event)"
					:rules="rules.access_details">
				</text-field>
			</flex>

			<flex md6>
				<text-field 
					label="Alarm code"
					type="vert"
					:maxlength="15"
					:value="accessData.alarm_code"
					@input="setField('alarm_code', $event)">
				</text-field>
			</flex>

			<flex md6>
				<text-field 
					label="Garage code"
					type="vert"
					:maxlength="15"
					:value="accessData.garage_code"
					@input="setField('garage_code', $event)">
				</text-field>
			</flex>

			<flex md6>
				<dropdown-field
					label="Garbage disposal"
					type="vert"
					:value="accessData.garbage_disposal"
					@input="setField('garbage_disposal', $event)"
					:items="$options.garbageOptions"
					:rules="rules.garbage_disposal"
					>
				</dropdown-field>

				<text-field
					type="vert"
					:value="accessData.garbage_disposal_other"
					@input="setField('garbage_disposal_other', $event)"
					v-if="accessData.garbage_disposal == 'other'"
					:rules="rules.garbage_disposal_other">
				</text-field>
			</flex>

			<flex md6>
				<dropdown-field
					label="Parking"
					type="vert"
					:value="accessData.parking"
					@input="setField('parking', $event)"
					:items="$options.parkingOptions"
					:rules="rules.parking">
				</dropdown-field>

				<text-field
					type="vert"
					:value="accessData.parking_other"
					@input="setField('parking_other', $event)"
					v-if="accessData.parking == 'other'"
					:rules="rules.parking_other"
					>
				</text-field>
			</flex>
		</layout>

		<layout row wrap>
			<flex xs12>
				<layout row wrap class="obe-mb-4">
					<flex md6>
						<div class="obe-sub-title obe-sub-title--blue obe-mb-2">Regular cleaning</div>
					</flex>

					<flex md6>
						<checkbox blue-text v-model="useInitialAccessDetails">Use initial cleaning access details</checkbox>
					</flex>
				</layout>
			</flex>

			<flex xs12>
				<dropdown-field 
					label="Home access"
					type="vert"
					no-selection="Please select an option"
					:value="accessData.regular_cleaning_access"
					:maxlength="255"
					@input="setField('regular_cleaning_access', $event)"
					:disabled="useInitialAccessDetails"
					:items="$options.accessOptions"
					:rules="rules.regular_cleaning_access">
				</dropdown-field>
			</flex>

			<flex xs12>
				<text-field 
					label="Access details"
					type="vert"
					placeholder="Code, buzzer #, key location, etc"
					:value="accessData.regular_cleaning_access_details"
					@input="setField('regular_cleaning_access_details', $event)"
					:disabled="useInitialAccessDetails"
					:rules="rules.regular_cleaning_access_details">
				</text-field>
			</flex>

			<flex md6>
				<text-field 
					label="Alarm code"
					type="vert"
					:maxlength="15"
					:value="accessData.regular_alarm_code"
					@input="setField('regular_alarm_code', $event)"
					:disabled="useInitialAccessDetails">
				</text-field>
			</flex>

			<flex md6>
				<text-field 
					label="Garage code"
					type="vert"
					:maxlength="15"
					:value="accessData.regular_garage_code"
					@input="setField('regular_garage_code', $event)"
					:disabled="useInitialAccessDetails">
				</text-field>
			</flex>
		</layout>


	</obe-form>
</template>

<script>
import { mapState } from 'vuex';

import filters from 'obe/shared/filters';

import types from '../store/types';

export default {
	mixins: [filters],

	accessOptions: [
		{ text: 'Door code', value: 'door-code' },
		{ text: 'I will be home', value: 'will be at home' },
		{ text: 'I will hide key', value: 'will hide key' },
		{ text: 'I will drop off key at AspenClean\'s office', value: 'drop key off' },
		{ text: 'Other', value: 'other' },
	],

	parkingOptions: [
		{ text: 'Driveway', value: 'drive_way' },
		{ text: 'Free street parking', value: 'street' },
		{ text: 'Paid parking (charges apply)', value: 'paid' },
		{ text: 'Other, please specify', value: 'other' },
	],

	garbageOptions: [
		{ text: 'Bin in the garage', value: 'garage' },
		{ text: 'Dumpster in the back of the house', value: 'back_dumpster' },
		{ text: 'Dumpster in the front of the house', value: 'front_dumpster' },
		{ text: 'Other, please specify', value: 'other' },
	],

	data() 
	{
		return {
			useInitialAccessDetails: false,
			rules: {
				home_access: 						[ v => !!v || 'Please select an access type' ],
				access_details: 					[ v => ['door-code', 'will hide key', 'other'].indexOf(this.accessData.home_access) == -1 || !!v || 'Please enter access details above' ],
				garbage_disposal: 					[ v => !!v || 'Please select an option above' ],
				garbage_disposal_other: 			[ v => this.garbage != 'other' || 'Please specify instructions above' ],
				parking: 							[ v => !!v || 'Please select an option above' ],
				parking_other: 						[ v => this.parking != 'other' || 'Please specify instructions above' ],
				regular_cleaning_access: 			[ v => !!v || 'Please select an access type' ],
				regular_cleaning_access_details: 	[ v => ['door-code', 'will hide key', 'other'].indexOf(this.accessData.regularAccessType) == -1 || !!v || 'Please enter access details above' ],
			}
		}
	},

	computed: { 
		...mapState({
			accessData: state => state.contacts.currentAddress,
		})
	},

	watch: {
		useInitialAccessDetails(flag)
		{
			this.updateRegularAccessDetails();
		}
	},

	methods: {
		updateRegularAccessDetails()
		{
			var details = {}, 
				fieldMap = {
				regular_cleaning_access: 'home_access',
				regular_cleaning_access_details: 'access_details',
				regular_alarm_code: 'alarm_code',
				regular_garage_code: 'garage_code',
			};

			for(var dst in fieldMap)
			{
				let src = fieldMap[dst];
				details[dst] = this.useInitialAccessDetails ? this.accessData[src] : '';
			}

			this.$store.commit(types.UPDATE_CURRENT_ADDRESS, details);
		},

		validate()
		{
			return this.$refs.form.validate();
		},

		setField(field, val)
		{
			this.$store.commit(types.UPDATE_CURRENT_ADDRESS, { [field]: val });
		}
	},
}
</script>
