
import moment from 'moment';

import api from 'obe/dashboard/api';

import types from './types';

function getEstimateDetails(state)
{
	// get base booking data to send to server
	var addons = {}, booking = { ...state.apptBooking }, appt = { ...state.currentAppt }, apptWorkorder = { ...state.apptWorkOrder };
	var arrivalDate = moment(state.currentAppt.arrival_window_start_time, 'YYYY-MM-DD');
	var cleaningFreq = {
		'Once': 'one-time',
		'one-time': 'one-time',
		'weekly': 'weekly',
		'bi-weekly': 'bi-weekly',
		'every-3-weeks': 'every-3-weeks',
		'every-4-weeks': 'every-4-weeks',
	}

	// get proper frequency
	booking.cleaning_freq = cleaningFreq[booking.cleaning_freq];

	// determine initial arrival day
	//booking.initial_arrival_day = moment(booking.initial_arrival_date, 'YYYY-MM-DD').day();
	booking.initial_arrival_day = arrivalDate.day();
	booking.initial_arrival_date = arrivalDate.format('YYYY-MM-DD');

	// determine regular arrival day
	if (booking.cleaning_freq != 'one-time')
	{
		// booking.regular_arrival_day = moment(booking.regular_arrival_date, 'YYYY-MM-DD').day();
		booking.regular_arrival_day = arrivalDate.day();
		booking.regular_arrival_date = arrivalDate.format('YYYY-MM-DD');
	}
	booking.regular_cleaning_frequency = apptWorkorder.regular_cleaning_frequency;
	// add addons
	for(var name in state.apptAddons)
	{
		var addon = state.apptAddons[name];
		addons[addon.addon_name] = { ...addon };
	}

	// request estimate details
	return {
		franchise_id: state.apptFranchise.id,
		addons: addons,
		booking: booking,
		work_order: {
			"root_work_order_id": apptWorkorder.root_work_order_id,
			"initial_work_order": apptWorkorder.initial_work_order,
		},
		overrides: state.overrides,
		base_overrides: state.baseOverrides,
	}
}


export default {
	async ['schedule.getAppointmentList']({ commit })
	{
		var result = await api.get('/appointments');
		commit(types.SET_LOYALTY, result.data.loyalty_code);
		commit(types.SET_APPT_LIST, result.data.appointments);
		commit(types.SET_SHOW_OFFER_BANNER, result.data.show_offer_banner);
	},

	async ['schedule.getAppointment']({ commit }, params)
	{
		var result = await api.get(`/appointments/${params.id}`);

		commit(types.SET_CURRENT_APPT, result.data);
		commit(types.SET_CURRENT_APPT_LOYALTY_CODE, result.data);
	},

	async ['schedule.addNewAddon']({ state, commit }, addon)
	{
		commit(types.UPDATE_ADDON, addon);
		commit(types.SET_CURRENT_ADDON, state.apptAddons[addon.addon_name]);
	},

	async ['schedule.getEstimate']({ state, commit }, currentUser)
	{
		var req = getEstimateDetails(state);
		var result = await api.post('/appt_estimate', { estimate_details: req });
		commit(types.UPDATE_ESTIMATE, result.data);
	},

	async ['schedule.getAvailGrades']({ state, commit }, dates)
	{
		// use last appointment's id and parent id to calculate availability grades
		var last = state.currentAppt;

		var params = { 
			appt_id: last.id,
			work_order_id: last.parent_record_id,
			franchise_id: state.apptFranchise.id,
			earliest_date: dates.earliest_date,
			latest_date: dates.latest_date,
		};

		var result = await api.get('/availability', { params });

		commit(types.SET_AVAIL_GRADES, result.data.grades);
		commit(types.SET_FRANCHISE_CLOSURES, result.data.closures);
	},

	async ['schedule.updateAppointment']({ state }, params)
	{
		var result, req = {
			time_window: params.time,
			comments: params.comments,
			update_on: params.update_on,
			force_override: params.force_override,
			recurring: state.isRecurring,
			appt_id: state.currentAppt.id,
			root_work_order_id: state.apptBooking.root_work_order_id || null,
			maintenance_plan_id: state.currentAppt.maintenance_plan_id || null,
			home_access: state.apptAccess,
			from_date: state.currentAppt.arrival_window_start_time,
			overrides: state.overrides,
			base_overrides: state.baseOverrides,
			addons: [],
		};

		for(var name in state.apptAddons)
		{
			var addon = { ...state.apptAddons[name] };

			// flag as regular frequency if bulk settings is enabled and this appt is recurring
			if (state.apptBooking.cleaning_freq != 'one-time' && params.update_on == 'all')
				addon.frequency = 'regular';

			req.addons.push({ ...addon });
		}

		req.estimate_details = getEstimateDetails(state);

		result = await api.put(`/appointments/${state.currentAppt.parent_record_id}`, req);
	},

	// only update appointment access details
	async ['schedule.updateAppointmentAccess']({ state })
	{
		var result, req = {
			home_access: state.apptAccess,
			update_type: 'access',
			recurring: state.isRecurring,
		};

		result = await api.put(`/appointments/${state.currentAppt.parent_record_id}`, req);
	},

	async ['schedule.fetchApptProducts']({ state, commit })
	{
		var result = await api.get(`/appointments/${state.currentAppt.parent_record_id}/products`);
		commit(types.SET_APPT_PRODUCTS, result.data);
	}

}
