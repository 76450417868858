<template>
	<div class="obe-filled-box">
		<template v-if="promoCode">
			<div class="obe-db-subheading">Current promo code</div>
			<layout row wrap>
				<flex lg8>
					<text-field 
						hide-label
						readonly
						:value="promoCode.discountCode.code">
					</text-field>
				</flex>
				<flex lg4>
					<push-button block @click="removePromoCode">Remove promo code</push-button>
				</flex>
			</layout>
		</template>

		<obe-form v-else ref="promoCodeForm">
			<div class="obe-db-subheading">Please enter your promo code below</div>
			<layout row wrap>
				<flex lg8>
					<text-field 
						hide-label
						:disabled="loading"
						v-model="promoCodeValue">
					</text-field>
				</flex>
				<flex lg4>
					<push-button block :loading="loading" @click="checkPromoCode">Add promo code</push-button>
				</flex>
			</layout>
		</obe-form>
	</div>
	
</template>

<script>
import { mapState } from 'vuex';

import alerts from 'obe/shared/alerts';

import types from '../store/types';

export default {
	data()
	{
		return {
			loading: false,
			promoCodeValue: ''
		}
	},

	computed: {
		...mapState({ 
			promoCode: state => state.catalog.promoCode,
		})
	},

	methods: {
		async checkPromoCode()
		{
			this.loading = true;

			try
			{
				var result = await this.$store.dispatch('catalog.addPromoCode', { promo_code: this.promoCodeValue });
				
				if (!result.found)
					alerts.show(result.note, 'Sorry', 'warning');
				else
					alerts.show('Promo code added!', 'Success', 'success');
			}
			finally
			{
				this.loading = false;
			}
		},

		async removePromoCode()
		{
			if (!await alerts.confirm('Are you sure you want to remove this promo code?'))
				return;

			this.$store.commit(types.SET_CATALOG_PROMO_CODE, null);
		}
	}
}
</script>