<template>
	<div class="obe-account-form">
		<div v-if="loading">
			<div class="obe-db-subheading">Loading...</div>
		</div>

		<obe-form ref="form" v-else>
			<layout row wrap>
				<flex md6>
					<text-field
						disabled
						type="vert"
						label="First Name"
						:value="account.first_name">
					</text-field>
				</flex>
				<flex md6>
					<text-field
						disabled
						type="vert"
						label="Last Name"
						:value="account.last_name">
					</text-field>
				</flex>
				<flex md6>
					<text-field
						disabled
						type="vert"
						label="Email Address"
						:value="account.email">
					</text-field>
				</flex>
				<flex md6>
					<text-field
						disabled
						type="vert"
						label="Mobile phone"
						:value="account.phone1">
					</text-field>
				</flex>
				<flex md6>
					<text-field
						disabled
						type="vert"
						label="Additional phone number"
						:value="account.phone2">
					</text-field>
				</flex>
				<flex xs12>
					<div class="obe-alert obe-alert--info">
						If you would like to edit your contact info please go to Contacts/Addresses and edit your primary address
					</div>

					<push-button outline color="blue" to="/contacts">Go To Contacts/Addresses</push-button>
				</flex>
			</layout>
		</obe-form>	
	</div>
</template>

<script>
import { mapState } from 'vuex';

import validate from 'obe/shared/validators';
import alerts from 'obe/shared/alerts';

import types from '../store/types';

export default {
	data()
	{
		return {
			loading: false,
			saving: false,

			rules: {
				first_name: [ v => !!v || 'Please enter your first name' ],
				last_name: [ v => !!v || 'Please enter your last name' ],
				email: [ 
					v => !!v || 'Please enter your email address',
					v => validate.email(v) || 'Invalid email address',
				],
				phone1: [ v => !!v || 'Please enter your phone number' ],
			}
		}
	},

	computed: {
		...mapState({
			account: state => state.account.profile,
		})
	},

	methods: {
		async getProfileData()
		{
			this.loading = true;

			try
			{
				await this.$store.dispatch('account.getProfileData');
			}
			finally
			{
				this.loading = false;
			}
		},

		/*
		updateField(field, val)
		{
			this.$store.commit(types.UPDATE_PROFILE_DATA, { [field]: val });
		},

		async save()
		{
			if (!this.$refs.form.validate())
				return;

			this.saving = true;

			try
			{
				await this.$store.dispatch('account.updateProfileData');
				alerts.show('Account info updtaed', 'Success', 'success');
			}
			finally
			{
				this.saving = false;
			}
		}
		*/
	},

	mounted()
	{
		this.getProfileData();
	}
}
</script>