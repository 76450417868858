<template>
	<card>
		<!-- WARN USER IF WITHIN 24-48 HOURS OF ARRIVAL DATE -->
		<div v-if="showWarning" class="obe-warning-popup">
			<div class="obe-warning-popup__title">Skip existing booking</div>

			<div class="obe-warning-popup__desc">
				You are trying to skip your booking within {{ hours }} hours of your cleaning service.<br>
				<strong>YOU WILL BE CHARGED {{ surcharge }} OF YOUR CLEANING SERVICE COST IF YOU PROCEED.</strong>
			</div>

			<ul class="obe-button-menu obe-button-menu--vert-center">
				<li><push-button wide block color="orange" @click="dismiss">Keep original booking</push-button></li>
				<li><push-button wide block color="text" @click="resume">Skip at additional cost</push-button></li>
			</ul>
		</div>

		<!-- OTHERWISE SHOW SKIP INTERFACE -->
		<div v-else class="obe-warning-popup">
			<div class="obe-warning-popup__title">Skip current cleaning job</div>
			<div class="obe-warning-popup__desc">
				Please let us know your reasons for skipping your cleaning appointment.
			</div>

			<div class="obe-warning-popup__desc">
				<obe-form ref="form">
					<radio-group
						inline
						centered
						hide-label
						:disabled="loading"
						:items="$options.reasons"
						:rules="rules.reason"
						v-model="reason">
					</radio-group>

					<text-field
						v-if="reason == 'other'"
						hide-label
						multiline
						placeholder="Please enter any notes or comments you may have here"
						:disabled="loading"
						v-model="notes">
					</text-field>
				</obe-form>
			</div>

			<ul class="obe-button-menu obe-button-menu--horz">
				<li><push-button wide outline color="gray" :disabled="loading" @click="dismiss">Close</push-button></li>
				<li><push-button wide color="orange" :loading="loading" @click="submit">Skip</push-button></li>
			</ul>
		</div>
	</card>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';

import api from 'obe/dashboard/api';

export default {
	reasons: [
		{ text: 'Will be away', value: 'Will be away' },
		{ text: 'Not required', value: 'Not required' },
		{ text: 'Other', value: 'other' },
	],

	data()
	{
		return {
			showWarning: false,
			hours: 0,
			surcharge: '',

			reason: '',
			notes: '',
			loading: false,

			rules: {
				reason: [ v => !!v || 'Please select an option above' ],
			}
		}
	},

	computed: {
		...mapState({
			appointment: state => state.schedule.currentAppt,
		})
	},

	methods: {
		dismiss()
		{
			this.$emit('closed');
		},

		resume()
		{
			this.showWarning = false;
		},

		async submit()
		{
			if (!this.$refs.form.validate())
				return;

			this.loading = true;

			try
			{
				var params = {
					appt_id: this.appointment.id,
					work_order_id: this.appointment.parent_record_id, 
					notes: this.notes,
					reason: this.reason,
				};

				// cancel appointment with salesforce
				await api.post('/appointments/skip', params);

				// reload screen
				location.reload();
			}
			finally
			{
				this.loading = false;
			}
		}
	},

	mounted()
	{
		// get arrival date
		var arrival = moment(this.appointment.arrival_window_start_time).startOf('day');

		// get difference between arrival date and today in hours
		var diff = moment.duration(arrival.diff(moment())).asHours();

		// if diff <= 24 then show 100% warning
		if (diff <= 24)
		{
			this.showWarning = true;
			this.hours = 24;
			this.surcharge = '100%';
		}
		// otherwise if within 48 hours..
		else if (diff <= 48)
		{
			this.showWarning = true;
			this.hours = 48;
			this.surcharge = '50%';
		}
	}
}
</script>