

//const STORAGE_KEY = 'obe-frontend-state';
const STORAGE_KEY = 'obe-dashboard-state';

// 'global' state module
import state from './state';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

// import state modules
import account from 'obe/dashboard/screens/account/store';
import payments from 'obe/dashboard/screens/payments/store';
//import addresses from 'obe/dashboard/screens/addresses/store';
import contacts from 'obe/dashboard/screens/contacts/store';
import help from 'obe/dashboard/screens/help/store';
import catalog from 'obe/dashboard/screens/catalog/store';
import schedule from 'obe/dashboard/screens/schedule/store';

// import vue/vuex and activate state storage
import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import { stat } from 'fs';

Vue.use(Vuex);

// reducer for state persistence
function stateReducer(state)
{
	return { catalog: state.catalog };
}

// state persistence
const persist = new VuexPersistence({
	storage: sessionStorage, 
	reducer: stateReducer,
	key: STORAGE_KEY,
});


// initialize state storage
const store = new Vuex.Store({
	modules: { 
		dashboard: { state, mutations, actions, getters },
		account, payments, help, contacts, catalog, schedule
	},
	plugins: [persist.plugin],
});

export default store;
