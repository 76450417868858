<template>
	<div class="obe-appt-block">
		<div class="obe-appt-block__content">
			<slot name="title"></slot>

			<div v-show="!collapsed || !collapsible">
				<slot></slot>
			</div>
		</div>

		<button 
			v-if="collapsible"
			type="button" 
			class="obe-appt-block__close"
			:class="{ rotated: !collapsed }"
			@click.prevent="collapsed = !collapsed">
			
			<span class="obe-i-down-arrow-bold"></span>

		</button>
	</div>
</template>

<script>
export default {
	props: {
		collapsible: { type: Boolean, default: false },
	},

	data()
	{
		return {
			collapsed: true
		}
	}
}
</script>