<template>
	<div :class="classes" @keydown="onKeyPress">
		<!-- display the value above the handle for normal sliders -->
		<div ref="value" class="obe-slider__value" v-if="!numbered">{{ showValue }}</div>

		<input type="text" class="obe-focus-hack" :disabled="disabled" :value="value" readonly>

		<div ref="widget" class="obe-slider__widget">

			<div ref="track" class="obe-slider__track">
				<div ref="fill" class="obe-slider__track-fill"></div>
			</div>
			
			<div ref="handle" class="obe-slider__handle">
				{{ numbered ? showValue : '' }}
			</div>
		</div>
		
		<div v-if="labelSet != null" class="obe-slider__label-set">
			<div v-for="lbl in labelSet" :key="lbl" class="obe-slider__label">{{ lbl }}</div>
		</div>
		
		<div v-else class="obe-slider__label">{{ label }}</div>
	</div>
</template>

<script>
import Vue from 'vue';
import constants from '../constants';

export default {
	props: {
		label: { type: String, default: '' },
		labelSet: { type: Array, default: () => null },
		min: { type: Number, default: 1 },
		max: { type: Number, default: 10 },
		snap: { type: Number, default: 1 },
		//array: { type: Boolean, default: false },
		items: { type: Array, default: () => null },
		value: { default: 0 },
		disabled: { type: Boolean, default: false },
		numbered: { type: Boolean, default: false },
		valueLabels: { type: Array  },
	},

	data() {
		return {
			dragging: false,
			touchId: null,
		}
	},

	computed: {
		classes()
		{
			var classes = ['obe-slider'];

			if (this.disabled)
				classes.push('obe-slider--disabled')

			if (this.numbered)
				classes.push('obe-slider--numbered');
			
			return classes;
		},

		array()
		{
			return this.items != null && (this.items instanceof Array);
		},

		scalarValue()
		{
			if (this.array)
				//return this.items.indexOf(this.value, this.items);
				return this.findValue(this.value);
			else
				return this.value;
		},

		minValue()
		{
			return this.array ? 0 : this.min;
		},

		maxValue()
		{
			return this.array ? this.items.length - 1 : this.max;
		},

		showValue()
		{
			if (this.array)
				return this.valueLabels != null ? this.valueLabels[this.scalarValue] : this.value;
			else
				return this.value;
		}
	},

	watch: {
		value()
		{
			this.$nextTick(() => {
				//this.updateElements();
			});
		}
	},

	methods: {
		findValue(val)
		{
			for(var i=0; i<this.items.length; i++)
				if (this.items[i] == val)
					return i;
			
			return -1;
		},

		respositionValueLabel(pos)
		{
			if (this.$refs.value)
			{
				var parentWidth = this.$el.getBoundingClientRect().width;
				var width = this.$refs.value.getBoundingClientRect().width;
				var offset = width / 2;
				var valuePos = pos - offset;

				// if the right edge of the extends past the controls right edge
				// then align it to the right edge instead
				if (valuePos + offset >= parentWidth)
					valuePos = parentWidth - width;
				// otherwise if the left edge extends then align it to the left
				else if (valuePos < 0)
					valuePos = 0;
				
				// position value label
				this.$refs.value.style.left = `${valuePos}px`;
			}
		},
		
		// update UI elements
		updateElements()
		{
			// calculate position within track boundries
			var value = (this.scalarValue - this.minValue) / (this.maxValue - this.minValue);
			var width = this.$refs.track ? this.$refs.track.getBoundingClientRect().width : null;
			var handleSize = this.$refs.handle? this.$refs.handle.getBoundingClientRect().width / 2 : null;
			var handlePos = (width * value) - handleSize;

			// reposition handle
			if(this.$refs.handle){
			this.$refs.handle.style.left = `${handlePos}px`;
			}

			// resize fill
			if(this.$refs.fill){
			this.$refs.fill.style.width = `${(width * value)}px`;

			}	

			// reposition value
			//this.respositionValueLabel(width * value);
			//this.$refs.value.style.left = `${(width * value) - (valueWidth / 2)}px`;
		},

		// update the value represented by this slider
		updateValue(value)
		{
			// get largest possible value to get value within min/max range
			var max = (this.maxValue - this.minValue);
			var newVal = (value * max) + this.minValue;

			if (this.array)
			{
				var pos = Math.round(newVal);
				this.$emit('input', this.items[pos]);
			}
			else
			{
				// snap value if snap is >= 1
				if (this.snap == 1)
					newVal = Math.round(newVal);
				else if (this.snap > 1)
					newVal = Math.round(newVal / this.snap) * this.snap;

				// send denormalized value to owner
				this.$emit('input', newVal);
			}
		},

		// increase (dir = 1) or decrease (dir = -1) value by 1 snap amount
		bumpValue(dir)
		{
			if (this.array)
			{
				var pos = this.items.indexOf(this.value);
				if (pos >= 0)
				{
					pos += dir;
					if (pos < 0)
						pos = 0;
					else if (pos >= this.items.length)
						pos = this.items.length - 1;

					this.$emit('input', this.items[pos]);
				}
			}
			else
			{
				var val = this.value + (this.snap * dir);

				if (val < this.min)
					val = this.min;
				else if (val > this.max)
					val = this.max;

				this.$emit('input', val);
			}
		},

		// update value when the user drags the slider knob
		processDrag(clientX)
		{
			// get local position of mouse cursor and width of track
			var width = this.$refs.track.getBoundingClientRect().width;
			var x = clientX - this.$refs.track.getBoundingClientRect().left;

			// clamp new position to fit within the track width
			if (x < 0)
				x = 0;
			else if (x >= width)
				x = width;

			// update normalized value
			this.updateValue(x / width);
		},

		// start dragging via mouse events
		startMouseDrag(event)
		{
			if (this.disabled)
				return;

			if (event.target == this.$refs.handle)
				this.dragging = true;
		},

		// handle drag by mouse
		moveHandleMouse(event)
		{
			if (this.disabled)
				return;

			if (this.dragging && this.$el.contains(event.target))
				this.processDrag(event.clientX);
		},

		// start dragging via touch
		startTouchDrag(event)
		{
			if (this.disabled)
				return;

			if (event.target == this.$refs.handle)
			{
				this.dragging = true;
				this.touchId = event.touches[0].identifier;
				event.preventDefault();
			}
		},

		// continue dragging via touch
		moveHandleTouch(event)
		{
			if (this.disabled)
				return;

			if (this.dragging && this.$el.contains(event.target) && event.touches[0].identifier == this.touchId)
			{
				this.processDrag(event.touches[0].clientX);
				event.preventDefault();
			}
		},

		// stop dragging
		stopDrag()
		{
			if (this.disabled)
				return;

			this.dragging = false;
		},

		// respond to left/right/up/down arrow keys
		onKeyPress(event)
		{
			if (this.disabled)
				return;

			if (this.$el.contains(event.target))
			{
				switch(event.which)
				{
					// decrease value on down/left
					case constants.KEY_DOWN:
					case constants.KEY_LEFT:
						this.bumpValue(-1);
						break;

					// increase value on up/right
					case constants.KEY_UP:
					case constants.KEY_RIGHT:
						this.bumpValue(1);
						break;
				}
			}
		}
	},

	mounted()
	{
		// bind events
		var events = {
			mousedown: e => this.startMouseDrag(e),
			mousemove: e => this.moveHandleMouse(e),
			mouseup: e => this.stopDrag(),
			mouseleave: e => this.stopDrag(),
			touchstart: e => this.startTouchDrag(e),
			touchmove: e => this.moveHandleTouch(e),
			touchend: e => this.stopDrag(),
		};

		for(var ev in events)
			this.$el.addEventListener(ev, events[ev]);

		// initialize default value
		/*if (this.value < this.min)
			this.value = this.min;
		else if (this.value > this.max)
			this.value = this.max;*/

		// initialize UI
		this.$nextTick(() => {
			this.updateElements();
		});

	},

	updated()
	{
		this.updateElements();
	}
}
</script>