<template>
	<card>
		<!-- WARN USER IF THIS IS THE INITIAL WORK ORDER PAIRED WITH A REGULAR CLEANING SCHEDULE -->
		<div v-if="showInitialWarning" class="obe-warning-popup">
			<div class="obe-warning-popup__title">Cancel?</div>

			<div class="obe-warning-popup__desc">
				You can reschedule the Initial Cleaning Appointment and the Regular Maintenance Plan. 
				If you decide to cancel the Initial Cleaning Appointment, the Regular Maintenance Plan will be cancelled as well. 
			</div>

			<ul class="obe-button-menu obe-button-menu--horz">
				<li><push-button wide outline color="grey" @click="showInitialWarning = false">Yes</push-button></li>
				<li><push-button wide color="orange" @click="dismiss">No</push-button></li>
			</ul>
		</div>

		<!-- WARN USER IF WITHIN 24-48 HOURS OF ARRIVAL DATE -->
		<div v-else-if="showWarning" class="obe-warning-popup">
			<div class="obe-warning-popup__title">Cancel existing booking</div>

			<div class="obe-warning-popup__desc">
				You are trying to cancel your booking within {{ hours }} hours of your cleaning service.<br>
				<strong>YOU WILL BE CHARGED {{ surcharge }} OF YOUR CLEANING SERVICE COST IF YOU PROCEED.</strong>
			</div>

			<ul class="obe-button-menu obe-button-menu--vert-center">
				<li><push-button wide block color="orange" @click="dismiss">Keep original booking</push-button></li>
				<li><push-button wide block color="text" @click="resume">Cancel at additional cost</push-button></li>
			</ul>
		</div>

		<!-- OTHERWISE SHOW SKIP INTERFACE -->
		<div v-else class="obe-warning-popup">
			<div class="obe-warning-popup__title">
				{{ appointment.initial_mp ? 'Cancel regular schedule' : 'Cancel current cleaning job' }}
			</div>
			<div class="obe-warning-popup__desc">
				We are sorry to see you cancelling. Please let us know your reasons and our Customer Care Team will send you confirmation shortly.
			</div>

			<div class="obe-warning-popup__desc">
				<obe-form ref="form">
					<radio-group
						inline
						centered
						hide-label
						:disabled="loading"
						:items="$options.reasons"
						:rules="rules.reason"
						v-model="reason">
					</radio-group>

					<text-field
						hide-label
						multiline
						placeholder="Please enter any notes or comments you may have here"
						:disabled="loading"
						v-model="notes">
					</text-field>
				</obe-form>
			</div>

			<ul class="obe-button-menu obe-button-menu--horz">
				<li><push-button wide outline color="gray" :disabled="loading" @click="dismiss">Close</push-button></li>
				<li><push-button wide color="orange" :loading="loading" @click="submit">Request Cancellation</push-button></li>
			</ul>
		</div>
	</card>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';

import api from 'obe/dashboard/api';

export default {
	inject: ['$showRescheduleForm'],

	reasons: [
		{ text: 'Not Happy with Service', value: 'Not Happy with service' },
		{ text: 'Price', value: 'Price' },
		{ text: 'Moving', value: 'Moving' },
		{ text: 'Other', value: 'Other' },
	],

	data()
	{
		return {
			showWarning: false,
			showInitialWarning: false,
			hours: 0,
			surcharge: '',

			reason: '',
			notes: '',
			loading: false,

			rules: {
				reason: [ v => !!v || 'Please select an option above' ],
			}
		}
	},

	computed: {
		...mapState({
			appointment: state => state.schedule.currentAppt,
		})
	},

	methods: {
		dismiss()
		{
			this.$emit('closed');
		},

		resume()
		{
			this.showWarning = false;
		},

		async cancelRegular()
		{

		},

		async submit()
		{
			if (!this.$refs.form.validate())
				return;

			this.loading = true;

			try
			{
				// cancelling plan associated with initial job
				if (this.appointment.initial_mp)
				{
					var params = {
						cancel_date: moment().toISOString(),
						maint_plan_id: this.appointment.initial_mp,
						notes: this.notes,
						reason: this.reason,
						cancel_initial: true,
					};

					await api.post('/appointments/cancel_schedule', params);
				}
				// cancelling single appointment
				else
				{
					var params = {
						appt_id: this.appointment.id,
						work_order_id: this.appointment.parent_record_id, 
						notes: this.notes,
						reason: this.reason,
					};

					// cancel appointment with salesforce
					await api.post('/appointments/cancel', params);
				}

				// reload screen
				this.$router.push({ path: '/schedule' });
			}
			finally
			{
				this.loading = false;
			}
		}
	},

	mounted()
	{
		// get arrival date
		var arrival = moment(this.appointment.arrival_window_start_time).startOf('day');

		// get difference between arrival date and today in hours
		var diff = moment.duration(arrival.diff(moment())).asHours();

		// if this is an initial cleaning paired with a regular maintenance plan then warn the user
		if (this.appointment.initial_mp)
			this.showInitialWarning = true;

		// if diff <= 24 then show 100% warning
		if (diff <= 24)
		{
			this.showWarning = true;
			this.hours = 24;
			this.surcharge = '100%';
		}
		// otherwise if within 48 hours..
		else if (diff <= 48)
		{
			this.showWarning = true;
			this.hours = 48;
			this.surcharge = '50%';
		}
	}
}
</script>
