<template>
	<div>
		<div v-if="$route.query.token" class="obe-login">
			<div class="obe-login__wrapper">
				<div class="obe-login__header-icon"><span class="obe-i-user"></span></div>

				<div class="obe-login__title">Obtain Dashboard Access</div>

				<div class="obe-login__body">
					Please enter your email address and password information below and click confirm. Once this is done you may login to your customer dashboard.
				</div>

				<obe-form ref="form">
					<layout row wrap>
						<flex xs12>
							<text-field
								type="vert"
								label="Confirm email address"
								v-model.trim="email"
								:rules="rules.email">
							</text-field>

							<text-field
								type="vert"
								input-type="password"
								label="Enter password"
								v-model.trim="password"
								:rules="rules.password">
							</text-field>

							<text-field
								type="vert"
								input-type="password"
								label="Confirm password"
								v-model.trim="confPassword"
								:rules="rules.confPassword">
							</text-field>

							<push-button label="Submit" @click="submit" :loading="loading"></push-button>
						</flex>
					</layout>
				</obe-form>
			</div>
		</div>	

		<div v-else>
			Invalid link
		</div>
	</div>
</template>

<script>
import api from 'obe/dashboard/api';

import alerts from 'obe/shared/alerts';
import validate from 'obe/shared/validators';

export default {
	data()
	{
		return {
			codeStatus: 'checking',
			loading: false,

			email: '',
			password: '',
			confPassword: '',

			rules: {
				email: [ 
					v => !!v || 'Please enter your email address',
					v => validate.email(v) || 'Invalid email address',
				],

				password: [
					v => !!v || 'Please enter your new password',
					v => validate.password(v) || 'Passwords must be at least six characters long',
				],

				confPassword: [
					v => !!v || 'Please re-enter your password',
					v => v == this.password || 'Passwords do not match',
				]
			}
		}
	},

	methods: {
		async submit()
		{
			var params = {
				email: this.email,
				password: this.password,
				conf_password: this.confPassword,
				token: this.$route.query.token,
			};

			if (!this.$refs.form.validate())
				return;

			this.loading = true;

			try
			{
				await api.post('/signup/complete', params);
				await alerts.show('Thank you! You may now login to the customer dashboard', 'success');
				document.location = '/dashboard/login';
			}
			finally
			{
				this.loading = false;
			}
		}
	},
}
</script>