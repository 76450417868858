<template>
	<div>
		<div class="obe-db-calendar">
			<div class="obe-db-calendar__header">
				<div class="obe-db-calendar__header-label">Sun</div>
				<div class="obe-db-calendar__header-label">Mon</div>
				<div class="obe-db-calendar__header-label">Tue</div>
				<div class="obe-db-calendar__header-label">Wed</div>
				<div class="obe-db-calendar__header-label">Thu</div>
				<div class="obe-db-calendar__header-label">Fri</div>
				<div class="obe-db-calendar__header-label">Sat</div>
			</div>

			<div class="obe-db-calendar__days">
				<div class="obe-db-calendar__cell" v-for="day in dates" :key="day.key">
					<span :class="day.classes" :style="day.styles">{{ day.dayOfMonth }}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';

import ApptList from '../appt-list';

import { statusColors } from '../info';

export default {
	mixins: [ApptList],

	data()
	{
		return {
			dates: [],
			window: '',
		}
	},

	computed: {
		...mapGetters(['franchiseDays']),

		...mapState({
			appts: state => state.schedule.appts,
			//booking: state => state.bookingForm.booking,
			//arrivalDate: state => state.bookingForm.booking.initialArriveOn,
			//availGrades: state => state.schedule.availGrades,
			//franchise: state => state.schedule.franchise,
		}),

	},

	watch: {
		calendarDate: {
			deep: true,
			handler(){
				this.getCalendarDates();
			}
		}
	},

	methods: {
		// return list of appointments grouped by date
		getApptsForMonth()
		{
			return this.filterApptsForMonth(this.calendarDate.month, this.calendarDate.year);
		},

		getCalendarDates()
		{
			var baseCls = 'obe-db-calendar__date';
			var now = moment().month(this.calendarDate.month).year(this.calendarDate.year);
			//var minDate = now.add(1, 'days');
            var startDate = moment(now).date(1).day(0);
			var endDate = moment(now).endOf("month").day(6);
			var current = startDate;
			var dates = [];
			var startOfMonth = moment(now).startOf('month');
			var endOfMonth = moment(now).endOf('month');
			var appts = this.getApptsForMonth();

			// calculate dates
            while(current.isSameOrBefore(endDate))
            {
				// create data for cell
				var currentUnix = current.unix();
				var dateStr = current.format('YYYY-MM-DD');
                var date = {
					key: dateStr,
					dayOfMonth: current.date(),
					date: moment(current),
					classes: [baseCls],
					styles: {},
					available: false,
					appts: appts[dateStr] || [],
				};
				
				if (date.appts.length > 0)
				{
					var status = date.appts[0].appt.status.toLowerCase().trim();

					date.classes.push(`${baseCls}--available`);

					if (['scheduled', 'dispatched', 'in progress', 'none', 'new', 'accepted', 'completed'].indexOf(status) >= 0)
					{
						date.styles.backgroundColor = statusColors[status];
						date.styles.border = `1px solid ${statusColors[status]}`
						date.styles.color = 'white';
					}
					else if (statusColors[status])
					{
						date.styles.border = `1px solid ${statusColors[status]}`;
					}
				}

				dates.push(date);
        current.add(1, 'd');
			}	

			this.dates = dates;
		},
	},

	mounted()
	{
		this.getCalendarDates();
	}
}
</script>