<template>
	<div>
		<template v-if="loading">
			Loading addresses...
		</template>

		<template v-else>
			<div v-for="addr in contactAddressList" :key="addr.id" class="obe-address-selector">
				<div class="obe-address-selector__addr">
					<div class="obe-address-line">
						<div class="obe-address-line__name">
							<span class="name-item">{{ addr.street | truncated }}</span>
							<span class="name-item">{{ addr.city }}</span>
							<span class="name-item">{{ addr.province }}</span>
						</div>

						<div class="obe-address-line__controls">
							<a href="#" class="obe-address-line__edit" @click.prevent="editAddress(addr.id)">
								<span class="obe-sr-only">Edit Address</span>
								<span class="obe-i-edit-pencil"></span>
							</a>
							<a href="#" class="obe-address-line__delete" @click.prevent="deleteAddress(addr.id)">
								<span class="obe-sr-only">Delete Address</span>
								<span class="obe-i-delete"></span>
							</a>
						</div>
					</div>
				</div>
			</div>

			<ul class="obe-button-menu">
				<li><push-button wide color="blue" @click="editAddress('new')">Add Address</push-button></li>
			</ul>

		</template>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import alerts from 'obe/shared/alerts';
import api from 'obe/dashboard/api';

import types from '../store/types';

export default {
	filters: {
		truncated(val)
		{
			if (!val)
				val = '';
			
			if (val.length >= 60)
				return `${val.slice(0, 60)}...`;
			else
				return val;
		}
	},

	data()
	{
		return {
			loading: true,
		}
	},

	computed: {
		...mapGetters(['contactAddressList']),
		...mapState({
			addresses: state => state.contacts.addresses,
			currentContact: state => state.contacts.currentContact,
		}),
	},

	methods: {
		async refreshAddresses()
		{
			this.loading = true;

			try
			{
				await this.$store.dispatch('contacts.fetchContactAddresses');
			}
			finally
			{
				this.loading = false;
			}
		},

		editAddress(id)
		{
			this.$router.push(`/contacts/${this.currentContact.id}/address/${id}`);
			
			/*
			// creating a new address?
			if (id == 0)
			{
				var addr = {
					id: 0,
					street: '',
					city: '',
					postal_code: '',
					province: '',
				};

				this.$store.commit(types.SET_CURRENT_ADDRESS, addr);
			}
			// otherwise edit the address if the ID is valid
			else if (this.addresses[id])
			{
				this.$store.commit(types.SET_CURRENT_ADDRESS, this.addresses[id]);
			}
			*/
		},

		async deleteAddress(id)
		{
			if (!await alerts.confirm('Are you sure you want to delete this address?'))
				return;

			this.loading = true;

			try
			{
				// delete address in SF
				await api.delete(`/contacts/${this.currentContact.id}/addresses/${id}`);

				// refresh address list
				await this.refreshAddresses();
			}
			finally
			{
				this.loading = false;
			}
		}
	},

	mounted()
	{
		this.refreshAddresses();
	}
}
</script>