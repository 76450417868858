
import api from 'obe/dashboard/api';

import types from './types';

export default {
	async ['paymentMethods.getPaymentMethods']({ commit })
	{
		var result = await api.get('/payment_methods');
		commit(types.SET_PAYMENT_METHODS, result.data.methods);
		commit(types.SET_PUBLIC_PAYMENT_GATEWAY, result.data.public_gateway_key);
	},

	async ['paymentMethods.getInvoices']({ commit }, params)
	{
		var result = await api.get('/invoices', { params });
		commit(types.SET_INVOICE_LIST, result.data.invoices);
		commit(types.SET_STATEMENT_INFO, { due: result.data.balance_due });
	},
}
